import { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import isEmpty from 'lodash/isEmpty'
import router from 'next/router'
import { LONG_STALE_TIME } from 'src/common/constants/common.constants'
import { QUERY_KEYS } from 'src/common/constants/query-keys'
import type { Filters, SelectedFilters } from 'src/modules/opportunities/types'
import { getFilters } from 'src/services/recommendations.api'
import { getFiltersUrl } from 'src/common/utils/filters'
import { setInitialFilters } from './useRecommendationsFilters.utils'

export const useRecommendationsFilters = ({
  setSelectedFilters
}: {
  setSelectedFilters: (filters: SelectedFilters) => void
}) => {
  const [filters, setFilters] = useState<Filters>({})

  const { data } = useQuery(QUERY_KEYS.recommendationsFilters, getFilters, { staleTime: LONG_STALE_TIME })

  useEffect(() => {
    if (isEmpty(filters)) {
      setInitialFilters({
        data,
        setFilters,
        setSelectedFilters
      })
    }
  }, [data, filters, setSelectedFilters])

  const setSelectedFilterAndUrl = useCallback(
    (newSelectedFilters: SelectedFilters) => {
      // details case: /cases/:board_id/case?recommendation_type=Some_type&filters=[...]
      // cases: /cases/:board_id?filters=[...]
      const basePath = router.asPath.split(/(\?|&)filters=/)[0]
      const oldPath = basePath + window.location.search

      const filterQuery = getFiltersUrl(newSelectedFilters)
      let newPath = basePath
      if (filterQuery) {
        const isDetailsPage = /cases\/[^/]+\/case/.test(basePath)
        newPath = isDetailsPage ? `${basePath}&${filterQuery}` : `${basePath}?${filterQuery}`
      }

      if (oldPath !== newPath) {
        router.push(newPath)
      }
      setSelectedFilters(newSelectedFilters)
    },
    [setSelectedFilters]
  )

  return { filters, setSelectedFilterAndUrl }
}
