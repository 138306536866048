import { useRouter } from 'next/router'
import { pageTitles } from 'src/common/constants/routes.constants'
import Text from 'src/components/Text'

export const PageTitle = () => {
  const { pathname } = useRouter()

  const pageTitle = pageTitles.find(item => pathname.includes(item.path))
  const Component = pageTitle?.Component
  if (Component) {
    return <Component />
  }

  if (pageTitle?.title) {
    return <Text title={pageTitle?.title} color='var(--darkBlue)' style={{ fontSize: '18px' }} />
  }

  // not null, in order to have correct flex spacing
  return <div />
}
