import { useMemo } from 'react'
import { PaginationPayload, PaginationNextKey } from '../types/pagination'
import { DEFAULT_RETURNED_ITEMS } from '../constants/pagination.constants'

// build pagination object
export const usePagination = (
  next_page_key = null as PaginationNextKey,
  max_returned_items = DEFAULT_RETURNED_ITEMS
) => {
  return useMemo(() => {
    const pagination = { max_returned_items, start_page_key: null } as PaginationPayload
    if (next_page_key) {
      pagination.start_page_key = next_page_key
    }

    return pagination
  }, [max_returned_items, next_page_key])
}
