import React from 'react'
import capitalize from 'lodash/capitalize'
import cx from 'classnames'
import type { WorkflowRunRow } from 'src/modules/monitoring/components/types'
import Text from '../Text'
import { FailedStatuses, IconsPropsByStatus } from '../../common/utils/workflows'
import CopyError from '../CopyError'
import { strings } from '../../common/constants/strings'
import { WorkflowExecutionStatus } from '../../modules/wfe/wfe.constants'
import { numberWithCommas } from '../../common/utils/number.util'
import Button from '../Button'
import { Flex } from '../styled-components/flex'
import styles from './index.module.css'

const i18n = strings.runs

export type LoopStatus = Partial<{
  aborted: number
  failed: number
  failuresNotRedrivable: number
  pending: number
  pendingRedrive: number
  resultsWritten: number
  running: number
  succeeded: number
  timedOut: number
  total: number
}>

type runningStepData = {
  title: string
  status: WorkflowExecutionStatus
  errors?: string[]
}

export type RunningWorkflowDetailsProps = {
  runningWorkflowData: runningStepData[]
  loopStatus?: any
  errorMessage?: string
  isWithBoxShadow?: boolean
  setWorkflowRunToAbort?: (row: any) => void
  row?: Partial<WorkflowRunRow>
  isShowTitle?: boolean
}

const loopStatusItems = [
  WorkflowExecutionStatus.Pending,
  WorkflowExecutionStatus.Running,
  WorkflowExecutionStatus.Failed,
  WorkflowExecutionStatus.Succeeded
]

const RunningWorkflowDetails = ({
  runningWorkflowData,
  loopStatus,
  errorMessage,
  isWithBoxShadow,
  setWorkflowRunToAbort,
  row,
  isShowTitle = true
}: RunningWorkflowDetailsProps) => {
  const Component = (
    <>
      {runningWorkflowData?.map((runningStep, index) => (
        <div className={styles.stepContainer} key={index}>
          <div className={styles.stepDetail}>
            <Text
              maxLength={40}
              className={styles.line}
              title={runningStep.title}
              iconProps={IconsPropsByStatus[runningStep.status]}
            />
            {runningStep.errors?.[0] && FailedStatuses.includes(runningStep.status) && (
              <CopyError
                className={styles.copyError}
                title={strings.common.stepError}
                description={runningStep.errors[0]}
              />
            )}
          </div>
        </div>
      ))}
      {loopStatus && (
        <div className={styles.loopSummary}>
          {loopStatusItems.map((status, innerIndex) => {
            const count = loopStatus[status.toLowerCase()]
            const iconProps = (IconsPropsByStatus as any)[status.toUpperCase()]

            return (
              <div className={styles.loopItem} key={innerIndex}>
                <Text
                  className={styles.line}
                  title={capitalize(status)}
                  iconProps={{
                    height: 24,
                    width: 24,
                    ...iconProps
                  }}
                />
                <div className={styles.count}>{numberWithCommas(count)}</div>
              </div>
            )
          })}
          <div className={styles.separatorLine} />
          <div className={styles.totalContainer}>
            <span>{strings.common.total}</span>
            <span>{numberWithCommas(loopStatus.total)}</span>
          </div>
        </div>
      )}
      {errorMessage && (
        <CopyError className={styles.workflowCopyError} title={strings.common.stepError} description={errorMessage} />
      )}
    </>
  )

  const Header = (
    <div className={styles.title}>
      {strings.common.steps} ({runningWorkflowData.length})
    </div>
  )

  return (
    <div className={cx(styles.runningWorkflowDetails, { [styles.boxShadow]: isWithBoxShadow })}>
      <Flex justifyContent='space-between' alignItems='flex-start'>
        <div>
          {isShowTitle && Header}
          {Component}
        </div>
        {((setWorkflowRunToAbort && row?.status === WorkflowExecutionStatus.Running) ||
          row?.status === WorkflowExecutionStatus.Waiting) && (
          <Button
            onClick={() => {
              setWorkflowRunToAbort?.(row)
            }}
            title={i18n.stopRun}
            minWidth={120}
            iconProps={{ name: 'stop' }}
          />
        )}
      </Flex>
    </div>
  )
}

export default RunningWorkflowDetails
