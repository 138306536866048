import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import omit from 'lodash/omit'
import isEqual from 'lodash/isEqual'
import short from 'short-uuid'
import FilterListIcon from '@mui/icons-material/FilterList'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import cx from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Autocomplete, Box, Button, Chip, IconButton, ListItem, Popover, Popper, TextField } from '@mui/material'
import { useQueryClient } from 'react-query'
import { useRouter } from 'next/router'
import CheckIcon from 'public/images/svg/check.svg'
import DeleteIcon from 'public/images/svg/delete.svg'
import CloseIcon from 'public/images/svg/close.svg'
import IconCounter from 'src/components/IconCounter'
import { strings } from 'src/common/constants/strings'
import { Flex } from 'src/components/styled-components/flex'
import Text, { SizeVariantsText, TypeVariantsText } from 'src/components/Text'
import { getApiFilters } from 'src/common/hooks/opportunities/useRecommendationsFilters.utils'
import { VirtualizedAutocomplete } from 'src/components/VirtualizedAutocomplete'
import { DATE_FILTERS, GROUP_BY, LOCAL_FILTERS, getDetailsKey } from '../../utils'
import { Filters, SelectedFilters } from '../../types'
import { useOpportunitiesStore } from '../../opportunities.store'
import styles from './styles.module.css'
import { useOpportunityFilter } from './useOpportunityFilter'

type Props = {
  selectedFilters: SelectedFilters
  setSelectedFilters: (value: SelectedFilters) => void
  filters: Filters
}

export const emptyItem = () => {
  return {
    id: short.generate(),
    name: '',
    value: []
  }
}

const i18n = strings.filters

const popularFilters = ['Account Id', 'Account Name', 'Region', 'resource_id']

const transformFiltersToArray = (filters: SelectedFilters) =>
  Object.entries(filters)
    .filter(([name]) => ![...LOCAL_FILTERS, ...DATE_FILTERS, ...GROUP_BY].includes(name))
    .map(([name, value]) => ({
      ...emptyItem(),
      name,
      value
    }))

export const FieldFilters = ({ selectedFilters, setSelectedFilters, filters: existingFilters }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [activeValueFilterKey, setActiveValueFilterKey] = useState('')
  const [isActiveValueFilter, setIsActiveValueFilter] = useState(false)
  const [appliedFilters, setAppliedFilters] = useState<any[]>([])
  const [activeFilterId, setActiveFilterId] = useState<string | null>(null)
  const activeFilter = appliedFilters.find(filter => filter.id === activeFilterId)?.name

  const { options: availableFilterValues, isLoading: isOptionsLoading } = useOpportunityFilter(activeFilter)
  const { setStartPageKey, selectedType } = useOpportunitiesStore()
  const apiFilters = useMemo(() => getApiFilters(selectedFilters), [selectedFilters])
  const queryClient = useQueryClient()
  const router = useRouter()
  const board_id = router.query.board_id as string

  const hasFilterChanged = useMemo(() => {
    const filters = transformFiltersToArray(selectedFilters).map(item => omit(item, 'id'))
    const appliedFiltersWithoutIds = appliedFilters.map(item => omit(item, 'id'))

    return !isEqual(filters, appliedFiltersWithoutIds)
  }, [selectedFilters, appliedFilters])

  const addEmptyFilter = useCallback(() => setAppliedFilters(prev => [...prev, emptyItem()]), [])

  const canDelete = useMemo(() => {
    if (appliedFilters.length !== 1) {
      return true
    }

    return !!appliedFilters[0].name || !!appliedFilters[0].value.length
  }, [appliedFilters])

  const availablePopularFilters = useMemo(() => {
    const currentFilters = appliedFilters.map(filter => filter.name)

    return popularFilters.filter(filter => !currentFilters.includes(filter))
  }, [appliedFilters])

  const availableFilters = useMemo(() => {
    const keys = sortBy(Object.keys(existingFilters))
    const currentFilters = appliedFilters.map(filter => filter.name)

    const filteredOptions = keys.filter(filter => !currentFilters.includes(filter))

    return filteredOptions.sort((a, b) => {
      const isAPopular = availablePopularFilters.includes(a)
      const isBPopular = availablePopularFilters.includes(b)
      if (isAPopular && !isBPopular) {
        return -1
      }
      if (!isAPopular && isBPopular) {
        return 1
      }

      return (a.toLowerCase() as any) - (b.toLowerCase() as any)
    })
  }, [existingFilters, appliedFilters])

  const addFilter = useCallback((filterId: string, filter: string) => {
    setAppliedFilters(prev => {
      return prev.map(item => {
        if (filterId === item.id) {
          return { ...item, name: filter, value: [] }
        }

        return item
      })
    })
  }, [])

  const updateFilterValue = useCallback((value: any, activeId?: string) => {
    setAppliedFilters(prev => {
      return prev.map(item => {
        if (item.id === activeId) {
          return { ...item, value }
        }

        return item
      })
    })
  }, [])

  const deleteFilter = useCallback((id: string) => {
    setAppliedFilters(prev => {
      const value = prev.filter(item => item.id !== id)
      if (value.length) {
        return value
      } else {
        return [emptyItem()]
      }
    })
  }, [])

  const clearFilters = useCallback(() => {
    setAppliedFilters([emptyItem()])
  }, [])

  useEffect(() => {
    if (appliedFilters.length) {
      return
    }
    if (isEmpty(selectedFilters)) {
      addEmptyFilter()
    } else {
      const filters = transformFiltersToArray(selectedFilters)
      setAppliedFilters(filters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getQueryKey = (filters: Filters, selectedType?: string, board_id?: string) =>
    getDetailsKey({
      selectedFilters: filters,
      recommendationType: selectedType,
      board_id
    })
  const applyFilters = useCallback(() => {
    const filters = appliedFilters
      .filter(filter => filter.name)
      .reduce(
        (acc, filter) => ({
          ...acc,
          [filter.name]: filter.value
        }),
        {}
      )
    setStartPageKey('')

    setSelectedFilters(filters)
    setAnchorEl(null)

    queryClient.invalidateQueries({
      queryKey: getQueryKey(filters, selectedType, board_id)
    })
  }, [appliedFilters, setStartPageKey, setSelectedFilters, queryClient, selectedType, board_id])

  return (
    <Flex className={styles.filtersContainer}>
      <div onClick={(e: any) => setAnchorEl(e.currentTarget)} style={{ display: 'flex', alignItems: 'center' }}>
        <IconCounter
          tooltip={
            isEmpty(apiFilters) ? undefined : (
              <div>
                {Object.entries(apiFilters).map(([key, values]: any) => (
                  <div className={styles.tooltip} key={key}>
                    <b>{`${key}: `}</b>
                    {'\u00A0'}
                    {/* Unicode for non-breaking space */}
                    {values.join(', ')}
                    <br />
                  </div>
                ))}
              </div>
            )
          }
          muiIconProps={{
            icon: <FilterListIcon className={styles.filtersIcon} />
          }}
          counter={Object.keys(apiFilters).reduce((acc: number, key: any) => acc + selectedFilters[key].length, 0)}
          title={strings.common.filters}
        />

        <KeyboardArrowDownIcon className={cx(styles.arrow, { [styles.arrowActive]: !!anchorEl })} />
      </div>
      <Popover
        id='filter-popover'
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        sx={{ zIndex: '1300 !important' }}
        onClose={() => {
          setAnchorEl(null)
          const filters = transformFiltersToArray(selectedFilters)
          setAppliedFilters(filters.length ? filters : [emptyItem()])
        }}
      >
        <div style={{ minWidth: '658px', minHeight: '352px' }}>
          <div
            style={{
              minHeight: '288px',
              paddingLeft: '24px',
              paddingTop: '24px',
              maxHeight: '510px',
              marginBottom: '12px',
              overflowY: 'scroll'
            }}
          >
            <Text
              typeVariant={TypeVariantsText.PRIMARY}
              sizeVariant={SizeVariantsText.SMALL}
              fontWeight={400}
              style={{ display: 'block', marginBottom: '24px' }}
              title={i18n.filters}
            />

            <div>
              {appliedFilters.map(appliedFilter => (
                <Flex
                  key={appliedFilter.id}
                  style={{
                    width: '100%',
                    justifyContent: 'space-between',
                    paddingRight: '18px',
                    marginBottom: '12px'
                  }}
                >
                  <Autocomplete
                    groupBy={option => (availablePopularFilters.includes(option) ? i18n.popular : i18n.all)}
                    sx={{
                      width: 277,
                      '& .MuiInputBase-root': {
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingRight: '14px !important'
                      },
                      '& .MuiInputBase-input': {
                        fontSize: '14px'
                      }
                    }}
                    className={styles.filterKey}
                    options={availableFilters}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder={i18n.searchFilter}
                        sx={{
                          padding: '0',
                          width: '277px',
                          '& .MuiInputBase-input': {
                            color: 'var(--customColors-primaryBlueBrand)',
                            height: '32px !important',
                            padding: '4px 14px',
                            fontSize: '14px'
                          },
                          '& div': {
                            height: '32px !important'
                          }
                        }}
                        onBlur={() => {
                          setActiveValueFilterKey('')
                        }}
                        onClick={() => {
                          if (appliedFilter.id === activeValueFilterKey) {
                            setActiveValueFilterKey('')
                          } else {
                            setActiveValueFilterKey(appliedFilter.id)
                          }
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment:
                            appliedFilter.id === activeValueFilterKey ? (
                              <KeyboardArrowUpIcon fontSize='small' />
                            ) : (
                              <KeyboardArrowDownIcon fontSize='small' />
                            )
                        }}
                      />
                    )}
                    value={appliedFilter.name}
                    onChange={(_, value, reason) => {
                      if (reason === 'selectOption' && value) {
                        addFilter(appliedFilter.id, value)
                        setActiveFilterId(appliedFilter.id)
                      }
                    }}
                    PopperComponent={props => (
                      <Popper
                        {...props}
                        sx={{
                          zIndex: 99999999,
                          '& .MuiAutocomplete-groupLabel': {
                            color: 'var(--customColors-secondaryDarkPurple)',
                            fontSize: '14px',
                            fontWeight: 700
                          },
                          '& .MuiAutocomplete-option': {
                            color: 'var(--customColors-secondaryDarkPurple)',
                            fontSize: '14px'
                          }
                        }}
                      />
                    )}
                  />
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    limitTags={1}
                    options={availableFilterValues}
                    ListboxComponent={VirtualizedAutocomplete}
                    noOptionsText={isOptionsLoading ? i18n.loading : i18n.noOptions}
                    renderInput={params => (
                      <TextField
                        {...params}
                        onBlur={() => {
                          setActiveFilterId('')
                          setIsActiveValueFilter(false)
                        }}
                        onClick={() => {
                          setActiveFilterId(appliedFilter.id)
                          setIsActiveValueFilter(true)
                        }}
                        sx={{
                          '& .MuiInputBase-root': {
                            padding: '2px 8px !important',
                            flexWrap: !(isActiveValueFilter && activeFilterId === appliedFilter.id) ? 'nowrap' : 'wrap'
                          }
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment:
                            isActiveValueFilter && activeFilterId === appliedFilter.id ? (
                              <KeyboardArrowUpIcon fontSize='small' />
                            ) : (
                              <KeyboardArrowDownIcon fontSize='small' />
                            )
                        }}
                      />
                    )}
                    value={appliedFilter.value}
                    sx={{
                      width: '285px',
                      '& .MuiInputBase-root': {
                        padding: '2px 14px 2px 4px !important'
                      },
                      '& .MuiAutocomplete-tag': {
                        backgroundColor: 'var(--customColors-neutralBrandBlue5) !important',
                        color: 'var(--customColors-primaryBlueBrand)',
                        fontSize: '14px',
                        padding: '0 4px',
                        borderRadius: '2px',
                        marginTop: 0,
                        marginBottom: 0
                      },
                      '& .MuiInputBase-input': { padding: '4.5px 4px 4.5px 5px !important' },
                      '& .MuiChip-root': {
                        border: 'none !important',
                        borderRadius: '2px',
                        height: '24px',
                        margin: '1px 3px',
                        color: 'var(--customColors-primaryBlueBrand)',
                        backgroundColor: 'var(--customColors-neutralBrandBlue5) !important'
                      }
                    }}
                    onChange={(_, value, reason) => {
                      if (reason === 'selectOption' || reason === 'removeOption') {
                        updateFilterValue(value, appliedFilter.id)
                      }
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          label={
                            !(isActiveValueFilter && activeFilterId === appliedFilter.id) && option.length > 11
                              ? option.slice(0, 11) + '...'
                              : option
                          }
                          deleteIcon={<CloseIcon />}
                          {...getTagProps({ index })}
                          key={option}
                          onDelete={() =>
                            updateFilterValue(
                              value.filter(item => item !== option),
                              appliedFilter.id
                            )
                          }
                        />
                      ))
                    }
                    renderOption={(props, option, { selected }) => (
                      <ListItem
                        {...props}
                        key={option}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between !important',
                          width: '100%',
                          backgroundColor: 'unset !important',
                          '&:hover': { backgroundColor: 'var(--customColors-neutralBrandBlue5) !important' }
                        }}
                      >
                        <Text
                          typeVariant={TypeVariantsText.PRIMARY}
                          sizeVariant={SizeVariantsText.SMALL}
                          fontWeight={400}
                          title={option}
                        />
                        {selected && <CheckIcon />}
                      </ListItem>
                    )}
                  />
                  {canDelete ? (
                    <IconButton onClick={() => deleteFilter(appliedFilter.id)} sx={{ padding: 0 }}>
                      <DeleteIcon />
                    </IconButton>
                  ) : (
                    <div style={{ width: '24px' }} />
                  )}
                </Flex>
              ))}
            </div>

            <Text
              typeVariant={TypeVariantsText.PRIMARY}
              sizeVariant={SizeVariantsText.SMALL}
              fontWeight={400}
              style={{ display: 'block', width: '100px' }}
              title={i18n.addFilter}
              onClick={addEmptyFilter}
            />
          </div>
          <Box
            width={'100%'}
            height={'64px'}
            sx={{ borderTop: '1px solid rgb(222, 219, 239)', padding: '16px 24px 16px 24px' }}
          >
            <Flex justifyContent='space-between'>
              {canDelete ? (
                <Text
                  typeVariant={TypeVariantsText.PRIMARY}
                  sizeVariant={SizeVariantsText.SMALL}
                  fontWeight={400}
                  style={{ cursor: 'pointer' }}
                  title={i18n.clearFilters}
                  onClick={clearFilters}
                />
              ) : (
                <div />
              )}
              <Flex>
                <Text
                  typeVariant={TypeVariantsText.PRIMARY}
                  sizeVariant={SizeVariantsText.SMALL}
                  fontWeight={400}
                  style={{ display: 'block', cursor: 'pointer' }}
                  title={i18n.cancel}
                  onClick={() => {
                    setAnchorEl(null)
                    const filters = transformFiltersToArray(selectedFilters)
                    setAppliedFilters(filters.length ? filters : [emptyItem()])
                  }}
                />
                <Button
                  disabled={!hasFilterChanged}
                  variant='contained'
                  sx={{ marginLeft: '12px' }}
                  onClick={applyFilters}
                >
                  {i18n.apply}
                </Button>
              </Flex>
            </Flex>
          </Box>
        </div>
      </Popover>
    </Flex>
  )
}
