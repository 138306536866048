import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { Workflow } from 'src/common/types/common.types'
import Button from '../../components/Button'
import { strings } from '../../common/constants/strings'

export const WorkflowDeleteDialog = ({
  deletedWorkflow,
  onDelete,
  onClose
}: {
  deletedWorkflow: Workflow | null
  onDelete: () => void
  onClose: () => void
}) => {
  return (
    <Dialog open={!!deletedWorkflow} onClose={onClose}>
      <DialogTitle>Delete workflow</DialogTitle>
      <DialogContent>Are you sure you want to delete workflow '{deletedWorkflow?.name}'?</DialogContent>
      <DialogActions>
        <Button variant='tertiary' onClick={onClose} title={strings.common.cancel} />
        <Button
          onClick={async () => {
            await onDelete()
            onClose()
          }}
          title={strings.common.delete}
        />
      </DialogActions>
    </Dialog>
  )
}
