import React from 'react'
import { Detail, Details, LifeCycle, Recommendation, Statuses } from '../../modules/opportunities/types'
import { strings } from '../../common/constants/strings'
import { Period } from '../../pages/cases/utils'
import OpportunitiesNavigationList from './OpportunitiesNavigationList'

export const closedStatuses: string[] = [
  Statuses.Excluded,
  Statuses.Expired,
  Statuses.Completed,
  Statuses.Verified,
  'closed'
]
export const getArrowIconProps = ({
  isOpenGoto,
  setIsOpenGoto,
  recommendations,
  recommendation,
  isDetailed
}: {
  isOpenGoto: boolean
  setIsOpenGoto: (isOpenGoto: boolean) => void
  recommendations?: Recommendation[]
  recommendation: Recommendation
  isDetailed?: boolean
}) =>
  !isDetailed
    ? undefined
    : {
        name: 'arrow-down',
        color: isOpenGoto ? 'var(--customColors-neutralBrandBlue60)' : 'var(--customColors-neutralBrandBlue40)',
        hoverColor: 'var(--customColors-neutralBrandBlue60)',
        style: {
          width: 24,
          height: 24,
          marginLeft: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...(isOpenGoto
            ? {
                backgroundColor: 'var(--lightBg)',
                borderRadius: 4
              }
            : {})
        },
        onClick: (e: any) => {
          e.stopPropagation()
          setIsOpenGoto(!isOpenGoto)
        },
        withHoverProps: {
          ComponentToShow: (
            <OpportunitiesNavigationList
              isOpenGoto={isOpenGoto}
              setIsOpenGoto={setIsOpenGoto}
              opportunities={recommendations?.map(rec => ({
                ...rec,
                isSelected: recommendation.recommendation_type
                  ? rec.recommendation_type === recommendation.recommendation_type
                  : rec.group_by_value === recommendation.group_by_value
              }))}
            />
          ),
          isShowHoverComponent: isOpenGoto
        }
      }

const CostImpactLabels = {
  [LifeCycle.ACTIVE]: strings.common.open,
  [LifeCycle.INACTIVE]: strings.common.closed
}

export const CaseStatusOptions: any = {
  [Statuses.Open]: {
    title: strings.common.toDo,
    name: 'case-open'
  },
  [Statuses.InProgress]: {
    title: strings.common.inProgress,
    name: 'case-in_progress'
  },
  [Statuses.Completed]: {
    title: strings.common.completed,
    name: 'case-completed'
  },
  [Statuses.Excluded]: {
    title: strings.common.excluded,
    name: 'case-excluded'
  },
  [Statuses.Expired]: {
    title: strings.common.expired,
    name: 'case-expired'
  },
  [Statuses.Verified]: {
    title: strings.common.verified,
    name: 'case-verified'
  },
  [Statuses.Acknowledged]: {
    title: strings.common.acknowledged,
    name: 'case-acknowledged'
  }
}

export const fillCostImpactSummaryDetails = (costImpactSummary: any) => (rec: Detail) => {
  if (!costImpactSummary.values[rec.recommendation_lifecycle_state]) {
    costImpactSummary.values[rec.recommendation_lifecycle_state] = {
      title: CostImpactLabels[rec.recommendation_lifecycle_state] + ` ${strings.common.cases}`,
      data: {},
      count: 0
    }
  }
  if (!costImpactSummary.values[rec.recommendation_lifecycle_state].data[rec.recommendation_status]) {
    costImpactSummary.values[rec.recommendation_lifecycle_state].data[rec.recommendation_status] = {
      value: 0,
      title: CaseStatusOptions[rec.recommendation_status].title,
      iconProps: { name: CaseStatusOptions[rec.recommendation_status].name },
      count: 0
    }
  }
  costImpactSummary.values[rec.recommendation_lifecycle_state].count += 1
  costImpactSummary.values[rec.recommendation_lifecycle_state].data[rec.recommendation_status].count += 1
  costImpactSummary.values[rec.recommendation_lifecycle_state].data[rec.recommendation_status].value +=
    rec.cost_impact[costImpactSummary.period as Period] || 0
}

const initializeStatusData = (status: string) => ({
  value: 0,
  count: 0,
  title: CaseStatusOptions[status].title,
  iconProps: { name: CaseStatusOptions[status].name }
})

export const ensureStatusCategory = (summary: any, category: 'closed' | 'open', title: string, status: string) => {
  if (!summary.values[category]) {
    summary.values[category] = {
      title,
      data: { [status]: initializeStatusData(status) },
      count: 0
    }
  }
  if (!summary.values[category].data[status]) {
    summary.values[category].data[status] = initializeStatusData(status)
  }
}

export const initializeCostImpactSummary = ({
  recommendation,
  details,
  costImpactSummary,
  isDetailed,
  period
}: {
  recommendation: Recommendation
  details?: Details
  costImpactSummary: any
  isDetailed?: boolean
  period: string
}) => {
  if (isDetailed) {
    details?.forEach(fillCostImpactSummaryDetails(costImpactSummary))
  } else {
    Object.keys((recommendation.status_data as object) || {}).forEach((status: string) => {
      const isClosedStatus = closedStatuses.includes(status)
      const category = isClosedStatus ? 'closed' : 'open'
      const title = `${strings.common[category]} ${strings.common.cases}`

      ensureStatusCategory(costImpactSummary, category, title, status)
      costImpactSummary.values[category].count += (recommendation as any).status_data[status]?.quantity
      costImpactSummary.values[category].data[status].count = (recommendation as any).status_data[status]?.quantity

      costImpactSummary.values[category].data[status].value +=
        (recommendation as any).status_data[status]?.savings[period] || 0
    })
  }
}
