import React, { CSSProperties } from 'react'
import styles from './index.module.css'

type CounterProps = {
  counter: number
  backgroundColor?: string
  color?: string
  margin?: string | number
  height?: number
  className?: string
  style?: CSSProperties
}

const Counter = ({
  backgroundColor = 'var(--primary-main)',
  counter,
  color = 'white',
  margin,
  height = 20,
  className = '',
  style
}: CounterProps) => (
  <div
    className={`${className} ${styles.counter}`}
    style={{ backgroundColor, color, margin, height, minWidth: height, ...style }}
  >
    {counter}
  </div>
)

export default Counter
