import startCase from 'lodash/startCase'
import { numberShortend } from './number.util'

export const convertAlphanumeric = (str = '') => str.toLowerCase().replace(/[^a-z0-9]/g, '')

export const isValidEmail = (email: string) => {
  return !!String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export const arrayToString = (arr: Array<string>, separator?: string) => arr.join(separator ?? ',')
export const stringToArray = (str: string, separator?: string) => str.split(separator ?? ',')

export const stringToDisplay = (str: string) => startCase(str)

export const containsNonLatinChars = (str = '') => {
  const latinPattern = /^[a-zA-Z0-9\s_-]+$/

  for (let i = 0; i < str.length; i++) {
    const char = str.charAt(i)

    if (!latinPattern.test(char)) {
      return true
    }
  }

  return false
}

export const stripHTML = (str: string) => str?.replace(/(<([^>]+)>)/gi, '')

type ObjectWithStrings = {
  [key: string]: any
}

export const trimObjectValues = (obj: ObjectWithStrings): ObjectWithStrings => {
  if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (typeof obj[key] === 'string') {
          obj[key] = String(obj[key]).trim()
        } else if (typeof obj[key] === 'object') {
          obj[key] = trimObjectValues(obj[key] as ObjectWithStrings)
        }
      }
    }
  }

  return obj
}

export const getSubstringsWithEllipsis = (str: string, maxLength: number): Array<string> => {
  const line1 = str.substring(0, maxLength)

  if (str.length <= maxLength) {
    return [line1, '']
  }

  const lastSpace = line1.lastIndexOf('_') >= 0 ? line1.lastIndexOf('_') + 1 : line1.length
  const line1ellipsis = line1.substring(0, lastSpace)

  const line2 = str.substring(lastSpace)

  const line2ellipsis = line2.length < maxLength ? line2 : line2.substring(0, maxLength - 1) + '...'

  return [line1ellipsis, line2ellipsis]
}

const DESCRIPTION_LENGTH = 180
export const truncateDescription = (str: string, length = DESCRIPTION_LENGTH) => {
  return stripHTML(str.length > length ? str.slice(0, length) + '...' : str)
}

export const withPlural = (num: number, unit: string) => `${num} ${unit}${num > 1 ? 's' : ''}`

const pluralRules: [RegExp, string][] = [
  [/s$/, 's'], // Words that end in 's'
  [/(ch|sh|ss|x|z)$/i, '$1es'], // Words that end in 'ch', 'sh', 'ss', 'x', or 'z'
  [/([^aeiou])y$/i, '$1ies'], // Words that end in a consonant followed by 'y'
  [/([^aeiou]o)$/i, '$1es'], // Words that end in a consonant followed by 'o'
  [/f$/, 'ves'], // Words that end in 'f'
  [/fe$/, 'ves'] // Words that end in 'fe'
]
export const pluralize = (word: string, count?: number): string => {
  if (count !== 1) {
    const irregularPlurals: { [key: string]: string } = {
      child: 'children',
      person: 'people',
      box: 'boxes',
      Opportunity: 'cases'

      // Add more irregular plurals as needed
    }

    if (irregularPlurals[word]) {
      return irregularPlurals[word]
    }

    for (const [pattern, replacement] of pluralRules) {
      if (pattern.test(word)) {
        return word.replace(pattern, replacement)
      }
    }

    return word + 's'
  }

  return word
}

export const getUsername = (user: string) => {
  if (user.includes('@')) {
    return user.split('@')[0]
  }

  return user
}

export const hashStringToIndex = (name: string, maxIndex: number) => {
  // convert string to an an index between 0 and maxIndex
  try {
    let hash = 0

    for (let i = 0; i < name.length; i++) {
      const char = name.charCodeAt(i)
      hash = (hash << (maxIndex + 2)) - hash + char
      hash |= 0
    }

    return (Math.abs(hash) % maxIndex) + 1
  } catch (e) {
    return 0
  }
}

export const costImpactByPeriod = (costImpact: number, periodLabel: string) =>
  costImpact ? '$' + numberShortend(costImpact) + periodLabel : ''
