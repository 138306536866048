import styles from '../../components/RunningWorkflowDetails/index.module.css'
import { WorkflowExecutionStatus } from '../../modules/wfe/wfe.constants'
import { SingleStepExecutionsStatuses } from '../../modules/wfe/wfe.types'

export const RunningStatusIconPropsEnum = {
  SUCCESS: { name: 'icons/monitoring/succeeded' },
  SUCCEEDED: { name: 'icons/monitoring/succeeded' },
  FAIL: { name: 'icons/monitoring/failed' },
  FAILED: { name: 'icons/monitoring/failed' },
  PENDING: { name: 'icons/monitoring/pending' },
  RUNNING: {
    name: 'icons/monitoring/running',
    className: styles.rotatingSvg
  },
  TIMEOUT: { name: 'icons/monitoring/timed_out' },
  TIMED_OUT: { name: 'icons/monitoring/timed_out' },
  COMPLETED_WITH_ERRORS: { name: 'icons/monitoring/completed_with_errors' }
}
export const IconsPropsByStatus: any = {
  [WorkflowExecutionStatus.Pending]: RunningStatusIconPropsEnum.PENDING,
  [WorkflowExecutionStatus.Succeeded]: RunningStatusIconPropsEnum.SUCCESS,
  [WorkflowExecutionStatus.Failed]: RunningStatusIconPropsEnum.FAIL,
  [WorkflowExecutionStatus.Aborted]: RunningStatusIconPropsEnum.FAIL,
  [WorkflowExecutionStatus.Running]: RunningStatusIconPropsEnum.RUNNING,
  [WorkflowExecutionStatus.Waiting]: RunningStatusIconPropsEnum.PENDING,
  [WorkflowExecutionStatus.RunningSteps]: RunningStatusIconPropsEnum.RUNNING,
  [WorkflowExecutionStatus.OK]: RunningStatusIconPropsEnum.SUCCESS,
  [SingleStepExecutionsStatuses.FAIL]: RunningStatusIconPropsEnum.FAIL,
  [SingleStepExecutionsStatuses.TIMEOUT]: RunningStatusIconPropsEnum.TIMEOUT,
  [SingleStepExecutionsStatuses.COMPLETED_WITH_ERRORS]: RunningStatusIconPropsEnum.COMPLETED_WITH_ERRORS
}

export const FailedStatuses: string[] = [WorkflowExecutionStatus.Failed, SingleStepExecutionsStatuses.FAIL]

export const PendingStatuses: string[] = [WorkflowExecutionStatus.Waiting, 'PENDING']
