import router from 'next/router'
import { FILTER_KEY } from '../../modules/opportunities/utils'
import { routes } from '../constants/routes.constants'
import { getUrlParam } from './url.util'

export const redirectToOpportunityType = (recommendation_type: string, board_id: string) => {
  const filters = getUrlParam(FILTER_KEY)
  router.push(
    `${routes.opportunities.firstPagePath}/${board_id}/case?recommendation_type=${recommendation_type}${
      filters ? `&filters=${filters}` : ''
    }`
  )
}

export const redirectToOpportunityAccount = (recommendation_account_id: string, board_id: string) => {
  const filters = getUrlParam(FILTER_KEY)
  router.push(
    `${routes.opportunities.firstPagePath}/${board_id}/case?recommendation_account_id=${recommendation_account_id}${
      filters ? `&filters=${filters}` : ''
    }`
  )
}
