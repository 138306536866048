import { Node } from 'reactflow'
import isNil from 'lodash/isNil'
import cloneDeep from 'lodash/cloneDeep'
import { useCallback, useMemo } from 'react'
import {
  FlowControlType,
  NodeScope,
  NodeSubType,
  NodeType,
  StepSubGroupType,
  UtilityType
} from 'src/common/constants/common.constants'
import { UniqueIdProvider } from 'src/common/utils/uniqueIdProvider.module'
import { removeKeysFromObj } from 'src/common/utils/obj.util'
import { InputParams } from 'src/common/utils/types'
import {
  DEPRECATED_PARAMS,
  GITHUB_WORKFLOW_INPUT_PARAM,
  REACT_FLOW_KEYS_TO_REMOVE,
  TRIGGER_STEP_PARAM_ID
} from 'src/modules/wfe/wfe.constants'
import { WFSubGroupIcons } from 'src/common/constants/assets.constants'
import { useGithubWorkflowInputs, WorkflowInput, WorkflowInputData } from '../integrations/useGithubWorkflowInputs'
import { EdgeType, WorkflowExecutionStatus } from './wfe.constants'
import { NodeDataStep, Parameter, Step } from './wfe.types'
import { ScheduleTypes, TriggerConfiguration, TriggerTypes, TriggerValue } from './components/StepBar/TriggerType/types'
import WfeSteps from './wfe.steps'
import { hasMissingParamsApprove } from './wfe.helpers.approve'
import { transformKeyToInput } from './components/StepBar/SpecialInputs/FiltersInput.utils'
import { HAS_OUTPUT } from './components/StepBar/outputTree.utils'
import { IGNORED_PARAMS_FOR_CLI } from './wfe.constants.aws'

export const NO_ID = '-1'

export const getStepParametersInput = (stepTemplateId: string) => {
  const steps = WfeSteps.getInstance().getSteps()
  if (Object.keys(steps).length > 0 && steps[stepTemplateId]) {
    return steps[stepTemplateId]
  }

  return [] as InputParams[]
}

export const getStepActionForUI = (subgroup: string, stepTemplateId: string) => {
  const groupActions = WfeSteps.getInstance().getSubGroupActions(subgroup)
  if (!groupActions) {
    return {
      icon: '',
      subgroupTitle: ''
    }
  }

  const step = groupActions.find((item: any) => item.stepTemplateId === stepTemplateId)

  if (!step) {
    return {
      icon: '',
      subgroupTitle: ''
    }
  }

  return {
    ...step,
    subgroupTitle: step.subgroupTitle ? subgroup + ' - ' + step.subgroupTitle : subgroup
  }
}

export const getNodeScopeByNodeSubtype = (subtype: NodeSubType) => {
  switch (subtype) {
    case NodeSubType.If:
      return NodeScope.If

    case NodeSubType.Approve:
      return NodeScope.Approve

    case NodeSubType.Loop:
      return NodeScope.Loop

    default:
      return NodeScope.Default
  }
}

export const createNodeDataStep = (
  step: Step & { id?: string },
  type: string,
  subtype: NodeSubType,
  scope: NodeScope
) => {
  const nodeData: NodeDataStep = {
    step,
    type: type as NodeType,
    subtype,
    scope,
    id: step.id ?? NO_ID
  }

  return cloneDeep(nodeData)
}

export const createNewNodeStep = (uniqueNodeId: string, wfNodeDataStep: NodeDataStep, type: NodeType): any => {
  wfNodeDataStep.id = uniqueNodeId

  // create a step node
  const childStepNode = {
    id: uniqueNodeId,

    // the layout function will animate it to its new position
    position: { x: 0, y: 0 },
    type: type,
    data: wfNodeDataStep
  }

  return childStepNode
}

export const createNewNodePlaceholder = (
  uniqueNodeId: string,
  newNodeData: any,
  data: any = {},
  isPassPlaceHolder = false
): any => {
  // create a placeholder node
  const nodeType = isPassPlaceHolder ? NodeType.PassPlaceholder : NodeType.Placeholder
  const nodeData = {
    type: nodeType,
    scope: newNodeData.scope,
    ...data,
    step: { name: uniqueNodeId, parameters: [] }
  }

  const childPlaceholderNode = {
    id: uniqueNodeId,

    // the layout function will animate it to its new position
    position: { x: 0, y: 0 },
    type: nodeType,
    data: nodeData
  }

  return childPlaceholderNode
}

export const createEdge = (edgeType: EdgeType, sourceId: string, targetId: string, data: any = {}): any => {
  if (edgeType == EdgeType.Loop) {
    return {
      id: `${sourceId}->${targetId}`,
      source: sourceId,
      target: targetId,
      type: edgeType,
      data: data,
      markerStart: {
        type: 'arrowclosed',
        orient: 'auto-start-reverse'
      }
    }
  }
  const edge = {
    id: `${sourceId}->${targetId}`,
    source: sourceId,
    target: targetId,
    type: edgeType,
    data: data
  }

  return edge
}

export const createNewNodeInvisible = (uniqueNodeId: string): any => {
  // create a placeholder node
  const nodeData = {
    type: NodeType.Invisible,
    step: { name: uniqueNodeId, parameters: [] }
  }
  const childPlaceholderNode = {
    id: uniqueNodeId,
    data: nodeData,

    // the layout function will animate it to its new position
    position: { x: 0, y: 0 },
    type: NodeType.Invisible
  }

  return childPlaceholderNode
}

export const generateUniqueNodeId = (nodeIdentifier: NodeType | NodeSubType | StepSubGroupType) => {
  const prefix = `N-${nodeIdentifier}`

  return UniqueIdProvider.getInstance().generateId(prefix)
}

const replaceComments = (nodes: any, comments: any) =>
  nodes.filter((node: any) => node.type !== NodeType.COMMENT).concat(comments)

export const createWorkflowObject = (reactflowObj: any) => {
  let commentNodes

  if (reactflowObj?.nodes || reactflowObj?.graph?.nodes) {
    const nodes = reactflowObj?.nodes || reactflowObj?.graph?.nodes
    commentNodes = JSON.parse(JSON.stringify(nodes.filter((node: any) => node.type === NodeType.COMMENT)))
    if (commentNodes?.length) {
      removeKeysFromObj(
        commentNodes,
        REACT_FLOW_KEYS_TO_REMOVE.filter(key => key !== 'position')
      )
    }
  }

  removeKeysFromObj(reactflowObj, REACT_FLOW_KEYS_TO_REMOVE)
  if (commentNodes?.length) {
    if (reactflowObj.nodes) {
      reactflowObj.nodes = replaceComments(reactflowObj.nodes, commentNodes)
    } else {
      reactflowObj.graph.nodes = replaceComments(reactflowObj.graph.nodes, commentNodes)
    }
  }

  return reactflowObj
}

export const getInputTemplate = (step: Step, paramId: string) => {
  const template = getStepParametersInput(step.stepTemplateId)
  const input = template.find((templateParam: any) => templateParam.id === paramId)

  return input
}

const isFilterInput = (id: string) => {
  return id.includes(UtilityType.Datastore) && id.includes('-P2')
}

const validateGithubWorkflowInputs = ({ value }: Parameter, cache: WorkflowInput) => {
  if (!cache) {
    return false
  }

  const paramValue = value as any

  return Object.entries(cache).some(([key, value]) => {
    if ((value as WorkflowInputData).required) {
      return isNil(paramValue[key]) || paramValue[key] === ''
    }

    return false
  })
}

export const useHasMissingParams = () => {
  const { data: workflowInputs } = useGithubWorkflowInputs()
  const cache = useMemo(
    () => ({
      // we can add more data for other steps
      [GITHUB_WORKFLOW_INPUT_PARAM]: workflowInputs
    }),
    [workflowInputs]
  )

  return useCallback(
    (node: NodeDataStep, showMissingParamsStep?: object) => hasMissingParams(node, showMissingParamsStep, cache),
    [cache]
  )
}

const hasMissedFilterField = (rule: any) => {
  if (rule.and) {
    return rule.and.some(hasMissedFilterField)
  } else if (rule.or) {
    return rule.or.some(hasMissedFilterField)
  }

  const isKeyFilled = !!transformKeyToInput(rule.key) as any
  const isOperatorFilled = !!rule.operator as any

  // if one of the field is filled, then second should be too
  return isKeyFilled ^ isOperatorFilled
}

export const hasMissingParams = (node: NodeDataStep, showMissingParamsStep?: object, cache?: any) => {
  // TODO: remove the "NodeScope.if" skip when we fix its missing params check
  if (showMissingParamsStep?.[node.step.name as keyof object]) {
    return 'step'
  }
  if (
    node?.type === NodeType.Invalid ||
    node?.scope === NodeScope.If ||
    node?.subtype === FlowControlType.If ||
    !node?.step
  ) {
    return ''
  }

  if (node?.subtype === FlowControlType.Trigger) {
    if (triggerHasMissingParams(node)) {
      return node?.subtype
    }

    return ''
  }

  if (node?.subtype === FlowControlType.Approve) {
    if (hasMissingParamsApprove(node)) {
      return node?.subtype
    }

    return ''
  }

  const params = node.step.parameters

  const emptyParams = params?.map((param: Parameter) => {
    const template = getInputTemplate(node?.step, param.id)

    if (template?.hasMissingParams) {
      if (param.value === undefined || param.value === '') {
        if (
          template?.hasMissingParams.some(
            (condition: { id: string; value: string }) =>
              params.find(p => p.id === condition.id)?.value === condition.value
          )
        ) {
          return param.id
        }

        return ''
      }
    }

    if (isFilterInput(param.id)) {
      if (hasMissedFilterField(param.value)) {
        return param.id
      }

      return ''
    }

    if (param.id === GITHUB_WORKFLOW_INPUT_PARAM) {
      if (validateGithubWorkflowInputs(param, cache?.[param.id])) {
        return param.id
      }

      return ''
    }
    const isEmpty = param.value === undefined || param.value === ''
    const isMissing =
      isEmpty &&
      template?.optional !== true &&
      template?.isHiddenToBE !== true &&
      template?.type !== 'toggle' &&
      template?.hasToggle !== true &&
      !typeAllowEmpty(template?.type) &&
      !DEPRECATED_PARAMS.includes(param.id)

    return isMissing ? param.id : ''
  })

  return emptyParams.filter(Boolean).join(',')
}

export const formWorkflowExecutedSteps = (nodes: any, workflowExecution: any, workflowExecutionStatus: any) => {
  const previousSteps: any = []
  nodes.forEach((node: any) => {
    const stepName = node.data?.step?.name
    if (workflowExecutionStatus?.previousStepsStatus?.[stepName]) {
      previousSteps.push({
        id: stepName,
        status: workflowExecutionStatus.previousStepsStatus[stepName].status,
        timestamp: workflowExecutionStatus.previousStepsStatus[stepName].timestamp
      })
    }
  })
  previousSteps.sort((a: any, b: any) => {
    return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
  })
  workflowExecution.executedSteps = previousSteps

  const lastStep = workflowExecutionStatus.lastStep
  const executedStep = {
    id: lastStep?.stepId,
    status: workflowExecutionStatus.status
  }
  const stepIndex = workflowExecution.executedSteps.findIndex((step: any) => step.id === executedStep.id)
  if (stepIndex < 0) {
    workflowExecution.executedSteps.push(executedStep)
  }

  return workflowExecution.executedSteps
}

export const getWorkflowProgressByStepsExecution = (workflowExecution: any) => {
  if (workflowExecution.executedSteps.length === 0) {
    return 0
  }

  if (
    workflowExecution.status === WorkflowExecutionStatus.Running ||
    workflowExecution.status === WorkflowExecutionStatus.Waiting
  ) {
    const completedSteps = workflowExecution.executedSteps.filter(
      (step: any) => step.status === WorkflowExecutionStatus.Succeeded
    )
    const progress = (completedSteps.length / workflowExecution.steps.length) * 100

    return Math.round(progress > 100 ? 100 : progress)
  }

  return 100
}

const scheduledTriggerHasMissingParams = (configuration: TriggerConfiguration) => {
  const { scheduleType, cronExpression, timezone, minute, hour, dayOfWeek, dayOfMonth, number, intervalType } =
    configuration

  switch (scheduleType) {
    case 'CRON':
      return !cronExpression || !timezone

    case 'DAILY':
      return minute === undefined || hour === undefined || !timezone

    case 'WEEKLY':
      return minute === undefined || hour === undefined || !dayOfWeek?.length || !timezone

    case 'MONTHLY':
      return minute === undefined || hour === undefined || !dayOfMonth?.length || !timezone

    case 'INTERVAL':
      return number === undefined || !intervalType || !timezone

    default:
      return true
  }
}

export const executableSteps = (workflow: any) =>
  workflow?.graph?.nodes?.filter((node: any) => node.type === NodeType.Action || node.type === NodeType.FlowControl)

export const getTriggerNode = (nodes?: Array<any>) => {
  return nodes?.find((node: any) => node.subtype === FlowControlType.Trigger)?.data
}

export const getTriggerValue = (node: NodeDataStep) => {
  const value = node.step.parameters.find(param => param.id === TRIGGER_STEP_PARAM_ID)?.value as
    | TriggerValue
    | undefined

  const conf = value?.configuration ?? {}

  const {
    scheduleType,
    cronExpression,
    timezone,
    minute,
    hour,
    dayOfWeek,
    dayOfMonth,
    number,
    intervalType,
    eventId,
    integrationId
  } = conf

  const getConfigurationByType = () => {
    if (value?.type === TriggerTypes.MANUAL) {
      return {}
    }

    if (value?.type === TriggerTypes.INTEGRATION_EVENT) {
      return { eventId, integrationId }
    }

    switch (scheduleType) {
      case ScheduleTypes.CRON:
        return { scheduleType, timezone, cronExpression }
      case ScheduleTypes.DAILY:
        return { scheduleType, timezone, minute, hour }
      case ScheduleTypes.WEEKLY:
        return { scheduleType, timezone, minute, hour, dayOfWeek }
      case ScheduleTypes.MONTHLY:
        return { scheduleType, timezone, minute, hour, dayOfMonth }
      case ScheduleTypes.INTERVAL:
        return { scheduleType, timezone, number, intervalType }
      default:
        return {}
    }
  }

  return {
    ...value,
    configuration: { ...getConfigurationByType() }
  }
}

const triggerHasMissingParams = (node: NodeDataStep) => {
  const triggerValue = getTriggerValue(node)
  if (!triggerValue) {
    return false
  }

  const { type, configuration } = triggerValue
  switch (type) {
    case 'SCHEDULED':
      return scheduledTriggerHasMissingParams(configuration)
    case 'INTEGRATION_EVENT':
    default:
      return false
  }
}

export const useGetMissedRequiredFields = () => {
  const hasMissingParamsFunc = useHasMissingParams()

  return useCallback(
    (nodes: Array<{ data: NodeDataStep }>) =>
      (nodes || []).reduce((acc, node) => {
        const { name } = node.data?.step ?? {}
        const hasMissingRequiredParams = hasMissingParamsFunc(node.data)

        if (!hasMissingRequiredParams) {
          return acc
        }

        return [...acc, { name, node }]
      }, [] as Array<any>),
    [hasMissingParamsFunc]
  )
}

export const typeAllowEmpty = (type?: string) =>
  ['toggle', 'multi-select', 'connected-accounts-multi-select'].includes(type ?? '')

const getStepReferences = (value: string) =>
  [...(value || '').matchAll(HAS_OUTPUT)].map(match => match[1].split('.')[0]) as string[]

export type InvalidNode = { node: Node; name: string; invalidReferences: string[] }
export const getStepsWithInvalidReference = (nodes: Node[]) => {
  const nodesWithoutComments = nodes.filter(node => node.type !== NodeType.COMMENT)

  return nodesWithoutComments.reduce((acc, node) => {
    const params = node.data.step.parameters ?? []

    const invalidReferences = params.reduce((acc: string[], param: Parameter) => {
      const stringifiedValue = JSON.stringify(param.value)

      // we should not include integrations into wrong step references as it is not the step
      const stepReferences = [...new Set(getStepReferences(stringifiedValue))].filter(
        stepReference => stepReference !== 'integrations'
      )

      if (!stepReferences.length) {
        return acc
      }

      return [
        ...acc,
        ...stepReferences.filter(stepName => !nodesWithoutComments.some(item => item.data.step.name === stepName))
      ]
    }, [] as string[])

    if (!invalidReferences.length) {
      return acc
    }

    return [...acc, { node, name: node.data.step.name, invalidReferences }]
  }, [] as InvalidNode[])
}
export const isCliIntegration = (param: Parameter) =>
  ((param.id.startsWith('CODE') ||
    param.id.startsWith('AWS') ||
    param.id.startsWith('GCP') ||
    param.id.startsWith('AZURE')) &&
    param.type === 'integrations') ||
  IGNORED_PARAMS_FOR_CLI.includes(param.id)

export const getStepIcon = (subgroup: any, stepTemplateId: any) => {
  const stepForUI = getStepActionForUI(subgroup, stepTemplateId)
  const { icon } = stepForUI

  const iconSrc = icon ? `/images/${icon}` : WFSubGroupIcons[subgroup as keyof typeof WFSubGroupIcons]

  try {
    return {
      iconSrc,
      iconTitle: (iconSrc.split('/').pop() || '').split('.')[0]
    }
  } catch (e) {
    return {
      iconSrc,
      iconTitle: subgroup
    }
  }
}
