import OrganizationDropdown from 'src/common/layouts/components/shared-components/OrganizationDropdown'
import { UserDropdown } from 'src/common/layouts/components/shared-components/UserDropdown'
import { Flex } from '../styled-components/flex'
import styles from './styles.module.css'
import { PageTitle } from './components/PageTitle'

export const AppBar = () => {
  return (
    <Flex className={styles.appBar}>
      <PageTitle />
      <Flex>
        <OrganizationDropdown />
        <UserDropdown />
      </Flex>
    </Flex>
  )
}
