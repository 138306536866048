import omit from 'lodash/omit'
import { TableConfiguration } from 'src/common/utils/tableConfiguration/types'
import { Configuration } from 'src/common/hooks/recommendations/useConfiguration'
import { MAX_PAGE_RECOMMENDATIONS } from 'src/modules/opportunities/utils'
import { request } from './api'

export async function getConfiguration(recommendationType: string) {
  return await request(`recommendations/${recommendationType}/configurations`)
}

export async function createConfiguration(
  recommendationType: string,
  configuration: {
    data: any
    table: TableConfiguration
  }
) {
  return await request(`recommendations/${recommendationType}/configurations`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(configuration)
  })
}

export async function patchConfiguration(recommendationType: string, configuration: Partial<Configuration>) {
  return await request(`recommendations/${recommendationType}/configurations`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(configuration)
  })
}

export async function updateStatus(payload: { recommendationIds: string[]; status: string }) {
  return await request(`recommendations/batch_update_status`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
}

export async function deleteConfiguration(recommendationType: string) {
  return await request(`recommendations/${recommendationType}/configurations`, {
    method: 'DELETE'
  })
}

export async function getFilters() {
  return await request('recommendations/filters')
}

export async function getFilter(filterKey: string) {
  return await request(`recommendations/filters/${filterKey}/values`)
}

export async function recommendationsSummary(filter: object) {
  return await request('recommendations/summary', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(filter)
  })
}

export async function recommendationsDetails(
  filter: object,
  recommendationType = '',
  recommendationAccountId = '',
  startPageKey?: string
) {
  const pagination = {
    max_returned_items: MAX_PAGE_RECOMMENDATIONS,
    start_page_key: startPageKey
      ? {
          scroll_id: startPageKey
        }
      : null
  }
  let url = `recommendations/details`
  if (recommendationType) {
    url += '?recommendation_type=' + recommendationType
  } else if (recommendationAccountId) {
    url += '?recommendation_account_id=' + recommendationAccountId
  }
  const body = JSON.stringify({
    ...omit(filter, 'group_by'),
    ...pagination
  })

  return await request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  })
}

export const addComment = async ({ comment, recommendationIds }: { comment: string; recommendationIds: string[] }) => {
  return await request('recommendations/batch_add_comment', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      recommendationIds,
      comment
    })
  })
}
