import { useQuery } from 'react-query'
import { NotificationEntityType } from 'src/common/types/common.types'
import { LONG_STALE_TIME } from 'src/common/constants/common.constants'
import { QUERY_KEYS } from 'src/common/constants/query-keys'
import { fetchWorkflows } from 'src/services/workflows.api'
import { WorkflowMode } from 'src/modules/wfe/wfe.types'

export type Workflow = {
  createdBy: string
  creationTime: string
  description: string
  executions: string
  labels: string[]
  lastRuns: { status: string; time: string }[]
  lastUpdateBy: string
  lastUpdateTime: string
  name: string
  notifications: Array<NotificationEntityType>
  path: string
  revisionId: string
  state: string
  tenantId: string
  inputParameters: { defaultValue: string; id: string; optional: boolean; recordId: string; type: string }[]
  trigger: {
    lastRun: string
    nextRun: string
    scheduleType: string
    type: string
  }
  workflowId: string
  workflowMode?: WorkflowMode
}

export const useWorkflows = (options?: any) => {
  const {
    data: workflows = [],
    isLoading,
    isError
  } = useQuery<Workflow[]>(QUERY_KEYS.workflows, fetchWorkflows, {
    staleTime: LONG_STALE_TIME,
    ...options
  })

  if (options?.showAllModes) {
    return { workflows, isLoading, isError }
  }

  return { workflows: workflows?.filter((wf: any) => wf.workflowMode !== WorkflowMode.SYSTEM), isLoading, isError }
}
