import React from 'react'
import { Tooltip } from '@mui/material'
import Counter from '../Counter'
import { Flex } from '../styled-components/flex'
import styles from './index.module.css'

type MuiIconPropsType = {
  icon: any
  height?: number
  width?: number
  onMouseLeave?: () => void
}
type IconCounterProps = {
  muiIconProps: MuiIconPropsType
  counter: number
  onClick?: () => void
  onMouseLeave?: () => void
  title?: string
  tooltip?: any
}

const IconCounter = ({ tooltip, muiIconProps, counter, onClick, title }: IconCounterProps) => {
  return (
    <Tooltip title={tooltip} placement='top' arrow>
      <div className={styles.iconCount} onClick={onClick}>
        <Flex gap={8}>
          {muiIconProps.icon}

          {title && <div className={styles.title}>{title}</div>}
          {
            <Counter
              counter={counter}
              style={{
                opacity: counter ? 1 : 0
              }}
            />
          }
        </Flex>
      </div>
    </Tooltip>
  )
}

export default IconCounter
