import { IntegrationEventInstance, IntegrationInstance } from 'src/modules/integrations/types'
import { dataToBE, request } from './api'

export async function fetchIntegrations() {
  return await request(`integrations`)
}

export async function getSlackIntegrationChannels(id: string) {
  return await request(`integrations/${id}/channels`)
}

export async function getAWSAccounts(id: string) {
  return await request(`integrations/aws/${id}/accounts`)
}

export async function getAWSConnectedAccounts(id: string) {
  return await request(`integrations/aws/${id}/connected-accounts`)
}

export async function refreshAWSAccounts(id: string) {
  return await request(`integrations/aws/${id}/accounts`, { method: 'PUT' })
}

export const getAwsPermissions = async () => await request(`integrations/aws/getPermissions`, { method: 'GET' })

export const customPermissions = async (integrationId: string, permissions: string[]) =>
  await request(`integrations/aws/customPermissions/${integrationId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(permissions)
  })

export async function getGithubRepos(integrationId: string) {
  return await request(`integrations/github/${integrationId}/repositories`)
}

export async function getGithubWorkflows(integrationId: string, repoId: string) {
  const [owner, repoName] = repoId.split('/')

  return await request(`integrations/github/${integrationId}/repositories/${owner}/${repoName}/workflows`)
}

export async function getGithubWorkflowInputs(integrationId: string, repoId: string, workflowId: string) {
  const [owner, repoName] = repoId.split('/')

  return await request(
    `integrations/github/${integrationId}/repositories/${owner}/${repoName}/workflows/${workflowId}/inputs`
  )
}

export async function getJiraProjects(integrationId: string) {
  return await request(`integrations/jira/${integrationId}/projects`)
}

export async function getJiraProjectUsers(integrationId: string, projectId: string) {
  return await request(`integrations/jira/${integrationId}/projects/${projectId}/users`)
}

export async function getJiraProjectPriorities(integrationId: string, projectId: string) {
  return await request(`integrations/jira/${integrationId}/projects/${projectId}/priorities`)
}

export async function getJiraProjectIssueTypes(integrationId: string, projectId: string) {
  return await request(`integrations/jira/${integrationId}/projects/${projectId}/issueTypes`)
}

export async function getJiraProjectStatuses(integrationId: string, projectId: string) {
  return await request(`integrations/jira/${integrationId}/projects/${projectId}/statuses`)
}

export async function getTeams(integrationId: string) {
  return await request(`integrations/teams/${integrationId}/teams`)
}

export async function getTeamsMembers(integrationId: string) {
  return await request(`integrations/teams/${integrationId}/members`)
}

export async function getTeamsChannels(integrationId: string, teamId: string) {
  return await request(`integrations/teams/${integrationId}/${teamId}/channels`)
}

export async function createIntegration(integration: Partial<IntegrationInstance>) {
  const response = await request(`integrations`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(dataToBE(integration))
  })

  return response
}

export async function deleteIntegration(id: string) {
  const response = await request(`integrations/${id}`, {
    method: 'DELETE'
  })

  return response
}

export async function getIntegrationEvents(integrationId: string) {
  return await request(`integrations/${integrationId}/events`)
}

export async function createIntegrationEvent(integrationId: string, event: IntegrationEventInstance) {
  return await request(`integrations/${integrationId}/events`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(dataToBE(event))
  })
}

export async function updateIntegrationEvent(integrationId: string, eventId: string, event: IntegrationEventInstance) {
  return await request(`integrations/${integrationId}/events/${eventId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(dataToBE(event))
  })
}

export async function deleteIntegrationEvent(integrationId: string, eventId: string) {
  return await request(`integrations/${integrationId}/events/${eventId}`, {
    method: 'DELETE'
  })
}

export const extractTags = async (integrationId: string, resource: string) => {
  return await request(`integrations/aws/${integrationId}/resources/tags/${resource}`)
}
