import { useQuery } from 'react-query'
import { LONG_STALE_TIME } from 'src/common/constants/common.constants'
import { ALL } from 'src/modules/opportunities/components/Filters/utils'
import { Recommendation, SelectedFilters } from 'src/modules/opportunities/types'
import { getFilterBody, getSummaryKey } from 'src/modules/opportunities/utils'
import { recommendationsSummary } from 'src/services/recommendations.api'

export const useRecommendationsSummary = ({
  selectedFilters,
  board_id
}: {
  selectedFilters: SelectedFilters
  board_id?: string
}) => {
  const { data: recommendations, isLoading } = useQuery<Array<Recommendation>>(
    getSummaryKey({ selectedFilters, board_id }),
    () => {
      const filters = getFilterBody(selectedFilters)

      if (board_id && board_id !== ALL) {
        return recommendationsSummary({ ...filters, board_id })
      }

      return recommendationsSummary(filters)
    },
    {
      staleTime: LONG_STALE_TIME
    }
  )

  return { recommendations, isLoading }
}
