import React, { useState } from 'react'
import cx from 'classnames'
import { shallow } from 'zustand/shallow'

import useStore from '../../wfe.store'
import { useIsEditableWorkflow } from '../../hooks/useIsEditableWorkflow'
import { WorkflowMode } from '../../wfe.types'
import styles from './style.module.css'
import LeftPart from './components/LeftPart'
import RightPart from './components/RightPart'

const selector = (state: any) => ({
  workflow: state.workflow
})

const WFEHeader = () => {
  const [hasWorkflowNameError, setHasWorkflowNameError] = useState(false)
  const { workflow } = useStore(selector, shallow)

  const isEditable = useIsEditableWorkflow(workflow)

  return (
    <div
      className={cx(styles.wfeHeader, {
        [styles.bigHeight]: hasWorkflowNameError
      })}
    >
      <LeftPart
        isEditable={isEditable}
        hasWorkflowNameError={hasWorkflowNameError}
        setHasWorkflowNameError={setHasWorkflowNameError}
      />
      <RightPart isEditable={isEditable} isSystem={workflow?.workflowMode === WorkflowMode.SYSTEM} />
    </div>
  )
}

export default WFEHeader
