import { useRouter } from 'next/router'
import { routes } from 'src/common/constants/routes.constants'
import { strings } from 'src/common/constants/strings'
import { BreadcrumbType, Breadcrumbs } from '../Breadcrumbs'

const WORKFLOW_INDEX = -1
export const WorkflowBreadcrumbs = () => {
  const { query, push } = useRouter()
  const currentPath = typeof query?.path === 'string' ? query.path.split('/') : []

  const onClickPath = (index: number) => () => {
    if (index === WORKFLOW_INDEX) {
      if (currentPath.length) {
        push(routes.workflows.path)
      }
    } else if (index !== currentPath.length - 1) {
      push(
        `${routes.workflows.path}?path=${currentPath
          .slice(0, index + 1)
          .reduce((acc, curr) => acc + (acc ? '/' : '') + curr, '')}`
      )
    }
  }

  const breadcrumbs: Array<BreadcrumbType> = [strings.common.workflows, ...currentPath].map((path, index) => ({
    title: path,
    onClick: onClickPath(index - 1)
  }))

  return <Breadcrumbs breadcrumbs={breadcrumbs} />
}
