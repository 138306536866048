import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link as MuiLink,
  Menu,
  Tooltip
} from '@mui/material'

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/Delete'

// Zustand
import { shallow } from 'zustand/shallow'
import { useMutation } from 'react-query'
import { useRouter } from 'next/router'
import { useTheme } from '@mui/material/styles'
import isEqual from 'lodash/isEqual'
import cx from 'classnames'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import useStore from 'src/modules/wfe/wfe.store'
import { useWarnUnsaved } from 'src/common/hooks/useWarnUnsaved'
import { PUBLISHED, UNPUBLISHED, Workflow, WorkflowState } from 'src/common/types/common.types'
import { cloneWorkflow, deleteWorkflow, updateWorkflowPartial } from 'src/services/workflows.api'
import { WorkflowDeleteDialog } from 'src/modules/workflows/WorkflowDeleteDialog'
import { createWorkflowTemplate } from 'src/services/workflow-templates.api'
import { Spinner } from 'src/components/spinner'
import InternalButton from 'src/components/Button'
import { WorkflowCreateTemplateDialog } from 'src/modules/workflows/WorkflowCreateTemplateDialog'
import { UnsavedDialog } from 'src/components/unsaved-dialog'

import stylesCommon from 'src/common/styles/common/common.module.css'
import { strings } from 'src/common/constants/strings'
import { routes } from 'src/common/constants/routes.constants'
import { REACT_FLOW_KEYS_TO_REMOVE, WorkflowExecutionStatus } from '../../../../wfe.constants'
import { getStepsWithInvalidReference, InvalidNode, useGetMissedRequiredFields } from '../../../../wfe.helper'
import { useWFAction } from '../../../../hooks/useWFAction'

import Toggle from '../../../../../../components/Toggle'
import { Flex } from '../../../../../../components/styled-components/flex'
import ExecutionSummaryDropdown from '../../../../../../components/ExecutionSummaryDropdown'
import { ConfirmRunDialog } from './ConfirmRunDialog'
import { onRunBtnClick, runClick, runTooltipContentRenderer, saveClick, stopClick } from './utils'
import styles from './style.module.css'
import { ShowMessage } from './ShowMessage'

const getNodesString = (nodes?: Array<object>) => {
  return JSON.stringify(nodes?.map(node => omit(node, REACT_FLOW_KEYS_TO_REMOVE)))
}

const selector = (state: any) => ({
  isWorkflowRunning: state.isWorkflowRunning,
  workflowExecution: state.workflowExecution,
  setIsWorkflowRunning: state.setIsWorkflowRunning,
  setIsWorkflowExecutionOpened: state.setIsWorkflowExecutionOpened,
  activeWorkflowVersion: state.activeWorkflowVersion,
  inputParameters: state.inputParameters,
  stepExecutions: state.stepExecutions,
  runningExecutions: state.runningExecutions,
  stoppedExecutions: state.stoppedExecutions
})

const selectorUI = (state: any) => ({
  workflow: state.workflow,
  setFocusedNode: state.setFocusedNode
})

const RightPart = ({ isEditable, isSystem }: { isEditable: boolean; isSystem: boolean }) => {
  const { workflow: workflowUI, setFocusedNode } = useStore(selectorUI, shallow)

  const { isSaving, setIsSaving, save, isCallingRun, setIsCallingRun, run, stop, workflow, nodes } = useWFAction()
  const {
    isWorkflowRunning,
    workflowExecution,
    setIsWorkflowRunning,
    inputParameters,
    setIsWorkflowExecutionOpened,
    activeWorkflowVersion
  } = useStore(selector, shallow)
  const prevNodesString = useRef('')
  const prevName = useRef(undefined)

  const [tempInputParameters, setTempInputParameters] = useState(inputParameters ?? [])
  const [published, setPublished] = useState(UNPUBLISHED)
  const [isRunEnabled, setIsRunEnabled] = useState(false)
  const [missedRequiredFields, setMissedRequiredFields] = useState<InvalidNode[]>([])
  const [stepsWithInvalidReference, setStepsWithInvalidReference] = useState<InvalidNode[]>([])
  const [isSaveEnabled, setIsSaveEnabled] = useState(false)
  const [isConfirmRunOpen, setIsConfirmRunOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [deletedWorkflow, setDeletedWorkflow] = useState<Workflow | null>(null)
  const [clonedWorkflow, setClonedWorkflow] = useState<Workflow | null>(null)
  const [isCreateTemplateShow, setIsCreateTemplateShow] = useState<boolean>(false)
  const [isCreateTemplateLoading, setIsCreateTemplateLoading] = useState<boolean>(false)
  const [isCopyWorkflowLoading, setIsCopyWorkflowLoading] = useState<boolean>(false)
  const [nextNavigationUrl, setNextNavigationUrl] = useState('')
  const [showUnsavedDialog, setShowUnsavedDialog] = useState(false)
  const [showWFAbortConfirmDialog, setShowWFAbortConfirmDialog] = useState(false)

  const router = useRouter()
  const theme = useTheme()

  const isPublished = published === PUBLISHED

  const deleteMutation = useMutation((workflowId: string) => deleteWorkflow(workflowId), {
    onMutate: async () => {
      setAnchorEl(null)
      router.push(routes.workflows.path)
    }
  })

  const copyMutation = useMutation((workflowId: string) => cloneWorkflow(workflowId), {
    onSuccess: async data => {
      setIsCopyWorkflowLoading(false)
      setClonedWorkflow(data.data)
      setToastMessage('Workflow Cloned Successfully', false)
    },
    onError: async () => {
      setIsCopyWorkflowLoading(false)
      setToastMessage('Workflow Copy Failed')
    }
  })

  const getMissedRequiredFields = useGetMissedRequiredFields()

  useEffect(() => {
    const nodeString = getNodesString(nodes)
    const missedRequiredFields = getMissedRequiredFields(nodes)
    const stepsWithInvalidReference = getStepsWithInvalidReference(nodes)
    const hasChanges =
      prevNodesString.current && prevNodesString.current !== '[]' && nodeString !== prevNodesString.current
    const nameChanges = prevName.current && workflowUI?.name !== prevName.current

    prevNodesString.current = nodeString
    prevName.current = workflowUI?.name
    if (hasChanges || nameChanges || (tempInputParameters?.length && !isEqual(tempInputParameters, inputParameters))) {
      setIsRunEnabled(false)
      setIsSaveEnabled(true)
    }
    setTempInputParameters(inputParameters)
    setMissedRequiredFields(missedRequiredFields)
    setStepsWithInvalidReference(stepsWithInvalidReference)
  }, [workflow, tempInputParameters, inputParameters, nodes, workflowUI?.name, getMissedRequiredFields])

  useEffect(() => {
    setPublished(workflowUI?.state)
  }, [workflowUI?.state])

  useWarnUnsaved(isSaveEnabled, url => {
    setNextNavigationUrl(url)
    setShowUnsavedDialog(true)
  })

  useEffect(() => {
    const isNew = window.location.href.includes('isNew')
    setIsRunEnabled(!isNew)
    setIsSaveEnabled(isNew)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setPublishMutation = useMutation(
    (data: { workflowId: string; state: WorkflowState }) =>
      updateWorkflowPartial(data.workflowId, { state: data.state }),
    {
      onMutate: async data => {
        setPublished(data.state)
      }
    }
  )

  const getStepLink = useCallback(
    (data: InvalidNode) => (
      <MuiLink
        className={stylesCommon.whiteLink + ' ' + styles.warningStepLink}
        key={data.name}
        onClick={() => setFocusedNode(data.node.data)}
      >
        {data.name}
      </MuiLink>
    ),
    [setFocusedNode]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const runTooltipContent = useMemo(
    runTooltipContentRenderer({
      getStepLink,
      isRunEnabled,
      workflow,
      missedRequiredFields,
      stepsWithInvalidReference
    }),
    [isRunEnabled, workflow, missedRequiredFields, stepsWithInvalidReference, getStepLink]
  )

  if (!workflow) return null

  const setToastMessage = (message: string, cleanClonedWorkflow = true) => {
    if (cleanClonedWorkflow && clonedWorkflow !== null) {
      setClonedWorkflow(null)
    }
    setMessage(message)
  }

  const shouldDisableButtonsCommon = !workflow || !!activeWorkflowVersion
  const disabledRunButton = !isRunEnabled || shouldDisableButtonsCommon
  const disabledSaveButton = !isSaveEnabled || shouldDisableButtonsCommon

  const isRunningSteps = workflowExecution?.status === WorkflowExecutionStatus.RunningSteps

  if (!isEditable) {
    return (
      <div className={styles.rightPart} style={{ paddingRight: '8px' }}>
        <Tooltip title={strings.workflows.cloneWorkflow}>
          <IconButton
            color={'secondary'}
            onClick={async e => {
              e.stopPropagation()
              setIsCopyWorkflowLoading(true)
              copyMutation.mutate(workflow.workflowId)
            }}
          >
            {!isCopyWorkflowLoading && <ContentCopyIcon fontSize='small' />}
            {isCopyWorkflowLoading && <Spinner size='small' />}
          </IconButton>
        </Tooltip>
        <ShowMessage message={message} setMessage={setMessage} clonedWorkflow={clonedWorkflow} />
      </div>
    )
  }

  return (
    <div className={styles.rightPart}>
      {(isRunningSteps || workflowExecution.status !== WorkflowExecutionStatus.Ready) && <ExecutionSummaryDropdown />}
      <div className={styles.buttonsContainer}>
        {isWorkflowRunning ? (
          <InternalButton
            iconProps={{ name: 'stop', height: 18, width: 18 }}
            title={strings.common.stop}
            onClick={() => setShowWFAbortConfirmDialog(true)}
          />
        ) : (
          <Tooltip title={!activeWorkflowVersion ? runTooltipContent : ''}>
            <span style={{ position: 'relative' }}>
              {(!isEmpty(missedRequiredFields) || !isEmpty(stepsWithInvalidReference)) && !activeWorkflowVersion && (
                <Badge
                  badgeContent={<img src='/images/icons/monitoring/failed.svg' alt='Missing' />}
                  sx={{
                    position: 'absolute',
                    zIndex: 100,
                    right: 8,
                    top: 5,
                    '& .MuiBadge-badge': {
                      width: '24px',
                      height: '24px',
                      borderRadius: '100%',
                      '& img': { width: '16px' }
                    }
                  }}
                />
              )}
              <InternalButton
                minWidth={72}
                title={strings.common.run}
                isDisabled={disabledRunButton}
                onClick={onRunBtnClick({
                  missedRequiredFields,
                  stepsWithInvalidReference,
                  setIsConfirmRunOpen,
                  runClick: runClick({
                    setIsSaveEnabled,
                    run,
                    setIsWorkflowRunning,
                    setIsWorkflowExecutionOpened,
                    setToastMessage,
                    setIsCallingRun
                  })
                })}
                iconProps={
                  isCallingRun
                    ? undefined
                    : {
                        name: 'run',
                        color: disabledRunButton ? undefined : 'white',
                        height: 18,
                        width: 18
                      }
                }
              >
                {isCallingRun && <Spinner size='small' variant='secondary' />}
              </InternalButton>
            </span>
          </Tooltip>
        )}

        <Dialog open={showWFAbortConfirmDialog} onClose={() => setShowWFAbortConfirmDialog(false)}>
          <DialogTitle>Confirm workflow execution abort </DialogTitle>
          <DialogContent>Are you sure you want to abort workflow execution?</DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowWFAbortConfirmDialog(false)
              }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              onClick={async () => {
                setShowWFAbortConfirmDialog(false)
                stopClick({ stop, workflowExecution, setIsWorkflowRunning, setToastMessage, setIsCallingRun })
              }}
            >
              Abort
            </Button>
          </DialogActions>
        </Dialog>

        <Badge color='warning' badgeContent={isSystem ? strings.common.system : null}>
          <InternalButton
            minWidth={72}
            title={strings.common.save}
            variant='secondary'
            isDisabled={disabledSaveButton}
            onClick={saveClick({
              isSaving,
              setIsRunEnabled,
              save,
              setToastMessage,
              setIsSaveEnabled,
              setIsSaving
            })}
          >
            {isSaving && <Spinner size='small' />}
          </InternalButton>
        </Badge>
      </div>
      <Flex gap={8}>
        <Flex gap={8} width={107}>
          <Toggle
            isToggled={isPublished}
            onClick={() =>
              setPublishMutation.mutate({
                workflowId: workflow.workflowId,
                state: isPublished ? UNPUBLISHED : PUBLISHED
              })
            }
          />
          <span className={styles.enable}>{isPublished ? strings.common.enabled : strings.common.disabled}</span>
        </Flex>
        <div>
          <MoreVertIcon
            className={cx(styles.menuIcon, {
              [styles.disabled]: !!activeWorkflowVersion,
              [styles.active]: !!anchorEl
            })}
            onClick={e => !activeWorkflowVersion && setAnchorEl(e.currentTarget as any)}
            fill='var(--neutralBrandBlue40)'
            sx={anchorEl ? { fill: 'unset !important' } : undefined}
          />
          <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={() => setAnchorEl(null)}
            PaperProps={{
              style: {
                pointerEvents: workflow ? 'all' : 'none'
              }
            }}
          >
            <IconButton
              color={'error'}
              onClick={async e => {
                e.stopPropagation()
                setIsCreateTemplateShow(true)
              }}
              sx={{ padding: '8px !important' }}
            >
              <NoteAddIcon sx={{ marginLeft: -1 }} fontSize='medium' />
              <span>Create template</span>
            </IconButton>

            <IconButton
              color={'error'}
              onClick={async e => {
                e.stopPropagation()
                setIsCopyWorkflowLoading(true)
                copyMutation.mutate(workflow.workflowId)
              }}
              sx={{ padding: '8px !important' }}
            >
              {!isCopyWorkflowLoading && <ContentCopyIcon sx={{ marginLeft: -1 }} fontSize='medium' />}
              {isCopyWorkflowLoading && <Spinner size='small' variant='secondary' />}
              <span>Clone workflow</span>
            </IconButton>

            <IconButton
              color={'error'}
              onClick={async e => {
                e.stopPropagation()
                await setDeletedWorkflow(workflow)
              }}
              sx={{ padding: '8px !important' }}
            >
              <DeleteIcon sx={{ marginLeft: -1 }} color={'error'} fontSize='medium' />
              <span style={{ color: theme.palette.error.main }}>Delete</span>
            </IconButton>
          </Menu>
        </div>
      </Flex>
      <ShowMessage message={message} setMessage={setMessage} clonedWorkflow={clonedWorkflow} />

      <ConfirmRunDialog
        open={isConfirmRunOpen}
        onClose={() => setIsConfirmRunOpen(false)}
        onRun={runClick({
          setIsSaveEnabled,
          run,
          setIsWorkflowRunning,
          setIsWorkflowExecutionOpened,
          setToastMessage,
          setIsCallingRun
        })}
      />

      <WorkflowCreateTemplateDialog
        isCreateTemplateShow={isCreateTemplateShow}
        isTemplateCreating={isCreateTemplateLoading}
        workflow={workflow}
        onCreateTemplate={async (template: any) => {
          template.graph = workflow.graph
          template.input_parameters = inputParameters
          setIsCreateTemplateLoading(true)

          try {
            await createWorkflowTemplate(template)
            setToastMessage('Template Creation Succeeded')
          } catch (e) {
            setToastMessage('Template Creation Failed')
          } finally {
            setIsCreateTemplateLoading(false)
            setIsCreateTemplateShow(false)
          }
        }}
        onClose={() => {
          setIsCreateTemplateShow(false)
        }}
      />

      <WorkflowDeleteDialog
        deletedWorkflow={deletedWorkflow}
        onClose={() => setDeletedWorkflow(null)}
        onDelete={() => deleteMutation.mutate(String(deletedWorkflow?.workflowId))}
      />

      <UnsavedDialog
        open={showUnsavedDialog}
        onClose={() => setShowUnsavedDialog(false)}
        nextNavigationUrl={nextNavigationUrl}
      />
    </div>
  )
}

export default RightPart
