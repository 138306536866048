import { LegacyRef, createContext, useContext, forwardRef } from 'react'
import { FixedSizeList } from 'react-window'

// for autocmpplete with long options lists
// <Autocomplete ...props ListboxComponent={VirtualizedAutocomplete}/>

const LISTBOX_PADDING = 8

const OuterElementContext = createContext({})

const OuterElementType = forwardRef((props, ref?: LegacyRef<HTMLDivElement>) => {
  const outerProps = useContext(OuterElementContext)

  return <div ref={ref} {...props} {...outerProps} />
})

const renderRow = ({ data, index, style, ...props }: any) => {
  const option = data[index]

  return (
    <div style={{ ...style, top: style.top + LISTBOX_PADDING }} {...props}>
      {option}
    </div>
  )
}

// Adapter for react-window
const itemSize = 36
export const VirtualizedAutocomplete = forwardRef(function ListboxComponent(
  { children: itemData, ...other }: any,
  ref: LegacyRef<HTMLDivElement>
) {
  const itemCount = itemData.length
  const height = Math.min(8, itemCount) * itemSize

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          height={height + 2 * LISTBOX_PADDING}
          innerElementType='ul'
          width={'100%'}
          itemData={itemData}
          itemCount={itemCount}
          itemSize={itemSize}
          outerElementType={OuterElementType}
          overscanCount={5}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})
