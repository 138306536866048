import { usePermissionLinks } from 'src/components/navigation/vertical'
import { navigationItems, settingsNavItems } from 'src/common/constants/routes.constants'
import { NavBar as NavBarComponent } from './NavBar'

type Props = {
  isOpen: boolean
  onToggle: () => void
}

export const NavBar = (props: Props) => {
  const navItems = usePermissionLinks(navigationItems)
  const systemNavItems = usePermissionLinks(settingsNavItems)

  return (
    <NavBarComponent
      navItems={navItems}
      systemNavItems={systemNavItems}
      isOpen={props.isOpen}
      onToggle={props.onToggle}
    />
  )
}
