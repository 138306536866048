import { useUserPermissions } from 'src/common/auth/auth.hooks'
import { NavLink } from 'src/common/layouts/types'

export const usePermissionLinks = (navigationItems: NavLink[]) => {
  const permissions = useUserPermissions()

  const flags: Record<string, boolean | undefined> = {}

  const openNavigationItems = navigationItems.filter(({ flag, flagHide }) => {
    if (flagHide && flags[flagHide as keyof object]) {
      return false
    }

    if (!flag) {
      return true
    }

    return flags[flag as keyof object]
  })

  const hasPermission = ({ permission }: NavLink) => !permission || permissions.includes(permission)

  return openNavigationItems.reduce((acc, navItem) => {
    if (navItem.subLinks?.length) {
      const subLinks = navItem.subLinks.filter(hasPermission)
      if (subLinks.length) {
        acc.push({ ...navItem, subLinks })
      }

      return acc
    } else {
      if (hasPermission(navItem)) {
        acc.push(navItem)
      }

      return acc
    }
  }, [] as NavLink[])
}
