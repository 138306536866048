import React, { memo } from 'react'
import { IconProps } from '../Icon'
import Text, { TextProps } from '../Text'

export type CheckboxProps = {
  isChecked: boolean
  setIsChecked?: (isChecked: boolean) => void
  iconProps?: IconProps
  title?: string
  textProps?: TextProps
}

const Checkbox = memo(({ textProps, title, isChecked, setIsChecked, iconProps }: CheckboxProps) => {
  return (
    <Text
      style={{ wordBreak: 'break-word' }}
      onClick={() => setIsChecked?.(!isChecked)}
      title={title}
      iconProps={{
        name: isChecked ? 'checkboxChecked' : 'checkboxUnchecked',
        height: 24,
        width: 24,
        ...iconProps
      }}
      {...textProps}
    />
  )
})

export default Checkbox
