import React from 'react'
import cx from 'classnames'
import { strings } from '../../common/constants/strings'
import { Flex } from '../styled-components/flex'
import Icon from '../Icon'
import TruncateTitle from '../TruncateTitle'
import styles from './index.module.css'

type CopyErrorProps = {
  title: string
  description: string
  className?: string
  descriptionMaxLength?: number
  isWithDot?: boolean
  isLink?: boolean
}

const CopyError = ({
  isWithDot = true,
  isLink,
  className = '',
  title,
  description,
  descriptionMaxLength = 1000
}: CopyErrorProps) => (
  <div className={cx(className, styles.copyError)}>
    <Flex className={styles.ErrorTitle}>
      {isWithDot && <div className={styles.colorDot} />}
      <div className={styles.title}>{title}</div>
    </Flex>
    {description && (
      <div className={styles.descriptionContainer}>
        <div className={styles.description}>
          <TruncateTitle
            isLink={isLink}
            placement={'left'}
            title={description}
            maxLength={descriptionMaxLength}
            tooltipMinLength={descriptionMaxLength}
          />
        </div>
        <Icon
          className={styles.copyIcon}
          tooltipProps={{ title: strings.common.copy }}
          name='copy'
          height={24}
          width={24}
          hoverColor='var(--neutralBrandBlue60)'
          color='var(--neutralBrandBlue40)'
          onClick={() => {
            navigator.clipboard.writeText(description)
          }}
        />
      </div>
    )}
  </div>
)

export default CopyError
