import { useRouter } from 'next/router'
import { routes } from 'src/common/constants/routes.constants'
import { strings } from 'src/common/constants/strings'
import { useDatastore } from 'src/modules/datastores/hooks/useDatastore'
import { Breadcrumbs } from '../Breadcrumbs'
import { DatastoreName } from './DatastoreName'

export const DatastoreBreadcrumbs = () => {
  const { query } = useRouter()
  const id: string = query.datastoreId?.toString() ?? ''

  const { datastore } = useDatastore(id)
  const datastoreName = datastore?.metadata?.name || ''
  const breadcrumbs: Array<any> = [{ title: strings.datastores.title, path: routes.datastores.path }]

  if (datastoreName) {
    breadcrumbs.push({ title: <DatastoreName datastore={datastore} /> })
  }

  return <Breadcrumbs breadcrumbs={breadcrumbs} />
}
