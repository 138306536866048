const Link = () => {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'underline !important'
          }
        }
      }
    }
  }
}

export default Link
