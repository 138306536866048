import Link from 'next/link'
import { useRouter } from 'next/router'
import cx from 'classnames'
import noop from 'lodash/noop'
import { useState } from 'react'
import { Box, IconButton, Drawer, Tooltip } from '@mui/material'
import Image from 'next/image'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { NavLink } from 'src/common/layouts/types'
import { routes } from 'src/common/constants/routes.constants'
import { SettingsModal } from 'src/modules/systemSettings/components/SettingsModal'
import { Accordion } from '../../Accordion'
import styles from './styles.module.css'

type Props = {
  navItems: NavLink[]
  systemNavItems: NavLink[]
  isOpen: boolean
  onToggle: () => void
}

export const collapsedSidebarWidth = 64
export const expandedSidebarWidth = 192

export const NavBar = ({ navItems, systemNavItems, onToggle, isOpen }: Props) => {
  const [subLinksAccordion, setSubLinksAccordion] = useState<Record<string, boolean>>({})
  const router = useRouter()

  const sidebarWidth = isOpen ? `${expandedSidebarWidth}px !important` : `${collapsedSidebarWidth}px !important`
  const [isOpenSettingsModal, setIsOpenSettingsModal] = useState(false)

  return (
    <Drawer
      variant='permanent'
      className={styles.navBar}
      open={isOpen}
      sx={{
        width: sidebarWidth,
        '& .MuiDrawer-paper': {
          width: sidebarWidth
        },
        '& .MuiPaper-root': {
          ...(!isOpen ? { width: '32px' } : {})
        }
      }}
    >
      <div
        style={{
          width: (isOpen ? expandedSidebarWidth - 24 : collapsedSidebarWidth - 24) + 'px',
          transition: 'all 0.5s ease'
        }}
      >
        <Image
          className={styles.logo}
          src={'/images/logo.svg'}
          style={{ cursor: 'pointer' }}
          alt='wiv'
          width={24}
          height={24}
          onClick={() => router.push(routes.overview.path)}
        />
        {navItems.map(navItem => {
          const { title, path, icon, subLinks } = navItem

          const subNavItems = subLinks || []
          const isActiveSection = [path, ...subNavItems.map(i => i.path)].some(item => router.pathname.includes(item))
          const hasSubNavItems = !!subNavItems.length
          const isExpanded = subLinksAccordion[title]

          const handleRedirect = () => {
            if (!hasSubNavItems) {
              router.push(path)
            } else if (!isOpen) {
              setSubLinksAccordion({ [title]: true })
              onToggle()
            }
          }

          return (
            <Accordion
              key={title}
              title={
                <div onClick={handleRedirect}>
                  <Tooltip title={isOpen ? '' : title} placement='right' classes={{ tooltip: styles.navTitleTooltip }}>
                    <Image
                      src={icon}
                      alt={title}
                      width={32}
                      height={32}
                      className={cx(styles.navTitleIcon, {
                        [styles.navTitleSectionIcon]: !isOpen && hasSubNavItems
                      })}
                      style={{
                        ...(isActiveSection && !isOpen && { backgroundColor: 'var(--lightPink) !important' })
                      }}
                    />
                  </Tooltip>
                  <span
                    style={{
                      opacity: isOpen ? '1' : '0',
                      transition: 'all 0.5s ease',
                      visibility: isOpen ? 'visible' : 'hidden'
                    }}
                  >
                    {title}
                  </span>
                </div>
              }
              className={styles.navItem}
              styles={{
                '& .MuiAccordionSummary-root': {
                  ...(isActiveSection && isOpen && !isExpanded && { backgroundColor: 'var(--lightPink) !important' })
                },
                '&:hover': {
                  ...(!isActiveSection &&
                    (!hasSubNavItems || !isExpanded) && { backgroundColor: 'rgba(255, 255, 255, 0.1)' })
                },
                '& .MuiAccordionSummary-expandIconWrapper': {
                  display: hasSubNavItems ? 'flex' : 'none',
                  opacity: isOpen ? '1' : '0',
                  visibility: isOpen ? 'visible' : 'hidden'
                }
              }}
              expanded={!!isExpanded && isOpen}
              setExpanded={expanded => {
                if (hasSubNavItems) {
                  setSubLinksAccordion(prev => ({ ...prev, [title]: expanded }))
                }
              }}
            >
              <>
                {subNavItems.map(subNavItem => (
                  <Link key={subNavItem.title} href={subNavItem.path ?? ''}>
                    <Box
                      className={cx(styles.link, {
                        [styles.linkActive]: router.pathname.includes(subNavItem.path)
                      })}
                    >
                      {subNavItem.title}
                    </Box>
                  </Link>
                ))}
              </>
            </Accordion>
          )
        })}
      </div>
      {systemNavItems.map(navItem => {
        const { title, icon } = navItem

        // we will have more system nav items
        const handleClick = () => setIsOpenSettingsModal(true)

        return (
          <Accordion
            key={title}
            title={
              <div onClick={handleClick}>
                <Tooltip title={isOpen ? '' : title} placement='right' classes={{ tooltip: styles.navTitleTooltip }}>
                  <Image src={icon} alt={title} width={32} height={32} className={cx(styles.navTitleIcon)} />
                </Tooltip>
                <span
                  style={{
                    opacity: isOpen ? '1' : '0',
                    transition: 'all 0.5s ease',
                    visibility: isOpen ? 'visible' : 'hidden'
                  }}
                >
                  {title}
                </span>
              </div>
            }
            className={styles.navItem}
            styles={{
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)'
              },
              '& .MuiAccordionSummary-expandIconWrapper': {
                display: 'none',
                opacity: isOpen ? '1' : '0',
                visibility: isOpen ? 'visible' : 'hidden'
              }
            }}
            expanded={isOpen}
            setExpanded={noop}
          >
            <></>
          </Accordion>
        )
      })}

      <SettingsModal open={isOpenSettingsModal} onClose={() => setIsOpenSettingsModal(false)} />
      <IconButton className={styles.navBarToggleIcon} onClick={onToggle}>
        {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </IconButton>
    </Drawer>
  )
}
