import { InputAdornment, TextField } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react'
import debounce from 'lodash/debounce'
import cx from 'classnames'
import { strings } from 'src/common/constants/strings'
import { containsNonLatinChars } from 'src/common/utils/str.util'
import { useNotify } from 'src/common/hooks/useNotify'
import Icon from '../Icon'
import styles from './styles.module.css'

const DEBOUNCE = 300

export type SearchFieldProps = {
  value?: string
  onChange: (value: string) => void
  placeholder?: string
  focused?: boolean
  debounceInterval?: number
  width?: number | string
  style?: React.CSSProperties
  variant?: 'outlined' | 'contained'
  dataTestId?: string
  iconStyle?: CSSProperties
  isNonLatinCharactersAllowed?: boolean
  onFocus?: () => void
  onBlur?: () => void
  autoFocus?: boolean
  isFullBorder?: boolean
}

export const SearchField = ({
  value,
  onChange,
  placeholder = strings.common.searchPlaceholder,
  focused = false,
  debounceInterval = DEBOUNCE,
  width = 300,
  style,
  variant = 'contained',
  dataTestId = 'search-field',
  iconStyle,
  isNonLatinCharactersAllowed,
  onFocus,
  onBlur,
  autoFocus,
  isFullBorder = true
}: SearchFieldProps) => {
  const theme = useTheme()
  const inputRef = useRef<HTMLInputElement>(null)
  const notify = useNotify()
  const [isFocused, setIsFocused] = useState(autoFocus)
  const [innerValue, setInnerValue] = useState(value ?? '')

  useEffect(() => {
    setInnerValue(value ?? '')
    if (focused) {
      inputRef?.current?.querySelector('input')?.focus()
    }
  }, [value, focused, inputRef])

  const debounceOnChange = useCallback(
    debounce(newValue => {
      onChange(newValue)
    }, debounceInterval),
    [onChange, debounceInterval]
  )

  const handleFocus = () => {
    setIsFocused(true)
    onFocus?.()
  }

  const handleBlur = () => {
    setIsFocused(false)
    onBlur?.()
  }

  return (
    <TextField
      autoFocus={autoFocus}
      onFocus={handleFocus}
      onBlur={handleBlur}
      style={style}
      className={cx(styles.container, {
        [styles.fullBorder]: isFullBorder,
        [styles.bottomBorder]: !isFullBorder
      })}
      ref={inputRef}
      size={'small'}
      value={innerValue}
      placeholder={placeholder}
      onChange={e => {
        const value = e.target.value
        if (!isNonLatinCharactersAllowed && containsNonLatinChars(value)) {
          notify(strings.common.nonLatin, 'error')
          setInnerValue('')

          return
        }
        debounceOnChange(value)
        setInnerValue(value)
      }}
      InputProps={{
        inputProps: {
          'data-testid': dataTestId
        },
        startAdornment: (
          <Icon
            className={styles.icon}
            name='icons/svg/search'
            height={22}
            width={22}
            style={{ ...iconStyle }}
            color={isFocused ? 'var(--neutralBrandBlue40)' : 'var(--neutralBrandBlue30)'}
          />
        ),
        endAdornment: innerValue && (
          <InputAdornment position='end'>
            <Icon
              marginRight={-2}
              name='close'
              height={24}
              width={24}
              hoverColor='var(--neutralBrandBlue60)'
              onClick={() => {
                setInnerValue('')
                onChange('')
              }}
            />
          </InputAdornment>
        )
      }}
      variant='outlined'
      sx={{
        width,
        input: {
          color: theme.palette.customColors.primaryBlueBrand
        },

        '& .MuiOutlinedInput-root': {
          '& fieldset':
            variant === 'contained'
              ? {
                  border: 'none !important'
                }
              : {
                  borderColor: theme.palette.customColors.neutralBrandBlue5
                }
        }
      }}
    />
  )
}
