import { useRouter } from 'next/router'
import { ReactElement } from 'react'
import { useUser, useRedirectFunctions } from '@propelauth/nextjs/client'
import { useAccessPage } from 'src/common/auth/auth.hooks'
import { routes } from '../constants/routes.constants'

export const PrivateRoutes = ({ children }: { children: ReactElement }) => {
  const router = useRouter()
  const user = useUser()
  const { redirectToLoginPage } = useRedirectFunctions()
  const canAccess = useAccessPage(router.pathname)
  const canAccessDashboard = useAccessPage(routes.dashboards.path)

  if (!user?.isLoggedIn && user?.isLoggedIn !== undefined) {
    const currentPath = router.asPath
    redirectToLoginPage({ postLoginRedirectPath: currentPath })
  }
  if (!canAccess) {
    if (canAccessDashboard) {
      router.push(routes.dashboards.path)
    }

    return null
  }

  return children
}
