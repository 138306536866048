import React, { CSSProperties } from 'react'
import classNames from 'classnames'
import styles from './index.module.css'

export const WithHoverVariants = {
  LEFT: 'left',
  RIGHT: 'right',
  MIDDLE_LEFT: 'middle-left'
}

export type WithHoverProps = {
  ComponentToHover?: any
  ComponentToShow?: any
  variant?: string
  isShowHoverComponent?: boolean
  gap?: number | string
  contentRef?: any
  style?: CSSProperties
}

const withHover = ({
  ComponentToHover,
  ComponentToShow,
  variant,
  isShowHoverComponent,
  gap,
  contentRef,
  style
}: WithHoverProps) => {
  return () => {
    const RenderComponentToHover = typeof ComponentToHover === 'object' ? ComponentToHover : <ComponentToHover />
    const RenderComponentToShow =
      ComponentToShow && (typeof ComponentToShow === 'object' ? ComponentToShow : <ComponentToShow />)

    if (!ComponentToShow) {
      return RenderComponentToHover
    }

    return (
      <div className={styles.withHover} style={style}>
        <div className={styles.wrapper}>{RenderComponentToHover}</div>
        <div
          ref={isShowHoverComponent ? contentRef : undefined}
          className={classNames(styles.content, {
            [styles.right]: variant === WithHoverVariants.RIGHT,
            [styles.middleLeft]: variant === WithHoverVariants.MIDDLE_LEFT,
            [styles.showComponent]: isShowHoverComponent
          })}
          style={{ [variant === WithHoverVariants.MIDDLE_LEFT ? 'marginRight' : 'marginTop']: gap }}
        >
          {RenderComponentToShow}
        </div>
      </div>
    )
  }
}

export default withHover
