import compact from 'lodash/compact'
import uniq from 'lodash/uniq'
import { FlowControlType } from 'src/common/constants/common.constants'
import { IntegrationInstance } from 'src/modules/integrations/types'
import { hasDynamicOutput } from '../../wfe.convertorsDynamicOutput'
import { DisplayTypes } from '../ReactJsonWiv'
import { Parameter } from '../../wfe.types'
import { getStepSuggestions } from './outputTreeSuggestions'

export const PLEASE_RUN_STEP = 'First run the original step test to generate the mock data'

export const HAS_OUTPUT = /{{(.*?)}}/g

export const OUTPUT_TAB = 'output-tab'

export const parseOutputValue = (value: string) => ((value || '').match(HAS_OUTPUT) ? '' : value)

export enum TreePasteTypes {
  Value = 'value',
  Key = 'key',
  Hidden = 'hidden'
}

export type LoopRef = { name: string; path: string }

export const getAllLoops = (nodes: Array<any>) => {
  return nodes.reduce((acc, node) => {
    if (node.data.subtype === FlowControlType.Loop) {
      return [...acc, node.data?.step?.name]
    }

    return acc
  }, [])
}

export const getOutputPath = (path: string, type: TreePasteTypes, nodes?: Array<any>) => {
  if (!path) {
    return ''
  }
  if (type !== TreePasteTypes.Key) {
    return path
  }
  const outputPath = path.replaceAll('steps.', '').replaceAll('steps', '')

  if (!nodes) {
    return outputPath
  }

  //remove the 1st array index, example:
  //LOOP 123.output[0].costs[3] => LOOP 123.output.costs[3]
  let newPath = outputPath

  if (newPath.split('.').length === 1) {
    newPath = `${newPath}.output`
  }
  const loops = getAllLoops(nodes)

  loops.forEach((loop: string) => {
    // remove main index
    const regex = new RegExp(`${loop}\\.output\\[\\d+\\]`, 'g')
    newPath = newPath.replace(regex, `${loop}.output`)

    //change x.0.y to x[0].y
    newPath = newPath.replace(/\.(\d+)\./g, '[$1].')
  })

  return newPath
}

export const getLoopsRefs = ({ node, nodes }: { node: any; nodes: Array<any> }) => {
  const values = compact(
    node?.step.parameters.map((param: Parameter) =>
      typeof param.value === 'object' ? JSON.stringify(param.value) : param.value
    )
  ).join('')

  // get values incased in {{ and }}
  const namesWithoutBrackets = values.match(/\{\{(.*?)\}\}/g)?.map(match => match.slice(2, -2))

  const stepNames: Array<LoopRef> = []

  namesWithoutBrackets?.forEach((value: any) => {
    const isString = typeof value === 'string'
    if (!isString || !value.includes('.output')) {
      return
    }

    stepNames.push({
      name: value.substring(0, value.indexOf('.output')),
      path: value
    })
  })

  return stepNames.filter((step: { name: string; path: string }) => {
    const node = nodes.find((n: any) => n?.data?.step?.name === step.name)

    return node?.data?.subtype === FlowControlType.Loop
  })
}

export const getIterationParameters = ({
  parameters,
  loopsRefs,
  iterations
}: {
  parameters: Array<Parameter>
  loopsRefs: Array<LoopRef>
  iterations: Record<string, number>
}) => {
  return parameters.map(param => {
    const isObject = typeof param.value === 'object'
    const isString = typeof param.value === 'string'
    const value = isObject ? JSON.stringify(param.value) : param.value
    if ((!isString && !isObject) || !value.includes('.output') || !hasDynamicOutput(value)) {
      return param
    }

    let iterationValue = getLoopIterationValue({
      value,
      loopsRefs,
      iterations
    })

    if (isObject) {
      iterationValue = JSON.parse(iterationValue)
    }

    return { ...param, value: iterationValue }
  })
}

export const getLoopIterationValue = ({
  value,
  loopsRefs,
  iterations
}: {
  value: any
  loopsRefs: Array<{ name: string; path: string }>
  iterations: Record<string, number>
}) => {
  let iterationValue = value
  loopsRefs.forEach(loopRef => {
    iterationValue = iterationValue.replaceAll(`${loopRef.name}.output`, `${loopRef.name}.iterationOutput`)
  })

  loopsRefs.forEach(loopRef => {
    iterationValue = iterationValue.replaceAll(
      `${loopRef.name}.iterationOutput`,
      `${loopRef.name}.output[${iterations[loopRef.name]}]`
    )
  })

  return iterationValue
}

export const getInputID = (stepId = '', inputId: string) => `${stepId}-${inputId}`

export const valueHasOutput = (value: any) => typeof value === 'string' && value?.includes('{{')

export const removeOutputBrackets = (value: string) => value.replace('{{', '').replace('}}', '')

export const getStepExecutionsAndIntegrations = ({
  currentStepExecutions,
  integrations,
  stepsBefore
}: {
  currentStepExecutions: any
  integrations?: Array<IntegrationInstance>
  stepsBefore: Array<string>
}) => {
  const integrationTypes = uniq(integrations?.map((integration: IntegrationInstance) => integration.type))

  const integrationsTree = integrationTypes.reduce((acc: Record<string, any>, type: string) => {
    acc[type] = (integrations ?? [])
      .filter((instance: IntegrationInstance) => instance.type === type)
      .map(instance => instance.integrationId + ':' + instance.name)

    return acc
  }, {})

  const suggestions = getStepSuggestions({ currentStepExecutions, stepsBefore })

  return {
    suggestions,
    steps: currentStepExecutions,
    integrations: integrationsTree
  }
}

export const suggestionsToSteps = (text: string) => (text?.replace ? text.replace('suggestions.', 'steps.') : text)

export const executionDisplay = (tree: { workflowId: string; stepExecutionId: string; [key: string]: any }): any => {
  if (!tree) {
    return null
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const { workflowId, stepExecutionId, output, ...rest } = tree

  return {
    output,
    ...rest
  }
}

export const getDefaultDisplayType = (stepTemplateId?: string) => {
  const isAthena = stepTemplateId && stepTemplateId?.includes('AWS-athena')

  return isAthena ? DisplayTypes.Table : DisplayTypes.Object
}
