export const FilterOperators = {
  EQUALS: 'equals'
}

export enum IntegrationType {
  Slack = 'SLACK',
  Teams = 'TEAMS',
  Email = 'EMAIL',
  AWS = 'AWS',
  Jira = 'JIRA',
  AnodotCost = 'ANODOTCOST',
  GitHub = 'GITHUB',
  GCP = 'GCP',
  Azure = 'AZURE'
}

export enum FlowControlType {
  Break = 'BREAK',
  If = 'IF',
  Exit = 'EXIT',
  SubWorkflow = 'SUBWORKFLOW',
  Loop = 'LOOP',
  Collect = 'COLLECT',
  Trigger = 'TRIGGER',
  Wait = 'WAIT',
  Approve = 'APPROVE',
  Comment = 'Comment'
}

export enum UtilityType {
  Workflow = 'WORKFLOW',
  Array = 'ARRAY',
  Api = 'API',
  Format = 'FORMAT',
  Network = 'NETWORK',
  Math = 'MATH',
  Variable = 'VARIABLE',
  Code = 'CODE',
  Datastore = 'DATASTORE',
  Recommendation = 'CASE',
  AnodotCost = 'ANODOTCOST'
}

export enum NodeType {
  Action = 'ACTION',
  FlowControl = 'FLOWCONTROL',
  Placeholder = 'PLACEHOLDER',
  PassPlaceholder = 'PASSPLACEHOLDER',
  Invisible = 'INVISIBLE',
  Invalid = 'INVALID',
  COMMENT = 'COMMENT'
}

export enum NodeSubTypeGeneral {
  BasicAction = 'BASIC_ACTION',
  Invalid = 'INVALID'
}

export const NodeSubType = { ...FlowControlType, ...NodeSubTypeGeneral }

export type NodeSubType = FlowControlType | NodeSubTypeGeneral

export enum NodeScope {
  Default = 'DEFAULT',
  If = 'IF',
  Approve = 'APPROVE',
  Loop = 'Loop'
}

export enum NodeControlType {
  Execute = 'EXECUTE',
  Delete = 'DELETE',
  Copy = 'COPY',
  Cut = 'CUT',
  Comment = 'COMMENT',
  Run = 'RUN'
}

export enum StepType {
  Action = 'ACTION',
  FlowControl = 'FLOWCONTROL'
}

export enum StepGroupType {
  FlowControl = 'FLOWCONTROL',
  Ticketing = 'TICKETING',
  Notifications = 'NOTIFICATIONS',
  Cloud = 'CLOUD',
  Utilities = 'UTILITIES'
}

export const StepSubGroupType = { ...IntegrationType, ...FlowControlType, ...UtilityType }

export type StepSubGroupType = IntegrationType | FlowControlType | UtilityType

export const IFRAME_PERMISSIONS = { referrerpolicy: 'no-referrer', allow: "navigation-override : 'none'" }

export const VERY_LONG_STALE_TIME = 1000 * 60 * 30
export const LONG_STALE_TIME = 1000 * 60 * 5
export const DATE_FORMAT = 'MMM dd h:mm a'
export const DATE_FORMAT_DAY = 'MMM dd'
export const DATE_FORMAT_BE = 'yyyy-MM-dd-HH:mm:ss'
export const DATE_FORMAT_SQL = 'yyyy-MM-dd'
export const DATE_ONLY_FORMAT = 'MMM, dd yyyy'
export const DATE_TIMES_FORMAT = 'MMM, dd yyyy hh:mm'
export const EMPTY_VALUE = '-'

export const DAYS = [
  { label: 'Sunday', value: 'SUN' },
  { label: 'Monday', value: 'MON' },
  { label: 'Tuesday', value: 'TUE' },
  { label: 'Wednesday', value: 'WED' },
  { label: 'Thursday', value: 'THU' },
  { label: 'Friday', value: 'FRI' },
  { label: 'Saturday', value: 'SAT' }
]

export const LOADING_TEXT = 'Loading...'

export const EMPTY_OPTION = { value: '', label: '' }

export const ERROR_TEXT = 'An Error has occurred'

export const OPPORTUNITIES = {
  COST: 'cost_impact',
  QUANTITY: 'quantity'
}

export const INVALID_DATE = 'Invalid Date'

export const TEMP_ID_PREFIX = 'TEMP_ID_PREFIX'

export const ALL = 'all'
