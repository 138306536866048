import Image from 'next/image'

export const defaultTitleStyle = {
  fontWeight: 500,
  fontSize: '16px',
  color: 'var(--darkBlue)'
}

type Props = {
  onClick?: () => void
}

export const ExpandArrow = ({ onClick }: Props) => (
  <Image
    src='/images/icons/svg/expand-arrow.svg'
    alt={'expand-arrow'}
    onClick={onClick}
    width={12}
    height={12}
    style={{ cursor: 'pointer' }}
  />
)
