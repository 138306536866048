import { routes } from 'src/common/constants/routes.constants'
import { strings } from 'src/common/constants/strings'
import { useDashboard } from 'src/modules/dashboards/hooks/useDashboard'
import { BreadcrumbType, Breadcrumbs } from '../Breadcrumbs'

export const DashboardBreadcrumbs = () => {
  const { data } = useDashboard()

  const breadcrumbs: Array<BreadcrumbType> = [{ title: strings.dashboards.title, path: routes.dashboards.path }]

  if (data?.name) {
    breadcrumbs.push({ title: data?.name })
  }

  return <Breadcrumbs breadcrumbs={breadcrumbs} />
}
