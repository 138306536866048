import { request } from './api'

export async function fetchMonitoring(pagination = {}) {
  const url = `monitoring`

  return await request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(pagination)
  })
}

export async function fetchExecutionById(workflowId: string, executionId: string) {
  return await request(`monitoring/workflows/${workflowId}/executions/${executionId}/status`)
}

export async function fetchSingleExecutions(executionIds: string[]) {
  return await request(`monitoring/workflows/executions/batch`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ execution_ids: executionIds })
  })
}

export async function fetchLatestExecutions(workflow_ids: string[]) {
  return await request(`monitoring/workflows/executions/last`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ workflow_ids })
  })
}
