import { useCallback, useMemo, useState } from 'react'
import pick from 'lodash/pick'
import omit from 'lodash/omit'
import { useRecommendationsFilters } from 'src/common/hooks/opportunities/useRecommendationsFilters'
import { Option, Select } from 'src/components/Select'
import { Flex } from 'src/components/styled-components/flex'
import {
  DATE_RANGE,
  dateOptions,
  dateRangeToDropdownValue,
  getDateRange,
  getDateRangeLabel
} from 'src/components/DateRangeFilter/utils'
import { DateRangePickerFilter } from 'src/components/DateRangeFilter'
import { SelectedFilters } from '../../types'

import { DATE_FILTERS, GROUP_BY, LOCAL_FILTERS } from '../../utils'
import { strings } from '../../../../common/constants/strings'
import styles from './styles.module.css'
import { FieldFilters } from './FieldFilters'
import {
  ALL,
  defaultCasesStatusValues,
  FilterKeys,
  getEffortsOptionsWithCount,
  getLabelOptionWithTotalCount,
  getStatusesOptionsWithCount,
  onChangeMultipleFilter,
  organizeStatusesOptions
} from './utils'

export enum GroupByType {
  cases = 'TYPE',
  accounts = 'ACCOUNT'
}

const groupByOptions = [
  // { label: 'None', value: 'none' },
  { label: 'Cases', value: GroupByType.cases },
  { label: 'Accounts', value: GroupByType.accounts }

  // { label: 'Regions', value: 'regions' }
]

const i18n = strings.filters
export const Filters = ({
  selectedFilters,
  recommendations,
  isLoading,
  setSelectedFilters,
  ignoredFilters = []
}: {
  selectedFilters: SelectedFilters
  recommendations: any[]
  isLoading: boolean
  setSelectedFilters: (selectedFilters: SelectedFilters) => void
  ignoredFilters?: string[]
}) => {
  const [isCustomDateRangeOpen, setIsCustomDateRangeOpen] = useState(false)

  const { filters, setSelectedFilterAndUrl } = useRecommendationsFilters({
    setSelectedFilters
  })

  const effortsOptionsWithCount = useMemo(
    () => getEffortsOptionsWithCount({ recommendations, isLoading, ignoredFilters, selectedFilters }),
    [recommendations, isLoading, ignoredFilters, selectedFilters]
  )

  const statusesOptionsWithCount = useMemo(
    () =>
      getStatusesOptionsWithCount({
        recommendations,
        isLoading,
        efforts: selectedFilters.recommendation_effort
      }),
    [recommendations, isLoading, selectedFilters.recommendation_effort]
  )

  const statusValue = selectedFilters.recommendation_status ?? defaultCasesStatusValues
  const updateLocalFilters = useCallback(
    (value: string | number | (string | number)[], field: string, isMultiple?: boolean) => {
      const newFilters = omit(selectedFilters, field) as Record<string, string[]>
      if (isMultiple) {
        newFilters[field] = value as string[]
      } else if (value !== ALL) {
        newFilters[field] = [value as string]
      }
      setSelectedFilterAndUrl(newFilters)
    },
    [setSelectedFilterAndUrl, selectedFilters]
  )

  const updateFilters = useCallback(
    (value: SelectedFilters) => {
      const oldFilters = pick(selectedFilters, [...LOCAL_FILTERS, ...GROUP_BY])
      const newValue = { ...oldFilters, ...value }
      setSelectedFilters(newValue)
      setSelectedFilterAndUrl(newValue)
    },
    [setSelectedFilters, setSelectedFilterAndUrl, selectedFilters]
  )

  const dateRangeLabel = useMemo(
    () => getDateRangeLabel(selectedFilters[FilterKeys.DATE_RANGE]),

    [selectedFilters]
  )

  return (
    <Flex className={styles.container} justifyContent='space-between'>
      <Flex gap={24}>
        <Select
          icon='/images/svg/date.svg'
          minWidth={175}
          optionLabel={dateRangeLabel}
          options={dateOptions}
          value={dateRangeToDropdownValue(selectedFilters[FilterKeys.DATE_RANGE])}
          onClick={value => {
            setIsCustomDateRangeOpen(value === DATE_RANGE.CUSTOM)
            if (value !== DATE_RANGE.CUSTOM) {
              updateFilters({ ...selectedFilters, [FilterKeys.DATE_RANGE]: getDateRange(value as string) })
            }
          }}
        />
        {!ignoredFilters.includes(FilterKeys.EFFORT) && (
          <Select
            minWidth={150}
            options={effortsOptionsWithCount}
            value={selectedFilters.recommendation_effort?.[0] ?? ALL}
            onChange={value => updateLocalFilters(value, FilterKeys.EFFORT)}
          />
        )}
        {!!statusesOptionsWithCount.length && (
          <Select
            isMultiple
            minWidth={170}
            options={organizeStatusesOptions(statusesOptionsWithCount) as Option[]}
            value={statusValue}
            placeholder={i18n.selectStatus}
            optionLabel={
              statusValue.length === 1
                ? statusesOptionsWithCount.find(status => status.value === statusValue[0])?.label || ''
                : getLabelOptionWithTotalCount(statusValue as string[], statusesOptionsWithCount)
            }
            onChange={onChangeMultipleFilter({
              selectedFilters,
              updateLocalFilters,
              options: statusesOptionsWithCount,
              filterKey: FilterKeys.STATUS
            })}
          />
        )}
        {!ignoredFilters.includes(FilterKeys.GROUP_BY) && (
          <Select
            minWidth={150}
            isMultiple={false}
            options={groupByOptions}
            value={
              (Array.isArray(selectedFilters.group_by) ? selectedFilters.group_by[0] : selectedFilters.group_by) ??
              GroupByType.cases
            }
            renderValue={value => (
              <div>
                {i18n.groupBy} {groupByOptions.find(i => i.value === value)?.label || value}
              </div>
            )}
            onChange={value => updateLocalFilters(value, FilterKeys.GROUP_BY)}
          />
        )}
        <FieldFilters
          selectedFilters={omit(selectedFilters, [...LOCAL_FILTERS, ...DATE_FILTERS, ...GROUP_BY, ...ignoredFilters])}
          setSelectedFilters={updateFilters}
          filters={filters}
        />
      </Flex>
      <DateRangePickerFilter
        open={isCustomDateRangeOpen}
        selectedFilters={selectedFilters}
        updateFilters={dateRange => updateFilters({ ...selectedFilters, [FilterKeys.DATE_RANGE]: dateRange })}
        onClose={() => setIsCustomDateRangeOpen(false)}
      />
    </Flex>
  )
}
