import omit from 'lodash/omit'
import { LOCAL_FILTERS, getFilterName } from 'src/modules/opportunities/utils'
import type { Filters, SelectedFilters } from 'src/modules/opportunities/types'
import { parseFilters } from 'src/common/utils/filters'

export const setInitialFilters = ({
  data,
  setFilters,
  setSelectedFilters
}: {
  data: any
  setFilters: (value: Filters) => void
  setSelectedFilters: (filters: SelectedFilters) => void
}) => {
  if (data) {
    const filter = data.data.reduce(
      (acc: any, filterKey: string) => ({
        ...acc,
        [filterKey]: {
          title: getFilterName(filterKey),
          options: []
        }
      }),
      {}
    )
    setFilters(filter)
  }

  setSelectedFilters(parseFilters())
}

export const getApiFilters = (selectedFilters: SelectedFilters) => omit(selectedFilters, [...LOCAL_FILTERS])
