import { CostImpact } from '../boards/types'

export enum Efforts {
  Low = 'low',
  Medium = 'medium',
  High = 'high'
}

export type Effort = keyof typeof Efforts

export enum Statuses {
  Open = 'OPEN',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Excluded = 'EXCLUDED',
  Expired = 'EXPIRED',
  Verified = 'VERIFIED',
  Acknowledged = 'ACKNOWLEDGED'
}

export type Status = keyof typeof Statuses

export type Recommendation = {
  recommendation_type: string
  recommendation_group: string
  recommendation_title: string
  cost_impact: CostImpact
  quantity: number
  effort: Efforts
  recommendation_service_group?: string
  recommendation_service?: string
  recommendation_criteria?: string
  recommendation_last_update?: string
  recommendation_status: Statuses
  recommendation_id?: string
  id?: string
  group_by_label?: string
  group_by_value?: string
  status_data?: Record<string, { savings: CostImpact; quantity: number }>
}

export type Detail = {
  recommendation_id: string
  recommendation_workflow_id: string
  recommendation_lifecycle_state: LifeCycle
  cost_impact: CostImpact
  isNew?: boolean
  recommendation_status: Statuses
}

export type Details = Detail[]

export type SelectedFilters = {
  [key: string]: (string | number)[]
}

export interface FilterOption {
  title: string
  isSelected: boolean
}

export interface Filters {
  [key: string]: {
    options: FilterOption[]
  }
}

export enum LifeCycle {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}
