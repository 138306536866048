import { useRouter } from 'next/router'
import { useQuery } from 'react-query'
import { fetchApproval } from 'src/services/approvals-api'

export const useSingleExecutionApprovals = () => {
  const router = useRouter()
  const workflowId: string = router.query.slug?.[0]?.toString() ?? ''
  const executionId: string = router.query.slug?.[1]?.toString() ?? ''

  return useQuery(['approvals', workflowId, executionId], () => fetchApproval(workflowId, executionId), {
    enabled: !!workflowId && !!executionId
  })
}
