import { Accordion as MuiAccordion, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export type Props = {
  defaultExpanded?: boolean
  children: React.ReactElement
  title: React.ReactElement
  expanded: boolean
  setExpanded: (expanded: boolean) => void
  styles?: any
  className?: string
}

export const Accordion = ({ children, title, setExpanded, expanded = true, styles = {}, className }: Props) => {
  return (
    <MuiAccordion
      className={className}
      sx={{
        border: 'none',
        padding: 0,
        margin: 0,
        boxShadow: 'none',
        '&:before': { display: 'none !important' },
        width: '100%',
        overflowX: 'hidden',
        '& .MuiAccordionSummary-content': {
          margin: '0 !important'
        },
        '& .MuiAccordionSummary-root': {
          height: '54px',
          minHeight: '54px !important'
        },
        ...styles
      }}
      expanded={expanded}
      onChange={(_, isExpanded) => setExpanded(isExpanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          height: '72px',
          paddingLeft: '0',
          borderBottom: expanded ? 'none' : '1px solid var(--neutralBrandBlue5)'
        }}
      >
        {title}
      </AccordionSummary>
      {children}
    </MuiAccordion>
  )
}
