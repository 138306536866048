import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { casesByBoardPath, routes } from 'src/common/constants/routes.constants'
import { getUrlParam } from 'src/common/utils/url.util'
import { RECOMMENDATION_ACCOUNT_ID_KEY, RECOMMENDATION_TYPE_KEY } from 'src/modules/opportunities/utils'
import { strings } from 'src/common/constants/strings'
import { FILTER_KEY } from 'src/modules/opportunities/utils'
import { useGetBoards } from 'src/modules/boards/components/hooks/useGetBoards'
import { useRecommendationsSummary } from 'src/common/hooks/opportunities/useRecommendationsSummary'
import { parseFilters } from 'src/common/utils/filters'
import { BreadcrumbType, Breadcrumbs } from '../Breadcrumbs'

export const CaseManagementBreadcrumbs = () => {
  const { data: boards } = useGetBoards()
  const { query, push } = useRouter()

  const board_id = query.board_id as string
  const { recommendations = [] } = useRecommendationsSummary({
    selectedFilters: parseFilters(),
    board_id
  })

  const boardName = boards?.find(board => board.boardId === board_id)?.name ?? ''
  const recommendationType = getUrlParam(RECOMMENDATION_TYPE_KEY)
  const recommendationAccountId = getUrlParam(RECOMMENDATION_ACCOUNT_ID_KEY)

  const selectedRecommendation = useMemo(
    () => recommendations?.find(recommendation => recommendation.group_by_value === recommendationAccountId),
    [recommendations, recommendationAccountId]
  )

  const breadcrumbs: Array<BreadcrumbType> = [
    {
      title: strings.common.caseManagement,
      path: routes.boards.path
    }
  ]

  if (boardName) {
    breadcrumbs.push({
      title: boardName,
      onClick: () => {
        const path = casesByBoardPath(board_id)
        const filters = getUrlParam(FILTER_KEY)
        push(`${path}${filters ? `?filters=${filters}` : ''}`)
      }
    })
  }

  if (recommendationType) {
    breadcrumbs.push({ title: recommendationType })
  }
  if (recommendationAccountId) {
    const name = selectedRecommendation?.group_by_label
    let title = recommendationAccountId
    if (name && name !== recommendationAccountId) {
      title = `${name} ${recommendationAccountId}`
    }
    breadcrumbs.push({ title })
  }

  return <Breadcrumbs breadcrumbs={breadcrumbs} />
}
