import { useRouter } from 'next/router'
import { routes } from 'src/common/constants/routes.constants'
import { strings } from 'src/common/constants/strings'
import { useWorkflows } from 'src/common/hooks/workflows/useWorkflows'
import { useSingleExecutionApprovals } from 'src/modules/approvals/hooks/useSingleExecutionApprovals'
import { BreadcrumbType, Breadcrumbs } from '../Breadcrumbs'

export const ApprovalBreadcrumbs = () => {
  const { query } = useRouter()
  const workflowId: string = query.slug?.[0]?.toString() ?? ''

  const { workflows } = useWorkflows()
  const { data: approvals } = useSingleExecutionApprovals()

  const workflowName = workflows?.find(workflow => workflow.workflowId === workflowId)?.name ?? ''

  const breadcrumbs: Array<BreadcrumbType> = [{ title: strings.approvalsStatus.title, path: routes.approvals.path }]

  if (workflowName && approvals?.length) {
    breadcrumbs.push({ title: `${workflowName} (${approvals?.length})` })
  }

  return <Breadcrumbs breadcrumbs={breadcrumbs} />
}
