import { strings } from 'src/common/constants/strings'
import { Efforts, SelectedFilters, Statuses } from '../../types'
import { extractIntegerFromString } from '../../../../common/utils/number.util'
import { closedStatuses } from '../../../../components/OpportunityCard/utils'
import { Option } from '../../../../components/Select'

const i18n = strings.recommendations
export const ALL = 'all'

export const defaultCasesStatusValues = [Statuses.Open, Statuses.InProgress, Statuses.Excluded]

export const StatusSections = {
  OPEN: 'OPEN_SECTION',
  CLOSED: 'CLOSED_SECTION'
}
export const AllArray = [ALL]
export const FilterKeys = {
  EFFORT: 'recommendation_effort',
  STATUS: 'recommendation_status',
  DATE_RANGE: 'date_range',
  GROUP_BY: 'group_by'
}

export const effortsOptions = [
  { value: ALL, label: i18n.effortAll },
  { value: Efforts.Low, label: i18n.effortLow },
  { value: Efforts.Medium, label: i18n.effortMedium },
  { value: Efforts.High, label: i18n.effortHigh }
]

export const statusOptions = [
  { value: ALL, label: i18n.statusAll },
  { value: Statuses.Open, label: i18n.OPEN },
  { value: Statuses.InProgress, label: i18n.IN_PROGRESS },
  { value: Statuses.Completed, label: i18n.COMPLETED },
  { value: Statuses.Excluded, label: i18n.EXCLUDED },
  { value: Statuses.Expired, label: i18n.EXPIRED },
  { value: Statuses.Verified, label: i18n.VERIFIED },
  { value: Statuses.Acknowledged, label: i18n.ACKNOWLEDGED }
]

export const getEffortsOptionsWithCount = ({
  recommendations,
  isLoading,
  ignoredFilters,
  selectedFilters
}: {
  selectedFilters: SelectedFilters
  recommendations: any[]
  isLoading: boolean
  ignoredFilters: string[]
}) => {
  if (ignoredFilters.includes(FilterKeys.EFFORT)) {
    return []
  }

  const getItems = () => {
    const statusFilter = selectedFilters.recommendation_status

    if (!statusFilter || statusFilter.includes(ALL)) {
      return recommendations
    }

    return recommendations.filter(item => {
      return statusFilter.some(status => item.status_data?.[status]?.quantity)
    })
  }

  const items = getItems()

  const getLabel = (value: string) => {
    if (isLoading) {
      return ''
    }
    if (value === ALL) {
      return `(${items.length})`
    }

    return `(${items.filter(item => item.effort === value).length})`
  }

  return effortsOptions.map(option => ({
    ...option,
    label: `${option.label} ${getLabel(option.value)}`,
    icon: option.value === ALL ? undefined : `/images/icons/recommendations/${option.value}.svg`
  }))
}

export const getStatusesOptionsWithCount = ({
  recommendations,
  isLoading,
  efforts
}: {
  efforts?: (string | number)[]
  recommendations: any[]
  isLoading: boolean
}) => {
  const effortFilter = efforts?.[0]
  const items = effortFilter ? recommendations.filter(item => item.effort === effortFilter) : recommendations

  const totalItems = items.reduce(
    (counter, item) =>
      counter + Object.values(item.status_data ?? {}).reduce((acc: number, item: any) => acc + item.quantity, 0),
    0
  )

  const getLabel = (value: string) => {
    if (isLoading) {
      return ''
    }
    if (value === ALL) {
      return `(${totalItems})`
    }

    const totalForStatus = items.reduce((counter, item) => counter + (item.status_data?.[value]?.quantity ?? 0), 0)

    return `(${totalForStatus})`
  }

  return statusOptions.map(option => ({
    ...option,
    label: `${option.label} ${getLabel(option.value)}`,
    icon: option.value === ALL ? undefined : `/images/svg/case-${option.value.toLowerCase()}.svg`,
    count: option.value === ALL ? totalItems : items.filter(item => item.status?.[option.value as keyof object]).length
  }))
}

export const getLabelOptionWithTotalCount = (statusValue: string[], statusesOptionsWithCount: any[]) =>
  statusValue[0] === ALL
    ? `${strings.recommendations.statusAll}(${statusesOptionsWithCount[0].count})`
    : statusValue.length === 1
    ? `${+extractIntegerFromString(statusesOptionsWithCount.find(option => option?.value === status)?.label || '')})`
    : `${strings.common.status} (${statusValue.reduce((total, status) => {
        return (
          total +
          +extractIntegerFromString(statusesOptionsWithCount.find(option => option?.value === status)?.label || '')
        )
      }, 0)})`

export const onChangeMultipleFilter =
  ({
    selectedFilters,
    updateLocalFilters,
    options,
    filterKey
  }: {
    selectedFilters: SelectedFilters
    updateLocalFilters: (value: string | number | (string | number)[], field: string, isMultiple?: boolean) => void
    options: any[]
    filterKey: string
  }) =>
  (value: string | number) => {
    const previousSelection = selectedFilters[filterKey] || []
    const isAllSelected = previousSelection.includes(ALL)

    const openOptions = options
      .filter(option => !closedStatuses.includes(option.value) && option.value !== ALL)
      .map(option => option.value)

    const closedOptions = options.filter(option => closedStatuses.includes(option.value)).map(option => option.value)

    if (value === ALL) {
      updateLocalFilters(isAllSelected ? [] : options.map(option => option.value), filterKey, true)

      return
    }

    if (value === StatusSections.OPEN) {
      const isOpenSectionSelected = openOptions.every(option => previousSelection.includes(option))
      const newValues = isOpenSectionSelected
        ? previousSelection.filter(val => val !== ALL && !openOptions.includes(val)) // Deselect all open options
        : [...new Set([...previousSelection, ...openOptions])] // Select all open options
      if (!isOpenSectionSelected && options.length - 1 === newValues.length) {
        newValues.unshift(ALL)
      }
      updateLocalFilters(newValues, filterKey, true)

      return
    }

    if (value === StatusSections.CLOSED) {
      const isClosedSectionSelected = closedOptions.every(option => previousSelection.includes(option))
      const newValues = isClosedSectionSelected
        ? previousSelection.filter(val => val !== ALL && !closedOptions.includes(val)) // Deselect all closed options
        : [...new Set([...previousSelection, ...closedOptions])] // Select all closed options
      if (!isClosedSectionSelected && options.length - 1 === newValues.length) {
        newValues.unshift(ALL)
      }
      updateLocalFilters(newValues, filterKey, true)

      return
    }

    if (isAllSelected) {
      const newValues = options
        .filter(option => option.value !== ALL && option.value !== value)
        .map(option => option.value)
      updateLocalFilters(newValues, filterKey, true)

      return
    }

    if (previousSelection.includes(value as string)) {
      const newValues = previousSelection.filter(status => status !== value)
      updateLocalFilters(newValues, filterKey, true)

      return
    }

    if (previousSelection.length === options.length - 2) {
      updateLocalFilters(
        options.map(option => option.value),
        filterKey,
        true
      )

      return
    }

    const newValues = [...previousSelection, value]
    updateLocalFilters(newValues, filterKey, true)
  }

export const organizeStatusesOptions = (options: Option[]) => {
  const allOption = options.find(option => option.value === 'all')

  const openOptions = options
    .filter(option => !closedStatuses.includes(option.value) && option.value !== 'all')
    .map(option => ({
      ...option,
      section: StatusSections.OPEN,
      count: extractIntegerFromString(option.label)
    }))
  const closedOptions = options
    .filter(option => closedStatuses.includes(option.value))
    .map(option => ({
      ...option,
      section: StatusSections.CLOSED,
      count: extractIntegerFromString(option.label)
    }))

  const openCount = openOptions.reduce((sum, option) => sum + ((option as Option).count || 0), 0)
  const closedCount = closedOptions.reduce((sum, option) => sum + ((option as Option).count || 0), 0)

  return [
    allOption,
    {
      value: StatusSections.OPEN,
      label: `${strings.common.open} (${openCount})`,
      isSectionHeader: true,
      count: openCount
    },
    ...openOptions,
    {
      value: StatusSections.CLOSED,
      label: `${strings.common.closed} (${closedCount})`,
      isSectionHeader: true,
      count: closedCount
    },
    ...closedOptions
  ].filter(Boolean)
}
