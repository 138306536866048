import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import orderBy from 'lodash/orderBy'
import cx from 'classnames'
import { useRouter } from 'next/router'
import { useGetCostPeriod } from 'src/modules/systemSettings/hooks/useGetCostPeriod'
import { getUrlParam } from 'src/common/utils/url.util'
import { RECOMMENDATION_ACCOUNT_ID_KEY } from 'src/modules/opportunities/utils'
import { Recommendation } from '../../../modules/opportunities/types'
import { strings } from '../../../common/constants/strings'
import Card from '../../Card'
import { numberWithCommas } from '../../../common/utils/number.util'
import Icon from '../../Icon'
import TruncateTitle from '../../TruncateTitle'
import { redirectToOpportunityType, redirectToOpportunityAccount } from '../../../common/utils/commonFunctionsUtils'
import { useMouseClickOutside } from '../../../modules/wfe/hooks/useMouseClickOutside'
import styles from './styles.module.css'

interface RecommendationOption extends Recommendation {
  isSelected?: boolean
}

type OpportunitiesNavigationListProps = {
  opportunities?: RecommendationOption[]
  setIsOpenGoto: (isOpenGoto: boolean) => void
  isOpenGoto: boolean
}

const OpportunitiesNavigationList = memo(
  ({ opportunities = [], setIsOpenGoto, isOpenGoto }: OpportunitiesNavigationListProps) => {
    const [isHoveringItem, setIsHoveringItem] = useState('')
    const recommendationAccountId = getUrlParam(RECOMMENDATION_ACCOUNT_ID_KEY)
    const { getCostPeriod, period, costPeriodTitle } = useGetCostPeriod()
    const cardRef = useRef()
    const router = useRouter()
    const board_id = router.query.board_id as string

    const handleKeyDown = useCallback(
      (e: any) => {
        if (e.key === 'Escape') {
          setIsOpenGoto(false)
        }
      },
      [setIsOpenGoto]
    )

    useEffect(() => {
      if (isOpenGoto) {
        document.addEventListener('keydown', handleKeyDown)
      }

      return () => {
        document.removeEventListener('keydown', handleKeyDown)
      }
    }, [handleKeyDown, isOpenGoto])

    useMouseClickOutside(cardRef, () => {
      setIsOpenGoto(false)
    })

    const onRedirect = useCallback(
      (opportunity: RecommendationOption) => {
        if (recommendationAccountId) {
          redirectToOpportunityAccount(opportunity.group_by_value!, board_id)
        } else {
          redirectToOpportunityType(opportunity.recommendation_type, board_id)
        }
      },
      [recommendationAccountId, board_id]
    )

    const getTitle = useCallback(
      (opportunity: RecommendationOption) => {
        let title = ''
        if (recommendationAccountId) {
          title = opportunity.group_by_label || opportunity.group_by_value || ''
        } else {
          title = opportunity.recommendation_title
        }

        return `${title} (${opportunity.quantity})`
      },
      [recommendationAccountId]
    )

    const getKey = useCallback(
      (opportunity: RecommendationOption) => {
        if (recommendationAccountId) {
          return opportunity.group_by_value!
        } else {
          return opportunity.recommendation_type
        }
      },
      [recommendationAccountId]
    )

    return (
      <Card className={styles.opportunitiesNavigationList} width='fit-content' padding='8px 0' cardRef={cardRef}>
        <div className={styles.goto}>{strings.common.goto}</div>
        <div className={styles.list}>
          {orderBy(opportunities, [`cost_impact.${period}`], 'desc').map((opportunity, index) => (
            <div
              className={styles.option}
              key={index}
              onClick={() => onRedirect(opportunity)}
              onMouseEnter={() => setIsHoveringItem(getKey(opportunity))}
              onMouseLeave={() => setIsHoveringItem('')}
            >
              <TruncateTitle
                className={cx(styles.title, {
                  [styles.onHover]: isHoveringItem === getKey(opportunity)
                })}
                title={getTitle(opportunity)}
              />
              <div
                className={cx(styles.costImpact, {
                  [styles.onHover]: isHoveringItem === getKey(opportunity)
                })}
              >
                {'$' + numberWithCommas(getCostPeriod(opportunity.cost_impact)) + costPeriodTitle}
                {opportunity.isSelected && <Icon name='check' height={22} width={22} />}
              </div>
            </div>
          ))}
        </div>
      </Card>
    )
  }
)

export default OpportunitiesNavigationList
