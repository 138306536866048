import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

// ** Next Imports
import Head from 'next/head'

// ** Config Imports
import { Toaster } from 'react-hot-toast'
import themeConfig from 'src/common/configs/themeConfig'

// ** Third Party Import
// ** Component Imports
import ThemeComponent from 'src/common/theme/ThemeComponent'
import WindowWrapper from 'src/components/window-wrapper'

// ** Contexts
import { SettingsConsumer, SettingsProvider } from 'src/common/context/settingsContext'
import ReactHotToast from 'src/common/styles/libs/react-hot-toast'
import 'react-perfect-scrollbar/dist/css/styles.css'
import UserLayout from 'src/components/layouts/UserLayout'
import { NotificationProvider } from '../hooks/useNotify'

const queryClient = new QueryClient()

export const WithTheme = (Component: any, context: any) => {
  const isStorybook = context?.tags?.[0] === 'story'
  const setConfig = Component?.setConfig ?? undefined

  const getLayout = isStorybook
    ? (Component: any) => Component
    : Component?.getLayout ?? ((page: any) => <UserLayout>{page}</UserLayout>)

  return (
    <QueryClientProvider client={queryClient}>
      <Head>
        <title>{`${themeConfig.templateName} - Your Ultimate FinOps Workspace`}</title>
        <meta
          name='description'
          content={`${themeConfig.templateName} – Cut Cloud Costs and Enforce Governance with No-Code, AI Powered Automation FinOps Platform.`}
        />
        <meta name='keywords' content='Wiv, Low-Code FinOps Automation Platform. Powered by AI, FinOps' />
        <meta name='viewport' content='initial-scale=1, width=device-width' />
      </Head>
      <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
        <SettingsConsumer>
          {({ settings }) => {
            return (
              <ThemeComponent settings={settings}>
                <WindowWrapper>
                  <NotificationProvider>{getLayout?.(<Component {...context} />)}</NotificationProvider>
                </WindowWrapper>
                <ReactHotToast>
                  <Toaster position={settings.toastPosition} toastOptions={{ className: 'react-hot-toast' }} />
                </ReactHotToast>
                {!isStorybook && <ReactQueryDevtools initialIsOpen={false} />}
              </ThemeComponent>
            )
          }}
        </SettingsConsumer>
      </SettingsProvider>
    </QueryClientProvider>
  )
}
