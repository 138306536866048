import { ReactElement, useCallback, useMemo, useRef, useState } from 'react'
import { MenuItem, Select as MuiSelect, useTheme } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Image from 'next/image'
import isEmpty from 'lodash/isEmpty'
import cx from 'classnames'
import { Flex } from '../styled-components/flex'
import { ALL } from '../../modules/opportunities/components/Filters/utils'
import { useOnKeyDown } from '../../common/hooks/common'
import { useMouseClickOutside } from '../../modules/wfe/hooks/useMouseClickOutside'
import Checkbox from '../Checkbox'
import styles from './styles.module.css'
import { OptionLabel } from './OptionLabel'

export type Option = {
  value: any
  label: string
  icon?: string
  iconClassName?: string
  isHidden?: boolean
  isSectionHeader?: boolean
  section?: string
  count?: number
}

// Example usage:
export const Select = ({
  options,
  value,
  onChange,
  renderValue,
  onClick,
  label,
  isStaticLabel,
  minWidth = 80,
  icon,
  iconWidth = 16,
  iconHeight = 16,
  badgeCount,
  isMultiple,
  optionLabel,
  placeholder,
  isHoverable,
  isDisabled,
  isWithBorder
}: {
  label?: string
  isStaticLabel?: boolean
  options: Option[]
  value: string | number | (string | number)[]
  renderValue?: (value: any) => ReactElement
  onChange?: (value: string | number) => void
  onClick?: (value: string) => void
  minWidth?: number
  icon?: string
  iconWidth?: number
  iconHeight?: number
  badgeCount?: number
  isMultiple?: boolean
  optionLabel?: string
  placeholder?: string
  isHoverable?: boolean
  isDisabled?: boolean
  isWithBorder?: boolean
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const theme = useTheme()
  const selectRef = useRef(null)
  const isAll = value === ALL || (value as string[]).includes?.(ALL)
  const selectedOption = useMemo(
    () =>
      options.find(
        isMultiple ? option => (value as string[]).includes(option.value) : option => option.value === value
      ),
    [options, value, isMultiple]
  )

  useOnKeyDown({
    Escape: useCallback(() => setIsOpen(false), [])
  })

  useMouseClickOutside(
    selectRef,
    useCallback(() => setIsOpen(false), [])
  )

  const mainLabel = useMemo(() => {
    if (undefined !== optionLabel) {
      return optionLabel
    }

    return isStaticLabel ? label : selectedOption?.label
  }, [optionLabel, isStaticLabel, label, selectedOption?.label])

  return (
    <div
      className={cx({
        [styles.hoverable]: isHoverable,
        [styles.withBorder]: isWithBorder
      })}
      ref={selectRef}
    >
      <Flex gap={24} className={styles.container}>
        {label && !isStaticLabel && <div className={styles.label}>{label}</div>}
        <MuiSelect
          disabled={isDisabled}
          onClick={() => (!isMultiple || !isOpen) && setIsOpen(!isOpen)}
          className={styles.select}
          IconComponent={KeyboardArrowDownIcon}
          renderValue={
            renderValue ||
            (() => (
              <OptionLabel
                isPlaceholder={isEmpty(value)}
                label={mainLabel}
                icon={isMultiple && (value as string[]).length > 1 ? undefined : icon ?? selectedOption?.icon}
                iconWidth={iconWidth}
                iconHeight={iconHeight}
                iconStyle={iconWidth > 20 ? { margin: '-4px' } : {}}
                iconClassName={selectedOption?.iconClassName}
                icons={
                  isMultiple && (value as string[]).length > 1 ? (
                    <Flex>
                      {options
                        .filter(option => (value as string[]).includes(option.value))
                        .map((option, index) =>
                          option.icon ? (
                            <Image
                              className={styles.frontIcon}
                              width={iconWidth}
                              height={iconHeight}
                              src={option.icon || ''}
                              alt={label ?? ''}
                              key={index}
                            />
                          ) : null
                        )}
                    </Flex>
                  ) : undefined
                }
                badgeCount={badgeCount}
              />
            ))
          }
          sx={{
            // this complex rules are needed here, cant be styled simply
            background: '#fff',
            '.MuiSelect-select': {
              padding: '2px 4px'
            },
            '& div[role="combobox"]': { overflow: 'visible' },
            '& .MuiOutlinedInput-notchedOutline': {
              border: '0px !important'
            },
            minWidth
          }}
          MenuProps={{
            open: isOpen,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'right'
            },
            PaperProps: {
              style: {
                margin: '8px 0px',
                borderRadius: '0px',
                maxHeight: '60vh'
              },
              sx: {
                boxShadow: '0px 2px 18px rgba(0,0,0,0.1) !important',
                '.MuiMenuItem-root': {
                  padding: '8px 12px 8px 8px',
                  '&:hover, &.Mui-selected, &.Mui-selected:hover': {
                    backgroundColor: `${theme.palette.customColors.lightBg} !important`
                  }
                }
              }
            }
          }}
          value={isEmpty(value) ? placeholder : value}
          onChange={e => {
            onChange?.(e.target.value as string)
            if (!isMultiple) setIsOpen(false)
          }}
          size='small'
        >
          {options.map(
            option =>
              !option.isHidden && (
                <MenuItem
                  className={cx(styles.option, { [styles.marginTop]: option.isSectionHeader })}
                  key={option.value}
                  value={option.value}
                  onClick={e => {
                    e.stopPropagation()
                    onClick?.(option.value)
                  }}
                >
                  <Flex className={!isMultiple ? styles.spaceBetween : ''}>
                    {isMultiple && (
                      <Checkbox
                        isChecked={
                          option.isSectionHeader
                            ? options
                                .filter(innerOpt => innerOpt.section === option.value)
                                .every(option => (value as string[]).includes(option.value))
                            : isAll || (value as string[]).includes(option.value)
                        }
                      />
                    )}
                    <OptionLabel
                      label={option.label}
                      icon={option.icon}
                      iconWidth={iconWidth}
                      iconHeight={iconHeight}
                      iconClassName={option.iconClassName}
                      labelClassname={isMultiple && option.value === ALL ? styles.withMargin : ''}
                    />
                    {value === option.value && <Image width={11} height={9} src='/images/icons/svg/v.svg' alt='v' />}
                  </Flex>
                </MenuItem>
              )
          )}
        </MuiSelect>
      </Flex>
    </div>
  )
}
