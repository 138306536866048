import { useMemo } from 'react'
import { Tooltip } from '@mui/material'
import { strings } from 'src/common/constants/strings'
import { IGNORED_KEYS } from 'src/modules/datastores/DatastoreActions/utils'
import { Datastore } from 'src/modules/datastores/types'
import stylesCommon from 'src/common/styles/common/common.module.css'
import { Flex } from 'src/components/styled-components/flex'
import styles from './styles.module.css'

const i18n = strings.datastore

type Props = {
  datastore?: Datastore
}

export const DatastoreName = ({ datastore }: Props): any => {
  const schema = datastore?.metadata.schema || []
  const records = datastore?.records || []
  const datastoreName = datastore?.metadata?.name || ''

  const recordFields = useMemo(() => schema.map(({ key, type }) => ({ key, type })), [schema])

  const recordKeys = useMemo(
    () => recordFields.reduce((acc, field) => ({ ...acc, [field.key]: true }), {} as Record<string, boolean>),
    [recordFields]
  )

  const missingKeys = useMemo(() => {
    const keys = records.reduce((acc, record) => {
      const keys = Object.keys(record)
      keys.forEach(key => {
        if (!IGNORED_KEYS.includes(key) && !recordKeys[key]) {
          acc[key] = true
        }
      })

      return acc
    }, {})

    return Object.keys(keys)
  }, [recordKeys, records])

  const tooltipMessage = useMemo(() => {
    const messages = []
    if (missingKeys.length) {
      messages.push(
        <>
          {i18n.messages.invalidRecords}
          <ul>
            {missingKeys.map(key => (
              <li key={key}>{key}</li>
            ))}
          </ul>
        </>
      )
    }
    if (!schema.length) {
      messages.push(i18n.messages.emptySchema)
    }

    if (!messages?.length) return null

    return (
      <ol className={styles.warningList}>
        {messages.map((message, index) => (
          <li key={index}>{message}</li>
        ))}
      </ol>
    )
  }, [schema.length, missingKeys])

  if (!tooltipMessage) {
    return datastoreName
  }

  return (
    <Flex gap={8}>
      {datastoreName}

      <Tooltip title={tooltipMessage}>
        <img
          className={stylesCommon.pointer}
          width='20'
          src='/images/icons/monitoring/warning_triangle.svg'
          alt='warning'
        />
      </Tooltip>
    </Flex>
  )
}
