/* eslint-disable no-unused-vars */
import { Theme } from '@mui/material/styles'
import Button from './button'
import Input from './input'
import DataGrid from './dataGrid'
import Switch from './switch'
import List from './list'
import Toggle from './toggle'
import Calendar from './calendar'
import Chip from './chip'
import Menu from './menu'
import Select from './select'
import Dialog from './dialog'
import Label from './label'
import Divider from './divider'
import Link from './link'
import ToggleButtonGroup from './toggleButtonGroup'
import Tooltip from './tooltip'

const Overrides = (theme: Theme) => {
  return Object.assign(
    Button(theme),
    Input(theme),
    DataGrid(theme),
    Switch(theme),
    List(theme),
    Toggle(theme),
    Calendar(theme),
    Chip(theme),
    Menu(theme),
    Select(theme),
    Dialog(),
    Label(theme),
    Divider(theme),
    Link(),
    ToggleButtonGroup(),
    Tooltip()
  )
}

export default Overrides
