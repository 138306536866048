import Image from 'next/image'
import { Badge } from '@mui/material'
import { Flex } from '../styled-components/flex'
import styles from './styles.module.css'

export const OptionLabel = ({
  label,
  icon,
  iconWidth = 16,
  iconHeight = 16,
  badgeCount,
  icons,
  labelClassname,
  isPlaceholder,
  iconClassName,
  iconStyle
}: {
  label?: string
  icon?: string
  iconWidth?: number
  iconHeight?: number
  badgeCount?: number
  icons?: React.JSX.Element
  labelClassname?: string
  isPlaceholder?: boolean
  iconClassName?: string
  iconStyle?: React.CSSProperties
}) => {
  return (
    <Flex gap={8} className={isPlaceholder ? styles.placeholder : ''}>
      {icons ||
        (icon && (
          <Badge badgeContent={badgeCount} color='primary'>
            <Image
              className={iconClassName ? styles[iconClassName] : ''}
              width={iconWidth}
              height={iconHeight}
              src={icon}
              alt={label ?? ''}
              style={iconStyle ?? {}}
            />
          </Badge>
        ))}
      <div className={labelClassname}>{label}</div>
    </Flex>
  )
}
