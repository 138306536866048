import { useCallback } from 'react'
import { CostImpact } from 'src/modules/boards/types'
import { strings } from 'src/common/constants/strings'
import { CostPeriodTypes } from '../types'
import { useSystemSettings } from './useSystemSettings'

const i18n = strings.common

export const useGetCostPeriod = () => {
  const { data: systemSettings } = useSystemSettings()
  const period = systemSettings?.cost?.period?.toLowerCase()

  const getCostPeriod = useCallback(
    (cost?: CostImpact) => {
      if (typeof cost === 'number') {
        return cost
      }
      const costPeriod = period === CostPeriodTypes.Monthly ? 'month' : 'year'

      return cost?.[costPeriod] || 0
    },
    [period]
  )
  const costPeriodTitle = period === CostPeriodTypes.Monthly ? i18n.m : i18n.yr

  return { getCostPeriod, costPeriodTitle, period: period === CostPeriodTypes.Monthly ? 'month' : 'year' }
}
