import { useRouter } from 'next/router'
import cx from 'classnames'
import { Flex } from 'src/components/styled-components/flex'
import styles from './styles.module.css'

export type BreadcrumbType = {
  path?: string
  title: string | any
  onClick?: () => void
}
type Props = {
  breadcrumbs: BreadcrumbType[]
}
export const Breadcrumbs = ({ breadcrumbs }: Props) => {
  const router = useRouter()

  return (
    <div className={styles.breadcrumbWrapper}>
      {breadcrumbs.map((breadcrumb, index) => (
        <Flex key={index}>
          {index !== 0 && <div className={styles.separator}>/</div>}
          <div
            className={cx(styles.breadcrumbTitle, {
              [styles.clickable]: index !== breadcrumbs.length - 1,
              [styles.activeBreadcrumb]: index === breadcrumbs.length - 1
            })}
            onClick={breadcrumb.path ? () => router.push(breadcrumb.path!) : breadcrumb.onClick}
          >
            {breadcrumb.title}
          </div>
        </Flex>
      ))}
    </div>
  )
}
