import React, { useCallback } from 'react'
import { Box, Radio, FormControlLabel, RadioGroup } from '@mui/material'

import { strings } from 'src/common/constants/strings'
import { useSystemSettings } from 'src/modules/systemSettings/hooks/useSystemSettings'
import Text from 'src/components/Text'
import { useNotify } from 'src/common/hooks/useNotify'
import { SystemSettingPayload } from 'src/services/settings.api'
import { CostAndUsageMetric, CostPeriodTypes, CostSettingTypes, SystemSettingsDomains } from '../../types'
import { costCalculationMethodMapper, costCalculationMethodOptions, costImpactPeriodOptions } from './utils'
import styles from './styles.module.css'

type CostTabProps = {
  updateSystemSettings: (payload: SystemSettingPayload, options: any) => void
}

const i18n = strings.settings

export const CostTab: React.FC<CostTabProps> = ({ updateSystemSettings }) => {
  const notify = useNotify()
  const { data } = useSystemSettings()
  const costPeriod = data?.cost?.period ?? CostPeriodTypes.Yearly
  const calculationMethod = data?.cost?.cost_type ?? CostAndUsageMetric.BLENDED

  const handleCostPeriodChange = useCallback(
    (e: any) => {
      const value = e.target.value
      updateSystemSettings(
        {
          domain: SystemSettingsDomains.Cost,
          type: CostSettingTypes.Period,
          value
        },
        {
          onSuccess: () => {
            notify(i18n.costImpactPeriodSuccess(value), 'success')
          }
        }
      )
    },
    [updateSystemSettings]
  )

  const handleCalculationMethodChange = useCallback(
    (e: any) => {
      const value = e.target.value
      updateSystemSettings(
        {
          domain: SystemSettingsDomains.Cost,
          type: CostSettingTypes.CostType,
          value
        },
        {
          onSuccess: () => {
            notify(
              <div>
                <div style={{ display: 'block' }}>
                  {i18n.costCalculationMethodSuccess(costCalculationMethodMapper[value] ?? value)}
                </div>
                <div style={{ display: 'block' }}>{i18n.changeWillTakeMessage}</div>
              </div>,
              'success'
            )
          }
        }
      )
    },
    [updateSystemSettings]
  )

  return (
    <Box className={styles.tabContent}>
      <Text
        color='var(--primaryBlueBrand)'
        fontSize={'14px'}
        title={i18n.costImpactPeriod}
        style={{ marginBottom: '16px' }}
      />
      <RadioGroup value={costPeriod} onChange={handleCostPeriodChange} className={styles.radioGroup}>
        {costImpactPeriodOptions.map(costImpactPeriod => (
          <FormControlLabel
            key={costImpactPeriod.value}
            value={costImpactPeriod.value}
            control={<Radio size='small' />}
            label={costImpactPeriod.label}
            className={styles.costImpactPeriodOption}
          />
        ))}
      </RadioGroup>

      <Text
        color='var(--primaryBlueBrand)'
        fontSize={'14px'}
        style={{ marginTop: '28px', marginBottom: '22px' }}
        title={i18n.costCalculationMethod}
      />
      <RadioGroup value={calculationMethod} onChange={handleCalculationMethodChange} className={styles.radioGroup}>
        {costCalculationMethodOptions.map(costCalculationMethod => (
          <FormControlLabel
            key={costCalculationMethod.value}
            value={costCalculationMethod.value}
            control={<Radio size='small' />}
            label={
              <div>
                <Text title={costCalculationMethod.title} className={styles.costCalculationMethodTitle} />
                <Text title={costCalculationMethod.description} className={styles.costCalculationMethodDescription} />
              </div>
            }
            className={styles.costCalculationMethodOption}
          />
        ))}
      </RadioGroup>
    </Box>
  )
}
