import { DatastoreMetadata } from 'src/modules/datastores/types'
import { PaginationPayload } from 'src/common/types/pagination'
import { dataToBE, request } from './api'

export async function fetchDatastores() {
  return await request(`datastores`)
}

export async function fetchDatastore(id: string, pagination = {} as PaginationPayload) {
  return await request(`datastores/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(pagination)
  })
}

export async function createDatastore(datastore: Partial<DatastoreMetadata>) {
  return await request(`datastores`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(dataToBE(datastore))
  })
}

export async function createDatastoreRecord(id: string, datastoreRecord: object) {
  return await request(`datastores/${id}/records`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(datastoreRecord)
  })
}

export async function updateDatastore(id: string, datastore: Partial<DatastoreMetadata>) {
  return await request(`datastores/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(dataToBE(datastore))
  })
}

export async function updateRecord(datastoreId: string, recordId: string, recordData: object) {
  return await request(`datastores/${datastoreId}/records/${recordId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(recordData)
  })
}

export async function deleteDatastore(id: string) {
  return await request(`datastores/${id}`, {
    method: 'DELETE'
  })
}

export async function deleteDatastoreRecord(datastoreId: string, recordId: string) {
  return await request(`datastores/${datastoreId}/records/${recordId}`, {
    method: 'DELETE'
  })
}

export async function deleteDatastoreRecords(id: string) {
  return await request(`datastores/${id}/delete_all_records`, {
    method: 'DELETE'
  })
}
