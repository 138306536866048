import { NavLink } from 'src/common/layouts/types'
import { Permission } from 'src/common/auth/constants'
import {
  ApprovalBreadcrumbs,
  CaseManagementBreadcrumbs,
  DashboardBreadcrumbs,
  DatastoreBreadcrumbs,
  IntegrationBreadcrumbs,
  WorkflowBreadcrumbs
} from 'src/components/layouts/components/PageTitle/Breadcrumbs'
import { strings } from './strings'

export const routes = {
  overview: {
    path: '/overview',
    permission: Permission['home:read']
  },
  opportunities: {
    path: '/cases/all',
    firstPagePath: '/cases',
    permission: Permission['recommendations:read']
  },
  boards: {
    path: '/boards',
    permission: Permission['recommendations:read']
  },

  // kebab-case because we rely on it when comparing with url path
  'cases-configuration': {
    path: '/cases-configuration',
    permission: Permission['casesConfiguration:write']
  },
  dashboards: {
    path: '/dashboards',
    permission: Permission['dashboards:read']
  },
  workflows: {
    path: '/workflows',
    permission: Permission['wf:read']
  },
  wfe: {
    path: '/wfe',
    permission: Permission['wf:read']
  },
  templates: {
    path: '/templates',
    permission: Permission['templates:read']
  },
  datastores: {
    path: '/datastores',
    permission: Permission['datastores:read']
  },
  runs: {
    path: '/runs',
    permission: Permission['activityLog:read']
  },
  approvals: {
    path: '/approvals',
    permission: Permission['approvals:read']
  },
  integrations: {
    path: '/integrations',
    permission: Permission['integrations:read']
  },
  forms: {
    path: '/forms'
  },
  settings: {
    path: '/settings',

    // TODO: when notification tab is ready, should be different permission
    permission: Permission['systemSettings:write']
  }
}

export const pageTitles = [
  {
    title: strings.common.overview,
    path: routes.overview.path
  },
  {
    title: strings.common.caseManagement,
    path: routes.boards.path,
    Component: CaseManagementBreadcrumbs
  },
  {
    title: strings.common.caseManagement,
    path: routes.opportunities.firstPagePath,
    Component: CaseManagementBreadcrumbs
  },
  {
    title: strings.casesConfiguration.title,
    path: routes['cases-configuration'].path
  },
  {
    title: strings.common.workflows,
    path: routes.workflows.path,
    Component: WorkflowBreadcrumbs
  },
  {
    title: strings.common.templates,
    path: routes.templates.path
  },
  {
    title: strings.common.runs,
    path: routes.runs.path
  },
  {
    title: strings.approvalsPage.approvalStatus,
    path: routes.approvals.path,
    Component: ApprovalBreadcrumbs
  },
  {
    title: strings.dashboards.title,
    path: routes.dashboards.path,
    Component: DashboardBreadcrumbs
  },
  {
    title: strings.datastores.title,
    path: routes.datastores.path,
    Component: DatastoreBreadcrumbs
  },
  {
    title: strings.common.integrations,
    path: routes.integrations.path,
    Component: IntegrationBreadcrumbs
  }
]

export const navigationItems = [
  {
    icon: '/images/icons/main/overview.svg',
    title: strings.common.overview,
    ...routes.overview
  },
  {
    title: strings.common.automation,
    icon: '/images/icons/main/automation.svg',
    subLinks: [
      {
        title: strings.common.workflows,
        icon: '/images/icons/main/workflows.svg',
        ...routes.workflows
      },
      {
        title: strings.common.templates,
        icon: '/images/icons/main/templates.svg',
        ...routes.templates
      }
    ]
  },
  {
    title: strings.common.monitor,
    icon: '/images/icons/main/monitor.svg',
    subLinks: [
      {
        title: strings.common.runs,
        icon: '/images/icons/main/runs.svg',
        ...routes.runs
      },
      {
        title: strings.common.approvals,
        icon: '/images/icons/main/approvals.svg',
        ...routes.approvals
      }
    ]
  },
  {
    title: strings.common.opportunities,
    icon: '/images/icons/main/savings.svg',
    subLinks: [
      {
        title: strings.common.boards,
        icon: '/images/icons/main/boards.svg',
        ...routes.boards
      },
      {
        title: strings.common.configuration,
        icon: '/images/icons/main/cases-configuration.svg',
        ...routes['cases-configuration']
      }
    ]
  },

  {
    title: strings.common.data,
    icon: '/images/icons/main/data.svg',
    subLinks: [
      {
        title: strings.common.dashboards,
        icon: '/images/icons/main/dashboards.svg',
        ...routes.dashboards
      },
      {
        title: strings.common.datastores,
        icon: '/images/icons/main/datastores.svg',
        ...routes.datastores
      }
    ]
  },
  {
    title: strings.common.integrations,
    icon: '/images/icons/main/settings.svg',
    ...routes.integrations
  }
] as NavLink[]

export const settingsNavItems = [
  {
    title: 'Settings',
    icon: '/images/svg/settings-nav.svg',
    ...routes.settings
  }
] as NavLink[]

export const casesByBoardPath = (boardId: string) => {
  return routes.opportunities.firstPagePath + '/' + boardId
}
