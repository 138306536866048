import { useQuery } from 'react-query'
import { LONG_STALE_TIME } from 'src/common/constants/common.constants'
import { QUERY_KEYS } from 'src/common/constants/query-keys'
import { getFilter } from 'src/services/recommendations.api'

export const useOpportunityFilter = (filterKey: string) => {
  const { data, isLoading } = useQuery<{ data: string[] }>(
    [QUERY_KEYS.recommendationsFilters, filterKey],
    () => getFilter(filterKey),
    {
      enabled: !!filterKey,
      staleTime: LONG_STALE_TIME
    }
  )

  return { options: data?.data || [], isLoading }
}
