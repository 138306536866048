import { useMutation, useQueryClient } from 'react-query'
import { QUERY_KEYS } from 'src/common/constants/query-keys'
import { SystemSettingPayload, updateSystemSettings } from 'src/services/settings.api'
import { strings } from 'src/common/constants/strings'
import { useNotify } from 'src/common/hooks/useNotify'

const i18n = strings.settings

export const useUpdateSystemSettings = () => {
  const notify = useNotify()
  const queryClient = useQueryClient()

  const { isLoading, mutate } = useMutation((payload: SystemSettingPayload) => updateSystemSettings(payload), {
    onMutate: async payload => {
      const key = [QUERY_KEYS.systemSettings]
      await queryClient.cancelQueries(key)

      const previousData = queryClient.getQueryData(key) || ({} as any)
      queryClient.setQueryData(key, () => ({
        ...previousData,
        [payload.domain]: {
          ...previousData[payload.domain],
          [payload.type]: payload.value
        }
      }))

      return { previousData }
    },
    onError: (err, variables, context) => {
      const key = [QUERY_KEYS.systemSettings]
      queryClient.setQueryData(key, context?.previousData)
      notify(i18n.updateSystemSettingsError, 'error')
    },
    onSettled: () => queryClient.invalidateQueries([QUERY_KEYS.systemSettings])
  })

  return { isLoading, updateSystemSettings: mutate }
}
