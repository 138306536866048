import { Button, Dialog, DialogActions, DialogContent, Divider } from '@mui/material'
import { DateRangePicker as MuiDateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { format, parse } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { DATE_FORMAT_SQL, DATE_ONLY_FORMAT } from 'src/common/constants/common.constants'
import { Flex } from 'src/components/styled-components/flex'
import { strings } from 'src/common/constants/strings'
import { DATE_RANGE, DATE_RANGE_DEFAULT, FilterKeys, dateOptions, getDateRange } from './utils'
import { SelectedFilters } from './types'
import styles from './styles.module.css'

export const DateRangePickerFilter = ({
  selectedFilters,
  updateFilters,
  open,
  onClose
}: {
  selectedFilters: SelectedFilters
  updateFilters: (dateRange: any) => void
  open: boolean
  onClose: () => void
}) => {
  const [tempDateRangeFilter, setTempDateRangeFilter] = useState<any>(
    selectedFilters[FilterKeys.DATE_RANGE] ?? getDateRange(DATE_RANGE_DEFAULT)
  )

  useEffect(() => {
    setTempDateRangeFilter(selectedFilters[FilterKeys.DATE_RANGE] ?? getDateRange(DATE_RANGE_DEFAULT))
  }, [selectedFilters])

  const dateRangeToCalendar = useMemo(() => {
    if (!tempDateRangeFilter || !tempDateRangeFilter[0] || !tempDateRangeFilter[1]) {
      return [null, null] as any
    }

    return [
      parse(tempDateRangeFilter[0] as string, DATE_FORMAT_SQL, new Date()),
      parse(tempDateRangeFilter[1] as string, DATE_FORMAT_SQL, new Date())
    ]
  }, [tempDateRangeFilter])

  const suggestionsOptions = useMemo(() => {
    const options = dateOptions.filter(option => option.value !== DATE_RANGE.CUSTOM)

    return options.map(option => (
      <div
        className={styles.suggestionsOption}
        key={option.value}
        onClick={() => {
          updateFilters(getDateRange(option.value as string))
          onClose()
        }}
      >
        {option.label}
      </div>
    ))
  }, [onClose, updateFilters])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog maxWidth={'md'} open={open} onClose={onClose}>
        <DialogContent>
          <Flex className={styles.container} gap={32} alignItems={'flex-start'}>
            <MuiDateRangePicker
              open={true}
              format={DATE_ONLY_FORMAT}
              calendars={1}
              value={dateRangeToCalendar}
              onChange={(date: any) =>
                setTempDateRangeFilter(date.map((d: any) => format(new Date(d), DATE_FORMAT_SQL)))
              }
              slotProps={{
                popper: {
                  sx: {
                    '& *': { boxShadow: 'none !important' },
                    '& .MuiPickersSlideTransition-root': {
                      minHeight: '255px'
                    },

                    zIndex: open ? 'var(--zIndexThirdModalContent)' : 0
                  }
                }
              }}
              sx={{
                '& .MuiMultiInputDateRangeField-separator': { opacity: 0 },
                '& input': {
                  width: '105px',
                  height: '10px',
                  color: 'var(--primaryBlueBrand) !important'
                }
              }}
            />
            <Divider orientation='vertical' flexItem sx={{ borderColor: 'var(--neutralBrandBlue5)' }} />
            <div className={styles.suggestions}>
              <div className={styles.suggestionsTitle}>{strings.common.suggestions}</div>
              {suggestionsOptions}
            </div>
          </Flex>
        </DialogContent>
        <DialogActions sx={{ padding: '16px' }}>
          <Flex gap={16} justifyContent='space-between'>
            <div className={styles.cancel} onClick={onClose}>
              {strings.common.cancel}
            </div>

            <Button
              variant='contained'
              onClick={() => {
                updateFilters(tempDateRangeFilter)
                onClose()
              }}
            >
              {strings.common.apply}
            </Button>
          </Flex>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  )
}
