import React, { CSSProperties, ReactNode } from 'react'
import classNames from 'classnames'
import { Tooltip } from '@mui/material'
import _truncate from 'lodash/truncate'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Icon, { IconProps } from '../Icon'
import { Flex } from '../styled-components/flex'
import styles from './index.module.css'
import { defaultTitleStyle, ExpandArrow } from './utils'

type CardProps = {
  style?: CSSProperties
  contentStyle?: CSSProperties
  children?: ReactNode
  height?: string | number
  minHeight?: string | number
  width?: string | number
  padding?: string | number
  onMouseLeave?: () => void
  className?: string
  isLoading?: boolean
  rightIcon?: any // <Image src='...' height={} width={} alt='' /> or MUI Icon
  title?: string | React.ReactNode
  titleStyle?: CSSProperties
  subtitleStyle?: CSSProperties
  isWithExpandArrow?: boolean
  onClick?: (e?: any) => void
  onRightIconClick?: () => void
  subtitle?: string | React.ReactNode
  titleMaxLength?: number
  isOverflowHidden?: boolean
  isWithBoxShadow?: boolean
  isHorizontal?: boolean
  horizontalLeftIconProps?: IconProps
  onMouseEnter?: () => void
  titleIconProps?: IconProps
  cardRef?: any
  backgroundColor?: string
}

const Card = ({
  className = '',
  style,
  children,
  height = '100%',
  minHeight,
  width = '100%',
  padding = '24px',
  onMouseLeave,
  isLoading,
  rightIcon,
  title,
  titleStyle,
  isWithExpandArrow,
  onClick,
  subtitle,
  titleMaxLength,
  onRightIconClick,
  isOverflowHidden = true,
  isWithBoxShadow = true,
  isHorizontal,
  subtitleStyle,
  horizontalLeftIconProps,
  onMouseEnter,
  contentStyle,
  titleIconProps,
  cardRef,
  backgroundColor = 'white'
}: CardProps) => {
  const paddingTop = isWithExpandArrow ? 24 : undefined

  return (
    <div
      ref={cardRef}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      className={classNames(`${className} ${styles.card}`, {
        [styles.clickable]: !!onClick,
        [styles.overflowHidden]: isOverflowHidden || isLoading,
        [styles.noBoxShadow]: !isWithBoxShadow
      })}
      style={{
        height,
        minHeight: minHeight ?? height,
        width,
        padding,
        backgroundColor,
        ...(paddingTop ? { paddingTop } : {}),
        ...style
      }}
    >
      <div
        className={classNames(styles.contentWrapper, {
          [styles.horizontal]: isHorizontal
        })}
        style={contentStyle}
      >
        {horizontalLeftIconProps && <Icon marginRight={10} {...horizontalLeftIconProps} />}
        {(rightIcon || isWithExpandArrow) && (
          <div
            className={classNames(styles.rightIcon, {
              [styles.expandArrow]: isWithExpandArrow
            })}
          >
            {isWithExpandArrow ? <ExpandArrow onClick={onRightIconClick} /> : rightIcon}
          </div>
        )}
        {(title || titleIconProps) && (
          <span className={styles.title} style={{ ...defaultTitleStyle, ...titleStyle }}>
            <Flex className={styles.tileContainer} gap={4}>
              {titleMaxLength ? (
                <Tooltip title={title}>
                  <span>{_truncate(title as string, { length: titleMaxLength })}</span>
                </Tooltip>
              ) : (
                title
              )}
              {titleIconProps && <Icon {...titleIconProps} />}
            </Flex>

            {subtitle && (
              <span className={styles.subtitle} style={{ ...subtitleStyle }}>
                {subtitle}
              </span>
            )}
          </span>
        )}
        {isLoading ? (
          <Box sx={{ width: '75%', padding: '4px' }}>
            <Skeleton />
            <Skeleton animation='wave' />
            <Skeleton animation={false} />
          </Box>
        ) : (
          children
        )}
      </div>
    </div>
  )
}

export default Card
