import { PaginationPayload } from 'src/common/types/pagination'
import { request } from './api'

export async function fetchApprovals(pagination = {} as PaginationPayload) {
  return await request(`monitoring/approvals_summary`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(pagination)
  })
}

export async function fetchApproval(workflowId: string, executionId: string) {
  return await request(`monitoring/approvals/${workflowId}/${executionId}`)
}
