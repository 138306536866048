import { Flex } from 'src/components/styled-components/flex'
import { getStepIcon } from '../../wfe.helper'
import { TreePasteTypes } from './outputTree.utils'

enum LabelType {
  Step = 'step',
  IntegrationType = 'integrationType',
  IntegrationId = 'integrationId',
  Other = 'other'
}

const getLabelType = ({ path }: { path: string }) => {
  const paths = path.split('.').length

  if (path.startsWith('integrations') && path.endsWith(']')) {
    // integrations.slack[0]
    return LabelType.IntegrationId
  } else if ((path.startsWith('steps.') || path.startsWith('suggestions.')) && paths === 2) {
    // steps.stepName...
    return LabelType.Step
  } else if (path.startsWith('integrations.') && paths === 2) {
    // integrations.slack
    return LabelType.IntegrationType
  }

  return LabelType.Other
}

const isPrimitive = (value: any) => {
  return value !== Object(value)
}

export const containsOnlyPrimitives = (array: any) => {
  if (Array.isArray(array)) {
    return array.every(isPrimitive)
  }
}

export const LoopNodeValueAndLabel = ({ node, path, subtype }: { node: any; path: string; subtype?: any }) => {
  const label = path.split('.').pop() ?? ''

  // do not display subtype field as it used just for identification the loop
  if (path.includes('.subtype') && subtype) {
    return {}
  }

  if (subtype !== 'LOOP') {
    return
  }

  if (path.includes('.output[') && typeof node !== 'object') {
    return {
      value: node,
      label: node,
      treePasteType: TreePasteTypes.Hidden,
      node
    }
  }

  if (path.includes('.output[') && typeof node === 'object') {
    const nestedLabel = Array.isArray(node) ? label : Object.keys(node)[0]

    return {
      value: node[nestedLabel],
      label: nestedLabel,
      node: node[nestedLabel]
    }
  }

  // do not show primitives values for LOOP
  if (containsOnlyPrimitives(node) || !node) {
    return {
      value: path,
      label,
      treePasteType: TreePasteTypes.Hidden,
      node
    }
  }
}

export const NodeValueAndLabel = ({
  node,
  path,
  nodes,
  subtype
}: {
  node: any
  path: string
  nodes: any
  subtype?: any
}) => {
  const labelType = getLabelType({ path })

  const label = path.split('.').pop() ?? ''

  const loopStep = LoopNodeValueAndLabel({ path, node, subtype })
  if (loopStep) {
    return loopStep
  }

  if (labelType === LabelType.IntegrationId) {
    return {
      value: node.split(':')[0] ?? '',
      label: node.split(':')[1] ?? '',
      treePasteType: TreePasteTypes.Hidden,
      node
    }
  }

  if (labelType === LabelType.Step) {
    const stepName = path.split('.')[1]
    const step = nodes.find((node: any) => node.data?.step?.name === stepName)?.data?.step
    if (!step) {
      return {
        value: path,
        label,
        node
      }
    }

    const { iconSrc, iconTitle } = getStepIcon(step.subgroup, step.stepTemplateId)

    if (iconSrc) {
      return {
        value: path,
        node,
        label: (
          <Flex style={{ gap: '8px' }}>
            <img width={16} height={16} src={iconSrc} alt={iconTitle} /> {label}
          </Flex>
        )
      }
    }
  }

  if (labelType === LabelType.IntegrationType) {
    const integrationName = path.split('.')[1]?.toLowerCase()

    const iconSrc = `/images/icons/integrations/${integrationName}.png`

    if (iconSrc) {
      return {
        value: path,
        node,
        label: (
          <Flex style={{ gap: '8px' }}>
            <img width={16} height={16} src={iconSrc} alt={integrationName} /> {label}
          </Flex>
        )
      }
    }
  }

  return {
    value: path,
    node,
    label
  }
}
