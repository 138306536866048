import { useCallback, useEffect, useMemo, useState } from 'react'
import { TreeView } from '@mui/x-tree-view/TreeView'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import isObject from 'lodash/isObject'
import { Box, Tooltip, useTheme } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { DataGridPro } from '@mui/x-data-grid-pro'
import isEmpty from 'lodash/isEmpty'
import { Spinner } from 'src/components/spinner'
import { LOADING_TEXT } from 'src/common/constants/common.constants'
import { SearchField } from 'src/components/SearchField'
import { Flex } from 'src/components/styled-components/flex'
import { TABLE_PROPS } from 'src/common/constants/ui-components.constants'
import { downloadGridFile } from 'src/common/utils/grid.utils'
import { strings } from 'src/common/constants/strings'
import Icon from 'src/components/Icon'
import { useNotify } from 'src/common/hooks/useNotify'
import { suggestionsToSteps, TreePasteTypes } from '../StepBar/outputTree.utils'
import { filterTreeData, transformLoopPath, transformTreeData } from './utils'
import styles from './ReactJsonWiv.module.css'
import { getColumns } from './reactJsonWiv.utils'

const TITLE_HEIGHT = 40

const ignoredKeys = { displayStep: true, parametersStatus: true }

export enum DisplayTypes {
  Object = 'object',
  Json = 'json',
  Table = 'table'
}

const PreviewNode = ({ node, expanded }: { node: any; expanded: boolean }) => {
  const theme = useTheme()
  const getPreview = (node: any) => {
    if (Array.isArray(node)) {
      if (node.length === 0) return `[]`

      if (expanded) {
        return ``
      }

      return `[...]`
    }

    if (typeof node === 'object') {
      const isEmpty = node === null || !Object.keys(node).length

      if (isEmpty) return `{}`

      if (expanded) {
        return ``
      }

      return `{...}`
    }

    return ``
  }

  return <span style={{ color: theme.palette.grey[300] }}>{getPreview(node)}</span>
}

export const ReactJsonWiv = ({
  data: initialData,
  name = 'steps',
  nodeValueAndLabel,
  onSelect,
  onExpand,
  isLoading,
  defaultExpandedNodes,
  dynamicHeight,
  showActions,
  defaultDisplayType = DisplayTypes.Object
}: {
  data: object | null
  name?: string
  nodeValueAndLabel?: (
    node: any,
    path: string,
    subtype?: string
  ) => { value?: string; label?: any; treePasteType?: TreePasteTypes; node?: any }
  defaultExpandedNodes?: Array<string>
  onSelect?: (value: string, type: TreePasteTypes) => void
  onExpand?: (nodeIds: Array<string>) => void
  isLoading?: boolean
  dynamicHeight?: boolean
  showActions?: boolean
  defaultDisplayType?: DisplayTypes
}) => {
  const data = useMemo(() => transformTreeData(initialData), [initialData])
  const notify = useNotify()
  const theme = useTheme()
  const [expanded, setExpanded] = useState<string[]>([])
  const [firstLoad, setFirstLoad] = useState(true)
  const [searchText, setSearchText] = useState('')
  const [filteredData, setFilteredData] = useState(data)
  const [displayType, setDisplayType] = useState(defaultDisplayType)

  const columns = useMemo(() => {
    return getColumns(filteredData)
  }, [filteredData])

  useEffect(() => {
    const filtered = filterTreeData(data, searchText.toLowerCase())
    setFilteredData(filtered)

    if (firstLoad && data) {
      setExpanded(defaultExpandedNodes ?? [name])
      setFirstLoad(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText])

  useEffect(() => {
    onExpand?.(expanded)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded])

  const handleCopy = (rawText: string, type: TreePasteTypes) => {
    const text = suggestionsToSteps(rawText)
    navigator.clipboard.writeText(text).catch(err => console.error('Copy failed:', err))
    onSelect?.(text, type)
    notify(strings.common.copied, 'info')
  }

  const displayPrimitive = (node: any) => {
    if (node === '') {
      return <>""</>
    }

    if (typeof node === 'boolean') {
      return node.toString()
    }

    if (node === LOADING_TEXT) {
      return <span style={{ color: theme.palette.primary.main }}>{node}</span>
    }

    return node
  }

  const renderJsonTree = (treeData: any, path = '', subtype = '') => {
    const paths = path.split('.').length
    const isFirstLevel = paths <= 1
    const defaultLabel = path.split('.').pop()

    const {
      node,
      value,
      label,
      treePasteType = TreePasteTypes.Key
    } = nodeValueAndLabel?.(treeData, path, subtype) ?? {
      value: path,
      label: defaultLabel,
      treePasteType: TreePasteTypes.Key,
      node: treeData
    }

    if (!value && !label && subtype) {
      return null
    }

    return (
      <TreeItem
        key={path}
        nodeId={path}
        sx={{
          '& .MuiTreeItem-iconContainer': {
            display: path === name ? 'none !important' : 'flex !important'
          },
          marginBottom: isFirstLevel ? '8px' : '0px'
        }}
        label={
          <div className={styles.label}>
            <span
              onClick={() => {
                if (isFirstLevel) {
                  return
                }
                let valuePath = value
                if (subtype === 'LOOP') {
                  valuePath = transformLoopPath(path, value, label, data)
                }

                handleCopy(valuePath as string, subtype === 'LOOP' ? TreePasteTypes.Key : treePasteType)
              }}
              className={styles['label-text']}
              style={{ color: theme.palette.grey[500] }}
            >
              {label}
              {treePasteType !== TreePasteTypes.Hidden && path && <span className={styles.dots}>: </span>}
              {<PreviewNode node={node} expanded={expanded.includes(path)} />}
            </span>

            {treePasteType !== TreePasteTypes.Hidden && (
              <span
                onClick={() => handleCopy(typeof node === 'object' ? '' : node, TreePasteTypes.Value)}
                className={styles.node}
                style={
                  typeof node !== 'object'
                    ? {
                        color: theme.palette.customColors.royalBlue,
                        width: '100%',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap'
                      }
                    : {}
                }
              >
                {typeof node === 'object' ? '' : displayPrimitive(node)}
              </span>
            )}
          </div>
        }
      >
        {typeof node === 'object' &&
          Object.entries(node ?? {}).map(([key, value]) => {
            if (ignoredKeys[key as keyof object]) {
              return null
            }
            const newPath = Array.isArray(node) ? `${path}[${key}]` : path ? `${path}.${key}` : key

            return renderJsonTree(value, newPath, node.subtype ?? subtype)
          })}
      </TreeItem>
    )
  }

  const filteredDataTable = useMemo(() => {
    if (Array.isArray(filteredData)) {
      return filteredData.map((row: any, index: number) => ({ id: index, ...row }))
    }

    return []
  }, [filteredData])

  const getActionIcon = useCallback(
    (type: DisplayTypes) => {
      return (
        <Icon
          color={displayType === type ? 'var(--neutralBrandBlue60)' : 'var(--neutralBrandBlue30)'}
          name={type}
          path={`/images/svg/${type}-test-results.svg`}
          hoverColor='var(--neutralBrandBlue60)'
          hoverBackgroundColor='var(--neutralBrandBlue5)'
          backgroundColor={displayType === type ? 'var(--neutralBrandBlue5)' : 'inherit'}
          onClick={() => setDisplayType(type)}
          width={24}
          height={24}
          style={{ borderRadius: '4px', width: '30px', height: '30px', padding: '2px' }}
        />
      )
    },
    [displayType]
  )

  return (
    <div
      className={styles.container}
      style={{
        padding: '8px 0',
        height: dynamicHeight ? `calc(100% - ${TITLE_HEIGHT}px)` : 'unset',
        minHeight: dynamicHeight ? 'unset' : '300px',
        overflow: dynamicHeight ? 'hidden' : 'unset'
      }}
    >
      {isLoading && <Spinner size='small' />}

      {!isLoading && data && !isEmpty(data) && (
        <>
          <Flex style={{ justifyContent: 'space-between', marginBottom: '16px', gap: '15px' }}>
            <SearchField variant={'outlined'} onChange={setSearchText} width='100%' />

            {showActions && (
              <Box
                className={styles.showActions}
                sx={{
                  '& *': {
                    border: '0px !important'
                  }
                }}
              >
                {displayType === DisplayTypes.Table && Array.isArray(filteredData) && (
                  <Tooltip title={strings.common.exportCSV}>
                    <Icon
                      name='/svg/download'
                      width={24}
                      onClick={() =>
                        downloadGridFile({
                          title: name,
                          dataKeys: Object.keys(filteredData[0] ?? {}),
                          data: filteredData
                        })
                      }
                      height={24}
                      style={{ borderRadius: '4px', width: '30px', height: '30px', padding: '2px' }}
                      hoverColor='var(--neutralBrandBlue60)'
                      hoverBackgroundColor='var(--neutralBrandBlue5)'
                    />
                  </Tooltip>
                )}

                {getActionIcon(DisplayTypes.Object)}
                {getActionIcon(DisplayTypes.Json)}

                {Array.isArray(filteredData) && isObject(filteredData[0]) && getActionIcon(DisplayTypes.Table)}
              </Box>
            )}
          </Flex>

          {displayType === DisplayTypes.Json && (
            <pre className={styles.jsonType}>{JSON.stringify(filteredData, null, 2)}</pre>
          )}
          {displayType === DisplayTypes.Object && (
            <div className={styles.treeView}>
              <TreeView
                expanded={expanded}
                onNodeToggle={(e: any, nodes: any) => setExpanded(nodes)}
                className={styles.tree}
                defaultExpandIcon={<ArrowRightIcon />}
                defaultCollapseIcon={<ArrowDropDownIcon />}
                sx={{
                  marginLeft: name === '' ? '-16px' : '0px',
                  '& .MuiTreeItem-content': { padding: 0 },
                  '& .MuiTreeItem-content.Mui-selected, & .MuiTreeItem-content:hover': {
                    backgroundColor: '#f6f5ff !important'
                  },
                  '& .MuiTreeItem-iconContainer': {
                    marginRight: 0,
                    '& svg': { fontSize: '24px !important' }
                  },

                  height: dynamicHeight ? `calc(100% - ${TITLE_HEIGHT * 1.5}px)` : 'unset',
                  maxHeight: dynamicHeight ? 'unset' : '300px',
                  overflow: 'auto'
                }}
              >
                {filteredData === null ? (
                  <span className={styles['no-results']}>No Results</span>
                ) : (
                  renderJsonTree(filteredData, name)
                )}
              </TreeView>
            </div>
          )}

          {displayType === DisplayTypes.Table && (
            <DataGridPro
              columns={columns}
              rows={filteredDataTable}
              {...TABLE_PROPS({ sx: { height: `calc(100vh - 300px)` } })}
            />
          )}
        </>
      )}
    </div>
  )
}
