import isEmpty from 'lodash/isEmpty'

export const FILTER_KEY = 'filters'
export const parseFilters = () => {
  const queryParams = new URLSearchParams(location.search)
  const filtersParam = queryParams.get(FILTER_KEY)
  if (!filtersParam) return {}

  const decodedFilters = decodeURIComponent(filtersParam)
  const filters = JSON.parse(decodedFilters)

  // filters=[${filtersQuery}] should always exist
  return filters[0]
}

export const getFiltersUrl = (selectedFilters: any) => {
  const filtersQuery = encodeURIComponent(JSON.stringify(selectedFilters))

  return `${isEmpty(selectedFilters) ? '' : `filters=[${filtersQuery}]`}`
}
