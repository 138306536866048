import { create } from 'zustand'

export type Store = {
  selectedRows: string[]
  setSelectedRows: (rows: string[]) => void
  clickedRow: string
  setClickedRow: (row: string) => void
  selectedType: string
  setSelectedType: (type: string) => void
  startPageKey: string
  setStartPageKey: (key: string) => void
}

export const useOpportunitiesStore = create<Store>(set => ({
  selectedRows: [],
  setSelectedRows: (rows: string[]) => set({ selectedRows: rows }),
  clickedRow: '',
  setClickedRow: (row: string) => set({ clickedRow: row }),
  selectedType: '',
  setSelectedType: (type: string) => set({ selectedType: type }),
  startPageKey: '',
  setStartPageKey: (key: string) => set({ startPageKey: key })
}))
