import { useState, SyntheticEvent, Fragment, useMemo } from 'react'

import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { useUser, useLogoutFunction } from '@propelauth/nextjs/client'

import LogoutIcon from 'public/images/svg/logout.svg'
import ProfileIcon from 'public/images/svg/profile.svg'
import MembersIcon from 'public/images/svg/members.svg'
import CreateOrganizationIcon from 'public/images/svg/createOrganization.svg'
import SettingsIcon from 'public/images/svg/settings.svg'
import { IFRAME_PERMISSIONS } from 'src/common/constants/common.constants'
import { useSettings } from 'src/common/hooks/useSettings'
import { useCanPerformAction } from 'src/common/auth/auth.hooks'
import { Permission } from 'src/common/auth/constants'
import { strings } from 'src/common/constants/strings'
import styles from './styles.module.css'

const USERS_DIALOG_STYLE = {
  width: '60vw',
  height: '60vh',
  overflow: 'hidden'
}

const USERS_IFRAME_STYLE = {
  width: '100%',
  height: '100%',
  border: '0px'
}

const i18n = strings.common

export const UserDropdown = () => {
  const logoutFn = useLogoutFunction()
  const { settings } = useSettings()
  const { user } = useUser()

  const canCreateOrg = useCanPerformAction(Permission['org:create'])
  const canSeeOrgMembers = useCanPerformAction(Permission['org:members'])
  const canSeeOrgSettings = useCanPerformAction(Permission['org:settings'])
  const canPerformOrgAction = canCreateOrg || canSeeOrgMembers || canSeeOrgSettings

  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [dialogOpenURL, setDialogOpenURL] = useState('')

  const getIframeSrc = () => {
    const authUrl = process.env.NEXT_PUBLIC_AUTH_URL ?? ''
    const url = `${authUrl}/${dialogOpenURL}`
    if (user?.activeOrgId && url.includes('org/')) {
      return `${url}/${user?.activeOrgId}`
    }

    return url
  }

  const { direction } = settings

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    window.Intercom('shutdown')
    logoutFn()
    handleDropdownClose()
  }

  const userAvatar = user?.pictureUrl ?? '/images/avatars/1.png'
  const firstLetter = (user?.firstName || user?.lastName || user?.username || user?.email)?.[0]

  // user may not have name
  const username = user?.username || [user?.firstName, user?.lastName].filter(Boolean).join(' ')

  const profileComponent = useMemo(() => {
    if (firstLetter) {
      return (
        <div onClick={handleDropdownOpen} className={styles.profileIcon}>
          {firstLetter?.toUpperCase()}
        </div>
      )
    }

    return (
      <Avatar
        alt={``}
        onClick={handleDropdownOpen}
        sx={{ width: 30, height: 30, cursor: 'pointer' }}
        src={userAvatar}
      />
    )
  }, [firstLetter, userAvatar])

  return (
    <Fragment>
      {profileComponent}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
        className={styles.userMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
      >
        {username && <Box className={styles.username}>{username}</Box>}
        <Box className={styles.userEmail}>{user?.email}</Box>
        <Box className={styles.menuItem} onClick={() => setDialogOpenURL('account')}>
          <ProfileIcon />
          {i18n.account}
        </Box>

        {canPerformOrgAction && <Divider className={styles.divider} sx={{ marginTop: '0 !important' }} />}
        {canPerformOrgAction && (
          <div>
            <div className={styles.organizationTitle}>{i18n.organization}</div>
            {canSeeOrgMembers && (
              <Box className={styles.menuItem} onClick={() => setDialogOpenURL('org/members')}>
                <MembersIcon />
                {i18n.users}
              </Box>
            )}
            {canSeeOrgSettings && (
              <Box className={styles.menuItem} onClick={() => setDialogOpenURL('org/settings')}>
                <SettingsIcon />
                {i18n.settings}
              </Box>
            )}
            {canCreateOrg && (
              <Box className={styles.menuItem} onClick={() => setDialogOpenURL('create_org')}>
                <CreateOrganizationIcon />
                {i18n.createOrganization}
              </Box>
            )}
          </div>
        )}

        <Divider className={styles.divider} />
        <Box
          onClick={handleLogout}
          className={styles.menuItem}
          sx={{ height: '36px !important', marginBottom: '0 !important' }}
        >
          <LogoutIcon />
          {i18n.logout}
        </Box>
      </Menu>

      <Dialog open={!!dialogOpenURL} maxWidth={false} onClose={() => setDialogOpenURL('')}>
        <DialogContent sx={USERS_DIALOG_STYLE}>
          <iframe {...IFRAME_PERMISSIONS} src={getIframeSrc()} style={USERS_IFRAME_STYLE}></iframe>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpenURL('')}>{i18n.ok}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
