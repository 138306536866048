import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  OnConnect,
  OnEdgesChange,
  OnInit,
  OnNodesChange
} from 'reactflow'

import omit from 'lodash/omit'
import { NodeType } from 'src/common/constants/common.constants'
import { fetchExecutionById } from 'src/services/monitoring.api'
import { wivCaptureException } from 'src/common/utils/sentry'
import { isInsideLoop } from 'src/common/utils/reactFlowUtils'
import { EXECUTION_POLLING_RETRIES, STEP_NAME_POSTFIX, WorkflowExecutionStatus } from './wfe.constants'
import {
  Execution,
  HistoryItem,
  InsertedVariableType,
  LastExecution,
  NodeData,
  NodeDataStep,
  StepParametersStatus,
  StepParametersStatusAction
} from './wfe.types'
import { WorkflowModel } from './wfe.model'
import {
  executableSteps,
  formWorkflowExecutedSteps,
  getWorkflowProgressByStepsExecution,
  hasMissingParams
} from './wfe.helper'
import { TriggerInputParameter, TriggerTypes } from './components/StepBar/TriggerType/types'
import { INVALID_NODE, redo, takeSnapshot, undo } from './wfe.store.utils'
import { StepAction } from './wfe.step.action'

export type WFEState = {
  user: any
  setUser: (user: any) => void
  isWFEditorOpen: boolean
  reactFlowInstance: any
  workflow: any
  activeWorkflowVersion: string
  workflowDataForRun: any
  workflowExecution: any
  isWorkflowRunning: boolean
  isWorkflowEditable: boolean
  isWorkflowExecutionOpened: boolean
  nodes: Node[]
  edges: Edge[]
  past: HistoryItem[]
  future: HistoryItem[]
  quickMenuState: any
  stepBarState: any
  focusedNode: any
  stepExecutions: Record<string, Execution>
  openedInput: string
  triggerType: keyof typeof TriggerTypes
  triggerId: string
  showMissingParams: boolean
  stepsLoaded: boolean
  insertedVariable?: { id: string; value: string; type: InsertedVariableType }
  maximizedInputId?: string
  showMissingParamsStep: Record<string, boolean>
  cacheKeys: Record<string, string[]>
  inputParameters: TriggerInputParameter[]
  onInit: OnInit
  setIsWFEditorOpen: (isOpen: boolean) => void
  setActiveWorkflowVersion: (versionId: string) => void
  onNodesChange: OnNodesChange
  onEdgesChange: OnEdgesChange
  onConnect: OnConnect
  handlePasteNewNodeEvent: (newNodeData: NodeDataStep, nodeId: string) => boolean
  addNodes: (nodes: Node[]) => void
  deleteNode: (nodeId: string) => void
  nodeUsedIn: (nodeId: string) => Node[]
  addEdges: (edges: Edge[]) => void
  setQuickMenu: (quickMenuState: any) => void
  setStepBar: (stepBarState: any) => void
  setNode: (newNodeData: NodeData, targetNode: Node) => void
  setWorkflow: (graph: any) => void
  setInputParameters: (inputParameters: any) => void
  clearWorkflow: () => void
  setIsWorkflowRunning: (isRunning: boolean) => void
  setIsWorkflowEditable: (isRunning: boolean) => void
  setIsWorkflowExecutionOpened: (isWorkflowExecutionOpened: boolean) => void
  isStepBarVisible: () => boolean
  setFocusedNode: (newNodeData: any) => void
  getFocusedNode: () => any
  addStepExecution: (stepName: string, execution: Execution) => void
  addWorkflowExecution: (workflow: any, triggerEventId: string) => void
  updateWorkflowExecution: (workFlowExecutionStatus: any) => void
  markWorkflowExecutionAsFailed: () => void
  changeStepName: (stepName: string, newStepName: string) => void
  setLastExecutions: (LastExecutions: Array<LastExecution>) => void
  handleParametersChange: (action: StepParametersStatusAction, step?: string) => void
  setOpenedInput: (inputId: string) => void
  setTriggerTypeAndId: (triggerType: keyof typeof TriggerTypes, triggerId?: string) => void
  setShowMissingParams: (showMissingParams: boolean) => void
  clearStepExecutionDisplay: () => void
  setStepsLoaded: (loaded: boolean) => void
  setInsertedVariable: (variable: any) => void
  setMaximizedInputId: (inputId: string) => void
  setWorkflowName: (name: string) => void
  setShowMissingParamsStep: (step: string, missing: boolean) => void
  undo: () => void
  redo: () => void
  takeSnapshot: () => void
  setCacheKeys: (cacheKeys: Record<string, string[]>) => void
  stoppedExecutions: { [key: string]: boolean }
  initialExecutions: { [key: string]: Execution }
  runningExecutions: { [key: string]: Execution }
  isCommentFocused: boolean
  setIsShowExecutionSummary: (isShowExecutionSummary: boolean) => void
  isShowExecutionSummary: boolean
  shouldHandleClickRun: boolean
  setShouldHandleClickRun: (shouldHandleClickRun: boolean) => void
}

// this is our useStore hook that we can use in our components to get parts of the store and call actions
const useStore = create<WFEState>()(
  devtools((set, get) => ({
    user: null,
    setUser: (user: any) => {
      set({
        user: user
      })
    },
    isWFEditorOpen: false,
    workflow: null,
    activeWorkflowVersion: '',
    workflowExecution: {
      id: null,
      triggerEventId: null,
      progress: 0,
      status: WorkflowExecutionStatus.Ready,
      steps: [],
      executedSteps: []
    },
    isWorkflowRunning: false,
    isWorkflowEditable: false,
    isWorkflowExecutionOpened: false,
    nodes: [],
    edges: [],
    cloudWatchMetrics: [],
    quickMenuState: {
      visible: false,
      posX: 0,
      posY: 0
    },
    stepBarState: {
      visible: false,
      invoker: {}
    },
    focusedNode: INVALID_NODE,
    reactFlowInstance: null,
    stepExecutions: {},
    openedInput: '',
    workflowDataForRun: null,
    triggerType: 'MANUAL',
    triggerId: '',
    showMissingParams: false,
    stepsLoaded: false,
    insertedVariable: undefined,
    maximizedInputId: undefined,
    showMissingParamsStep: {},
    past: [],
    future: [],
    stoppedExecutions: {},
    initialExecutions: {},
    runningExecutions: {},
    cacheKeys: {},
    isCommentFocused: false,
    inputParameters: [],
    isShowExecutionSummary: true,
    shouldHandleClickRun: false,

    onInit: (reactFlowInstance: any) => {
      set({
        reactFlowInstance: reactFlowInstance
      })

      if (reactFlowInstance) {
        const { setNodes, setEdges } = get().reactFlowInstance
        setNodes(get().workflow.graph.nodes)
        setEdges(get().workflow.graph.edges)
        get().setFocusedNode({ type: NodeType.Invalid })
      }
    },
    onNodesChange: (changes: NodeChange[]) => {
      set({
        nodes: applyNodeChanges(changes, get().nodes)
      })
    },
    onEdgesChange: (changes: EdgeChange[]) => {
      set({
        edges: applyEdgeChanges(changes, get().edges)
      })
    },
    onConnect: (connection: Connection) => {
      set({
        edges: addEdge(connection, get().edges)
      })
    },
    nodeUsedIn: (nodeId: string) => {
      const nodeStep = `{{${nodeId}.`
      const nodes = get().nodes

      return nodes.filter(node => {
        if (node.type === NodeType.COMMENT) {
          return
        }
        const parameters = node.data.step.parameters
        const stringifyObject = JSON.stringify(parameters)

        return stringifyObject.includes(nodeStep)
      })
    },
    isInsideLoopStore: (nodeId: string) => {
      const wfModel = new WorkflowModel()
      wfModel.initialize(get().nodes, get().edges, get().takeSnapshot)

      return isInsideLoop((wfModel as any).graph, nodeId).insideLoopScope
    },
    deleteNode: (nodeId: string) => {
      const wfModel = new WorkflowModel()
      wfModel.initialize(get().nodes, get().edges, get().takeSnapshot)
      wfModel.removeNode(nodeId)

      const { setNodes, setEdges, getNodes } = get().reactFlowInstance
      const wfNodes = wfModel.getNodes()
      setNodes(wfNodes)
      const wfEdges = wfModel.getEdges()
      setEdges(wfEdges)

      const stepName = getNodes().find((node: any) => node.id === nodeId)?.data?.step?.name
      if (stepName) {
        set({ stepExecutions: omit(get().stepExecutions, stepName) })
      }
    },
    deleteCommentNode: (nodeId: string) => {
      const { setNodes, getNodes } = get().reactFlowInstance

      setNodes(getNodes().filter((node: any) => node.id !== nodeId))
    },
    addNodes: (nodes: Node[]) => {
      set({
        nodes: get().nodes.concat(nodes)
      })
    },
    addEdges: (edges: Edge[]) => {
      set({ edges: get().edges.concat(edges) })
    },
    setNode: (newNodeData: NodeData, targetNode: Node) => {
      if (!targetNode?.id) {
        wivCaptureException(new Error('targetNode is not defined for newNodeData: ' + JSON.stringify(newNodeData)))

        return
      }

      set({
        nodes: get().nodes.map(node => {
          // here we are changing the type of the clicked node from placeholder to workflow
          if (node.id === targetNode.id) {
            return {
              ...node,
              type: newNodeData.type,
              data: newNodeData.type === NodeType.COMMENT ? (newNodeData.data as any) : newNodeData
            }
          }

          return node
        })
      })
    },
    clearWorkflow: () => {
      set({ workflow: null, focusedNode: { type: NodeType.Invalid }, stepExecutions: {} })
      get().setTriggerTypeAndId('MANUAL', '')

      if (get().reactFlowInstance) {
        const { setNodes, setEdges } = get().reactFlowInstance
        setNodes([])
        setEdges([])
      }
    },

    setWorkflow: (workflow: any) => {
      if (!workflow) {
        return
      }
      workflow.graph.nodes = workflow.graph.nodes.map((obj: any) => {
        if (!('position' in obj)) {
          obj.position = { x: 0, y: 0 }
        }

        return obj
      })
      set({ workflow: workflow })

      if (workflow && get().reactFlowInstance) {
        const { setNodes, setEdges } = get().reactFlowInstance
        setNodes(get().workflow.graph.nodes)
        setEdges(get().workflow.graph.edges)
      }
    },
    setIsWorkflowRunning: (isRunning: boolean) => {
      set({ isWorkflowRunning: isRunning })
    },
    setIsWorkflowEditable: (isEditable: boolean) => {
      set({ isWorkflowEditable: isEditable })
    },
    setIsWorkflowExecutionOpened: (isWorkflowExecutionOpened: boolean) => {
      set({ isWorkflowExecutionOpened: isWorkflowExecutionOpened })
    },
    setWorkflowDataForRun: (workflowDataForRun: any) => {
      set({ workflowDataForRun })
    },
    addWorkflowExecution: (workflow: any, triggerEventId: string) => {
      const {
        workflowExecution: oldWorkflowExecution,
        updateWorkflowExecution,
        markWorkflowExecutionAsFailed,
        setIsWorkflowRunning
      } = get()
      if (oldWorkflowExecution?.interval) {
        clearInterval(oldWorkflowExecution.interval)
      }
      let attemptsCount = 0
      let interval: any = null
      interval = setInterval(async () => {
        try {
          const data = await fetchExecutionById(workflow.workflowId, triggerEventId)
          updateWorkflowExecution(data)
          if (
            [
              WorkflowExecutionStatus.Succeeded,
              WorkflowExecutionStatus.SucceededWithErrors,
              WorkflowExecutionStatus.CompletedWithErrors,
              WorkflowExecutionStatus.Failed
            ].includes(data.status) &&
            interval
          ) {
            setIsWorkflowRunning(false)
            clearInterval(interval)
          }
        } catch (e) {
          console.error(e)
          if (attemptsCount > EXECUTION_POLLING_RETRIES) {
            markWorkflowExecutionAsFailed()
            if (interval) {
              clearInterval(interval)
            }
          }
          attemptsCount++
        }
      }, 3000)

      const workflowExecution = {
        id: workflow.workflowId,
        triggerEventId,
        interval,
        progress: 0,
        status: WorkflowExecutionStatus.Running,
        errorType: null,
        errorMessage: null,
        steps: executableSteps(workflow),
        executedSteps: []
      }
      set({ workflowExecution: { ...workflowExecution } })
    },
    updateWorkflowExecution: (workFlowExecutionStatus: any, executionId?: string) => {
      const workflowExecution = get().workflowExecution
      const nodes = get().nodes
      if (workFlowExecutionStatus?.status === WorkflowExecutionStatus.RunningSteps) {
        set({
          workflowExecution: {
            status: WorkflowExecutionStatus.RunningSteps,
            executedSteps: [],
            steps: [],
            executionId
          }
        })
      } else if (
        workflowExecution.status === WorkflowExecutionStatus.Running ||
        workflowExecution.status === WorkflowExecutionStatus.Waiting
      ) {
        workflowExecution.errorType = workFlowExecutionStatus.lastStep?.errorType
        workflowExecution.errorMessage = workFlowExecutionStatus.lastStep?.errorMessage
        workflowExecution.status = workFlowExecutionStatus.status
        workflowExecution.executedSteps = formWorkflowExecutedSteps(nodes, workflowExecution, workFlowExecutionStatus)
        workflowExecution.progress = getWorkflowProgressByStepsExecution(workflowExecution)
        workflowExecution.loopStatus = workFlowExecutionStatus.lastStep?.loopStatus
        set({ workflowExecution: { ...workflowExecution } })
      } else if (!workFlowExecutionStatus) {
        set({ workflowExecution: { status: WorkflowExecutionStatus.Ready } })
      }
    },
    markWorkflowExecutionAsFailed: () => {
      const workflowExecution = get().workflowExecution
      if (workflowExecution?.interval) {
        clearInterval(workflowExecution.interval)
      }
      workflowExecution.status = WorkflowExecutionStatus.Failed
      workflowExecution.progress = 100
      if (workflowExecution.executedSteps?.length > 0) {
        workflowExecution.executedSteps[workflowExecution.executedSteps.length - 1].status =
          WorkflowExecutionStatus.Failed
      }
      set({ workflowExecution: { ...workflowExecution } })
      get().setIsWorkflowRunning(false)
    },
    clearWorkflowExecution: () => {
      const { workflowExecution: oldWorkflowExecution } = get()
      if (oldWorkflowExecution?.interval) {
        clearInterval(oldWorkflowExecution.interval)
      }
      const workflowExecution = {
        id: null,
        progress: 0,
        status: WorkflowExecutionStatus.Ready,
        errorType: null,
        errorMessage: null,
        steps: [],
        executedSteps: []
      }
      set({ workflowExecution: { ...workflowExecution } })
    },
    stopWorkflowExecution: () => {
      const { workflowExecution } = get()
      if (workflowExecution?.interval) {
        clearInterval(workflowExecution.interval)
      }
      workflowExecution.progress = 100
      workflowExecution.status = WorkflowExecutionStatus.Aborted
      const lastStepIndex = workflowExecution.executedSteps.length - 1
      workflowExecution.executedSteps[lastStepIndex].status = WorkflowExecutionStatus.Aborted
      set({ workflowExecution: { ...workflowExecution } })
    },
    setQuickMenu: (quickMenuState: any): void => {
      set({ quickMenuState: { ...quickMenuState } })
    },
    setStepBar(stepBarState: any) {
      set({ stepBarState: { ...stepBarState } })
    },
    isStepBarVisible: () => {
      return get().focusedNode.type !== NodeType.Invalid
    },
    setIsWFEditorOpen: (isOpen: boolean) => {
      set({ isWFEditorOpen: isOpen })
    },
    setActiveWorkflowVersion: (versionId: string) => {
      set({ activeWorkflowVersion: versionId })
    },

    handleParametersChange: (action: StepParametersStatusAction, step?: string) => {
      const node = step
        ? get().nodes.find((node: any) => node?.data?.step?.name === step)
        : get().focusedNode ?? get().nodes[0]

      const stepName = node?.step?.name ?? node?.data?.step?.name
      if (!stepName) {
        return
      }

      const execution = get().stepExecutions[stepName]
      const prevStatus = execution?.parametersStatus as string
      const missingParams = hasMissingParams(node)
      const hasRun = execution?.stepExecutionId

      const getStatus = (action: StepParametersStatusAction) => {
        switch (action) {
          case 'loading':
            return 'loading'
          case 'error':
            return 'error'
          case 'timeout':
            return 'timeout'
          case 'changeParams':
            return execution?.stepExecutionId ? 'outdated' : ''
          case 'unFocus':
            if (missingParams) {
              return 'missing'
            }
            if (prevStatus === 'outdated' || prevStatus === 'error' || prevStatus === 'loading') {
              return prevStatus
            }

            return hasRun ? 'fresh' : ''

          default:
            return ''
        }
      }

      const status = getStatus(action)
      const updatedExecution = { ...execution, parametersStatus: status }
      set({ stepExecutions: { ...get().stepExecutions, [stepName]: updatedExecution } })
    },

    setFocusedNode(newNodeData: NodeData) {
      if (newNodeData?.id !== get().focusedNode?.id) {
        get().handleParametersChange('unFocus')
      }
      set({ focusedNode: { ...newNodeData } })
    },
    getFocusedNode() {
      return get().focusedNode
    },
    addStepExecution(stepName: string, execution: Execution, isReplaceExistingExecution?: boolean) {
      const prevExecution = get().stepExecutions[stepName] ?? {}
      set({
        stepExecutions: {
          ...get().stepExecutions,
          [stepName]: isReplaceExistingExecution
            ? execution
            : {
                ...prevExecution,
                ...execution,
                parametersStatus: 'fresh' as StepParametersStatus
              }
        }
      })
    },

    clearStepExecutionDisplay() {
      const prevExecution = get().stepExecutions ?? {}
      set({
        stepExecutions: Object.keys(prevExecution).reduce((acc, stepName) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
          const step = prevExecution[stepName]
          if (!step) {
            return acc
          }

          // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
          const { displayStep, ...rest } = step
          acc[stepName] = rest

          return acc
        }, {} as Record<string, Execution>)
      })
    },

    setLastExecutions(lastExecutions: Array<LastExecution>) {
      set({
        stepExecutions: (lastExecutions ?? []).reduce((acc, execution) => {
          const { stepName, timestamp, stepExecutionId, workflowId, output, status } = execution
          acc[stepName] = {
            output,
            status,
            timestamp,
            stepExecutionId,
            workflowId,
            parametersStatus: 'fresh' as StepParametersStatus
          }

          return acc
        }, {} as Record<string, Execution>)
      })
    },

    changeStepName(oldStepName: string, newStepName: string) {
      const prevExecution = get().stepExecutions[oldStepName]
      const prevExecutions = omit(get().stepExecutions, oldStepName)
      const updatedNodes = get().nodes.map(node => {
        const step = node.data?.step
        if (!step) {
          return node
        }

        const updatedParameters = step.parameters.map((param: any) => {
          if (typeof param?.value === 'string' && param.value === oldStepName) {
            return { ...param, value: param.value.replaceAll(oldStepName, newStepName) }
          } else if (typeof param?.value === 'string' && param.value?.includes(oldStepName)) {
            return {
              ...param,
              value: STEP_NAME_POSTFIX.reduce((newValue, postfix) => {
                return newValue.replaceAll(`{{${oldStepName}.${postfix}`, `{{${newStepName}.${postfix}`)
              }, param.value)
            }
          }

          if (typeof param?.value === 'object' && JSON.stringify(param.value)?.includes(oldStepName)) {
            return {
              ...param,
              value: JSON.parse(JSON.stringify(param.value).replaceAll('{{' + oldStepName, '{{' + newStepName))
            }
          }

          return param
        })

        return { ...node, data: { ...node.data, step: { ...step, parameters: updatedParameters } } }
      })

      set({ nodes: updatedNodes, stepExecutions: { ...prevExecutions, [newStepName]: prevExecution } })
    },
    handlePlaceholderNodeNewNodeEvent: (action: StepAction, nodeId: string): void => {
      const newNodeData = action.createStepNode()
      get().handlePasteNewNodeEvent(newNodeData, nodeId)
    },
    handlePasteNewNodeEvent: (newNodeData: NodeDataStep, nodeId: string) => {
      const wfModel = new WorkflowModel()
      wfModel.initialize(get().nodes, get().edges, get().takeSnapshot)
      const addResult = wfModel.addNode(nodeId, newNodeData)

      if (addResult) {
        const { setNodes, setEdges } = get().reactFlowInstance
        const wfNodes = wfModel.getNodes()
        setNodes(wfNodes)
        const wfEdges = wfModel.getEdges()
        setEdges(wfEdges)

        get().setFocusedNode(newNodeData)
      }

      return addResult
    },

    setOpenedInput: (openedInput: string) => {
      set({ openedInput })
    },

    setTriggerTypeAndId: (triggerType: keyof typeof TriggerTypes, triggerId?: string) => {
      set({ triggerType, triggerId })
    },

    setShowMissingParams: (showMissingParams: boolean) => {
      set({ showMissingParams })
    },

    setStepsLoaded: (stepsLoaded: boolean) => {
      set({ stepsLoaded })
    },

    setInsertedVariable: (variable: any) => {
      set({ insertedVariable: variable })
    },

    setMaximizedInputId: (inputId: string) => {
      set({ maximizedInputId: inputId })
    },

    setWorkflowName: (name: string) => {
      const { workflow } = get()
      set({ workflow: { ...workflow, name } })
    },

    setShowMissingParamsStep: (step: string, missing: boolean) => {
      const { showMissingParamsStep } = get()
      if (missing) {
        set({ showMissingParamsStep: { ...showMissingParamsStep, [step]: missing } })
      } else {
        const removed = showMissingParamsStep
        delete removed[step]
        set({ showMissingParamsStep: removed })
      }
    },

    setRunningExecutions: (runningExecutionId: string, execution: Execution) => {
      const runningExecutions = get().runningExecutions || {}
      set({
        runningExecutions: {
          ...runningExecutions,
          [runningExecutionId]: execution
        }
      })
    },

    setStoppedExecutions: (runningExecutionId: string) => {
      set({ stoppedExecutions: { ...get().stoppedExecutions, [runningExecutionId]: true } })
    },

    takeSnapshot: takeSnapshot({ get, set }),

    undo: undo({ get, set }),

    redo: redo({ get, set }),

    setCacheKeys: (cacheKeys: Record<string, string[]>) => {
      set({ cacheKeys: { ...get().cacheKeys, ...cacheKeys } })
    },

    setInputParameters: (inputParameters: TriggerInputParameter[]) => {
      set({ inputParameters })
    },

    setIsCommentFocused: (isCommentFocused: boolean) => {
      set({ isCommentFocused })
    },

    setIsShowExecutionSummary: (isShowExecutionSummary: boolean) => {
      set({ isShowExecutionSummary })
    },
    setShouldHandleClickRun: (shouldHandleClickRun: boolean) => {
      set({ shouldHandleClickRun })
    }
  }))
)

export default useStore
