import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

export const useWarnUnsaved = (unsavedChanges: boolean, callback: (url: string) => void) => {
  const router = useRouter()

  const dialogWasOpened = useRef(false)

  useEffect(() => {
    const routeChangeStart = (url: string) => {
      if (unsavedChanges) {
        dialogWasOpened.current = true
        callback(url)
        router.events.emit('routeChangeError')
        throw 'Abort route change. Please ignore this error.'
      }
    }

    const handleRefresh = (event: any) => {
      if (!unsavedChanges || dialogWasOpened.current === true) {
        return null
      }

      dialogWasOpened.current = true

      //this message wont be shown to the user, but it is required for the browser to show the dialog
      const message = `unsaved changes`
      event.returnValue = message

      return message
    }

    router.events.on('routeChangeStart', routeChangeStart)
    window.addEventListener('beforeunload', handleRefresh)

    return () => {
      router.events.off('routeChangeStart', routeChangeStart)
      window.removeEventListener('beforeunload', handleRefresh)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unsavedChanges])
}
