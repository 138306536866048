import { useState, useEffect, useMemo } from 'react'
import { useUser } from '@propelauth/nextjs/client'
import Menu from '@mui/material/Menu'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import toLower from 'lodash/toLower'
import sortBy from 'lodash/sortBy'
import cx from 'classnames'
import CheckIcon from 'public/images/svg/check.svg'
import { SearchField } from 'src/components/search-field'
import Text from 'src/components/Text'
import { strings } from 'src/common/constants/strings'
import styles from './OrganizationDropdown.module.css'

const lotOfOrganizations = 7
const i18n = strings.common

const OrganizationDropdown = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLDivElement | null>(null)
  const [searchedValue, setSearchedValue] = useState('')
  const { user, setActiveOrg } = useUser()
  const [org, setOrg] = useState<string | undefined>(undefined)

  const [organizations, setOrganizations] = useState<{ [x: string]: string }>({})
  const sortedOrgs = useMemo(() => {
    const list = Object.keys(organizations).map(id => ({ id, name: organizations[id] }))

    return sortBy(list, obj => toLower(obj.name))
  }, [organizations])

  const orgList = useMemo(() => {
    const searched = searchedValue?.toLowerCase()
    if (searched) {
      return sortedOrgs.filter(org => org.name.toLowerCase().includes(searched))
    }

    return sortedOrgs
  }, [sortedOrgs, searchedValue])

  useEffect(() => {
    const orgs = user?.orgIdToOrgMemberInfo || {}
    if (!isEmpty(orgs)) {
      const newOrganizations = Object.keys(orgs).reduce((acc, orgId) => ({ ...acc, [orgId]: orgs[orgId].orgName }), {})

      // fix random propelauth refetching organization options
      if (!isEqual(newOrganizations, organizations)) {
        setOrganizations(newOrganizations)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (!org && sortedOrgs.length) {
      const activeOrgId = user?.activeOrgId || sortedOrgs[0]?.id
      setOrg(activeOrgId)
    }
  }, [user, sortedOrgs, org])

  const onSetOrg = (orgId: string) => {
    if (orgId) {
      if (orgId && user?.orgIdToOrgMemberInfo?.[orgId]) {
        setOrg(orgId)
        setActiveOrg(orgId)
        setMenuAnchorEl(null)
      }
    }
  }

  if (!org) {
    return null
  }

  if (sortedOrgs.length === 1) {
    return <div className={styles.singleOrganization}>{sortedOrgs[0].name}</div>
  }

  const orgName = sortedOrgs.find(i => i.id === org)?.name

  return (
    <>
      <div
        className={cx(styles.organization, { [styles.openedDropdown]: !!menuAnchorEl })}
        onClick={event => setMenuAnchorEl(event.currentTarget)}
      >
        <Text title={orgName} maxLength={27} className={styles.organizationTitle} />
        <KeyboardArrowDownIcon fontSize='small' className={cx({ [styles.selectedDropdown]: !!menuAnchorEl })} />
      </div>
      <Menu
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        disableAutoFocusItem
        autoFocus={false}
        slotProps={{
          paper: {
            sx: {
              position: 'absolute',
              left: 'calc(100% - 300px) !important',
              top: '40px !important'
            }
          }
        }}
        onClose={() => setMenuAnchorEl(null)}
        onKeyDown={e => e.stopPropagation()}
        sx={{
          '& .MuiPaper-root': {
            maxHeight: '520px',
            borderRadius: '2px',
            width: '232px'
          }
        }}
      >
        {sortedOrgs.length >= lotOfOrganizations && (
          <SearchField
            onChange={setSearchedValue}
            value={searchedValue}
            className={styles.organizationSearch}
            sx={{
              '& .MuiOutlinedInput-root': {
                height: '32px'
              }
            }}
            placeholder='Search...'
          />
        )}
        {orgList.map(organization => (
          <div key={organization.id} onClick={() => onSetOrg(organization.id)} className={styles.organizationItem}>
            <Text title={organization.name} maxLength={25} style={{ color: 'var(--primaryBlueBrand)' }} />
            {organization.id === org && <CheckIcon />}
          </div>
        ))}
        {!orgList.length && <div className={cx(styles.organizationItem, styles.noItems)}>{i18n.noOrganizations}</div>}
      </Menu>
    </>
  )
}

export default OrganizationDropdown
