import { CostSettingTypes, SystemSettingsDomains } from 'src/modules/systemSettings/types'
import { request } from './api'

export async function fetchSystemSettings(domain?: SystemSettingsDomains, type?: CostSettingTypes) {
  let partialUrl = 'settings'
  if (domain) {
    partialUrl += `?domain=${domain}`
  }
  if (type) {
    partialUrl += `&type=${type}`
  }
  const response = await request(partialUrl)

  return response.data
}

export type SystemSettingPayload = {
  domain: 'cost' | 'notifications'
  type: 'period' | 'cost_type'
  value: 'month' | 'year'
}

export async function updateSystemSettings(payload: SystemSettingPayload) {
  return await request('settings', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
}
