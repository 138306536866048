import { Board } from 'src/modules/boards/types'
import { dataToBE, request } from './api'

export async function getBoards() {
  return await request(`boards`)
}

export async function getBoard(id: string) {
  return await request(`boards/${id}`)
}

export async function addBoard(board: Partial<Board>) {
  return await request(`boards`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(dataToBE(board))
  })
}

export async function updateBoard(board: Partial<Board>) {
  return await request(`boards/${board.boardId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(dataToBE(board))
  })
}

export async function deleteBoard(deletedId: string) {
  return await request(`boards/${deletedId}`, {
    method: 'DELETE'
  })
}
