import { Dashboard } from 'src/modules/dashboards/types'
import { dataToBE, request } from './api'

export async function getSavings() {
  return await request(`dashboard/recommendations-summary`)
}

export async function fetchDashboards() {
  return await request(`dashboards`)
}

export async function fetchDashboard(id: string) {
  return await request(`dashboards/${id}`)
}

export async function createDashboard(dashboard: Partial<Dashboard>) {
  return await request(`dashboards`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(dataToBE(dashboard))
  })
}

export async function updateDashboard(id: string, dashboard: Partial<Dashboard>) {
  return await request(`dashboards/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(dataToBE(dashboard))
  })
}

export async function deleteDashboard(id: string) {
  return await request(`dashboards/${id}`, {
    method: 'DELETE'
  })
}
