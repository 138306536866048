import React, { useState } from 'react'
import cx from 'classnames'
import { Modal, Tabs, Tab, Box, IconButton } from '@mui/material'

import Image from 'next/image'
import { strings } from 'src/common/constants/strings'
import { useUpdateSystemSettings } from 'src/modules/systemSettings/hooks/useUpdateSystemSettings'
import { Spinner } from 'src/components/spinner'
import { useCanPerformAction } from 'src/common/auth/auth.hooks'
import { Permission } from 'src/common/auth/constants'
import { CostTab } from './CostTab'
import styles from './styles.module.css'

type SettingsModalProps = {
  open: boolean
  onClose: () => void
}

const i18n = strings.settings

export const SettingsModal: React.FC<SettingsModalProps> = ({ open, onClose }) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const canSeeCostTab = useCanPerformAction(Permission['systemSettings:write'])
  const { updateSystemSettings, isLoading } = useUpdateSystemSettings()

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => setSelectedTab(newValue)

  return (
    <Modal open={open} onClose={onClose} className={styles.modal}>
      <Box className={cx(styles.modalContent, { [styles.loadingContent]: isLoading })}>
        {isLoading && <Spinner />}
        {!isLoading && (
          <>
            <IconButton onClick={onClose} className={styles.closeButton}>
              <Image src='/images/svg/close.svg' alt='close' width={24} height={24} />
            </IconButton>
            <Tabs value={selectedTab} onChange={handleTabChange} className={styles.tabs}>
              {canSeeCostTab && <Tab label={i18n.costPreferences} className={styles.tab} />}
            </Tabs>

            {selectedTab === 0 && canSeeCostTab && <CostTab updateSystemSettings={updateSystemSettings} />}
            {/* We will have notifications tab later */}
          </>
        )}
      </Box>
    </Modal>
  )
}
