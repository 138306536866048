import short from 'short-uuid'
import { format } from 'date-fns'
import { createWorkflowTriggerEvent } from 'src/services/workflows.api'
import { QUERY_KEYS } from 'src/common/constants/query-keys'
import { getApiFilters } from 'src/common/hooks/opportunities/useRecommendationsFilters.utils'
import { parseFilters } from 'src/common/utils/filters'
import { dateRangeToBE } from 'src/components/DateRangeFilter/utils'
import { getUrlParam } from 'src/common/utils/url.util'
import { DATE_FORMAT_BE, FilterOperators } from '../../common/constants/common.constants'
import { Filters, SelectedFilters, Statuses } from './types'
import { ALL } from './components/Filters/utils'
import { GroupByType } from './components/Filters'

const PREFIX_FILTER_KEY = 'recommendation_data.'
export const FILTER_KEY = 'filters'
export const RECOMMENDATION_TYPE_KEY = 'recommendation_type'
export const RECOMMENDATION_ACCOUNT_ID_KEY = 'recommendation_account_id'
export const RECOMMENDATION_RESOURCE_ID_KEY = 'recommendation_resource_id'
export const MAX_PAGE_RECOMMENDATIONS = 1000
export const LOCAL_FILTERS = ['recommendation_effort', 'recommendation_status']
export const DATE_FILTERS = ['date_range']
export const GROUP_BY = ['group_by']

export const baseFieldList = ['recommendation_status', RECOMMENDATION_TYPE_KEY, 'Account Id', 'Account Name', 'Region']
export const existingResourceIds = [
  'resource_id',
  'recommendation_resource_id',
  'Snapshot Id',
  'Table',
  'Volume Id',
  'Volume Name',
  'Instance Id',
  'Resource Id',
  'RDS Identifier',
  'Bucket Name',
  'DB Id',
  'DB',
  'Attached Resource ID',
  'split_line_item_resource_id',
  'Cluster Id',
  'Instance Type',
  'Cluster',
  'EIP Address',
  'Cluster Name',
  'registryId',
  'Hourly Commitment',
  'Instance Class'
]
export const priorityFieldOrder = [...baseFieldList, ...existingResourceIds]

export const runWorkflow = async (workflowId: string, automateRows: any[]) => {
  return await createWorkflowTriggerEvent(workflowId, '1', automateRows[0])
}

export const priceColumns = {
  savings: true,
  price: true,
  waste: true,
  cost: true
}

export const getFilterName = (filterKey = '') =>
  filterKey.startsWith('recommendation_')
    ? (filterKey.charAt(0).toUpperCase() + filterKey.slice(1)).replaceAll('_', ' ')
    : filterKey

const buildFilter = (key: string, value: string) => ({
  key: noNeedPrefixFilters.includes(key) ? key : PREFIX_FILTER_KEY + key,
  operator: FilterOperators.EQUALS,
  value
})

export const getFilterBody = (selectedFilters: any = {}) => {
  let filterBody = {}
  const recommendationAccountId = getUrlParam(RECOMMENDATION_ACCOUNT_ID_KEY)
  const selectedFilterKeys = Object.keys(selectedFilters).filter(
    key => ![...LOCAL_FILTERS, ...DATE_FILTERS, ...GROUP_BY].includes(key)
  )

  if (selectedFilterKeys.length) {
    if (selectedFilterKeys.length === 1) {
      if (selectedFilters[selectedFilterKeys[0]]?.length === 1) {
        filterBody = buildFilter(selectedFilterKeys[0], selectedFilters[selectedFilterKeys[0]][0])
      } else {
        filterBody = {
          or: selectedFilters[selectedFilterKeys[0]].map((selectedFilterKey: string) =>
            buildFilter(selectedFilterKeys[0], selectedFilterKey)
          )
        }
      }
    } else {
      filterBody = {
        and: selectedFilterKeys.map((key: string) => ({
          or: selectedFilters[key].map((value: string) => buildFilter(key, value))
        }))
      }
    }
  }

  const date_range = dateRangeToBE(selectedFilters.date_range)

  const filters = { filter: filterBody, date_range } as any
  const group_by = selectedFilters.group_by?.[0] ?? GroupByType.cases
  if (group_by !== GroupByType.cases || recommendationAccountId) {
    filters.group_by = GroupByType.accounts
  }

  return filters
}

export const noNeedPrefixFilters = [
  'recommendation_effort',
  'recommendation_criteria',
  'recommendation_group',
  'recommendation_service',
  'recommendation_service_group',
  'recommendation_title',
  RECOMMENDATION_TYPE_KEY,
  'recommendation_status'
]

export const getColumnTypes = (types: Record<string, any>) => {
  // check if there are any non-string types, caused by a temp state

  return Object.keys(types ?? {}).some(key => typeof types?.[key] !== 'string') ? {} : types
}

export const newActivityLog = (data: object, user: any) => {
  return {
    activity_id: short.generate(),
    activity_performer: user?.email,
    activity_timestamp: format(new Date(), DATE_FORMAT_BE),
    ...data
  }
}

export const getDetailsKey = ({
  recommendationType,
  recommendationAccountId,
  selectedFilters,
  board_id
}: {
  recommendationType?: string
  recommendationAccountId?: string
  selectedFilters: Filters | SelectedFilters
  board_id?: string
}) => {
  const isGroupingAccount = getUrlParam(RECOMMENDATION_ACCOUNT_ID_KEY)
  if (recommendationAccountId && isGroupingAccount) {
    return [
      QUERY_KEYS.recommendation,
      recommendationAccountId,
      JSON.stringify(getFilterBody(selectedFilters)),
      board_id ?? ALL
    ]
  } else {
    return [
      QUERY_KEYS.recommendation,
      recommendationType || '',
      JSON.stringify(getFilterBody(selectedFilters)),
      board_id ?? ALL
    ]
  }
}

export const getSummaryKey = ({
  selectedFilters,
  board_id
}: {
  selectedFilters: Filters | SelectedFilters
  board_id?: string
}) => {
  return [QUERY_KEYS.recommendations, JSON.stringify(getApiFilters(selectedFilters as any)), board_id ?? ALL]
}

export const getFilteredStatuses = () => {
  const selectedFilters = parseFilters()
  const statuses = selectedFilters?.recommendation_status

  if (!statuses || statuses?.includes(ALL)) {
    return Object.values(Statuses)
  }

  return Object.values(Statuses).filter(status => statuses?.includes(status))
}
