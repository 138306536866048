import { strings } from 'src/common/constants/strings'
import { CostAndUsageMetric, CostPeriodTypes } from '../../types'

const i18n = strings.settings
export const costImpactPeriodOptions = [
  { value: CostPeriodTypes.Monthly, label: i18n.monthly },
  { value: CostPeriodTypes.Yearly, label: i18n.yearly }
]

export const costCalculationMethodOptions = [
  { value: CostAndUsageMetric.UNBLENDED, title: i18n.unblended, description: i18n.unblendedDescription },
  { value: CostAndUsageMetric.AMORTIZED, title: i18n.amortized, description: i18n.amortizedDescription },
  { value: CostAndUsageMetric.NET_UNBLENDED, title: i18n.netUnblended, description: i18n.netUnblendedDescription },
  { value: CostAndUsageMetric.NET_AMORTIZED, title: i18n.netAmortized, description: i18n.netAmortizedDescription }
]
export const costCalculationMethodMapper: Record<string, string> = {
  [CostAndUsageMetric.UNBLENDED]: i18n.unblended,
  [CostAndUsageMetric.AMORTIZED]: i18n.amortized,
  [CostAndUsageMetric.NET_UNBLENDED]: i18n.netUnblended,
  [CostAndUsageMetric.NET_AMORTIZED]: i18n.netAmortized
}
