export enum CostAndUsageMetric {
  BLENDED = 'BlendedCost',
  UNBLENDED = 'UnblendedCost',
  AMORTIZED = 'AmortizedCost',
  NET_AMORTIZED = 'NetAmortizedCost',
  NET_UNBLENDED = 'NetUnblendedCost',
  NET_BLENDED = 'NetBlendedCost'
}

export enum CostPeriodTypes {
  Yearly = 'yearly',
  Monthly = 'monthly'
}

export enum CostSettingTypes {
  CostType = 'cost_type',
  Period = 'period'
}

export enum SystemSettingsDomains {
  Cost = 'cost',
  Notifications = 'notifications'
}
