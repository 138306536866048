import { useQuery, useQueryClient } from 'react-query'
import uniqBy from 'lodash/uniqBy'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import { useCallback, useEffect, useState } from 'react'
import { fetchDatastore } from 'src/services/datastores.api'
import { LONG_STALE_TIME } from 'src/common/constants/common.constants'
import { usePagination } from 'src/common/hooks/usePagination'
import { PaginationResponse } from 'src/common/types/pagination'
import { DATASTORES_QUERY_KEY } from '../constants'
import { Datastore } from '../types'

export type DatastoreResponse = {
  data: Datastore
} & PaginationResponse

export const useDatastore = (id: string) => {
  const pagination = usePagination()
  const { isLoading, data } = useQuery<DatastoreResponse>(
    [DATASTORES_QUERY_KEY, id],
    async () => fetchDatastore(id, pagination),
    {
      staleTime: LONG_STALE_TIME,
      enabled: !!id
    }
  )

  return {
    datastore: data?.data,
    isLoading
  }
}

const DEFAULT_DATASTORE = {
  next_page_key: null,
  data: { metadata: {}, records: [] }
}

export const usePaginatedDatastore = (id: string) => {
  const [page, setPage] = useState(1)
  const queryClient = useQueryClient()
  const [datastore, setDatastore] = useState<DatastoreResponse>(DEFAULT_DATASTORE as unknown as DatastoreResponse)
  const pagination = usePagination(datastore.next_page_key)
  const hasMoreRecords = !!pagination.start_page_key?.record_id || isEmpty(datastore.data.metadata)

  const { isLoading, isError } = useQuery<DatastoreResponse>(
    [DATASTORES_QUERY_KEY, id, page],
    async () => fetchDatastore(id, pagination),
    {
      enabled: !!id && hasMoreRecords,
      onSuccess: response =>
        queryClient.setQueryData([DATASTORES_QUERY_KEY, id], {
          ...response,
          data: {
            ...response.data,
            records: uniqBy([...datastore.data.records, ...response.data.records], 'recordId')
          }
        })
    }
  )

  useEffect(() => {
    const unsubscribe = queryClient.getQueryCache().subscribe(event => {
      if (
        event &&
        isEqual(event?.query.queryKey, [DATASTORES_QUERY_KEY, id]) &&
        (event as any).action?.data &&
        event.type === 'queryUpdated'
      ) {
        setDatastore((event as any).action.data)
      }
    })

    return () => {
      unsubscribe()
    }
  }, [queryClient, id])

  const fetchNextPage = useCallback(() => {
    if (hasMoreRecords && !isLoading) {
      setPage(prev => prev + 1)
    }
  }, [isLoading, hasMoreRecords])

  const invalidate = useCallback(() => {
    queryClient.setQueryData([DATASTORES_QUERY_KEY, id], {
      next_page_key: null,
      data: {
        metadata: datastore.data.metadata,
        records: []
      }
    })
    setPage(prev => prev + 1)
  }, [queryClient, id, datastore.data.metadata])

  return {
    isError,
    datastore: datastore.data,
    hasMoreRecords,
    isLoading,
    fetchNextPage,
    invalidate
  }
}
