import { differenceInDays, differenceInMonths, format, parseISO, startOfDay, subDays, subMonths } from 'date-fns'
import { DATE_FORMAT_SQL } from 'src/common/constants/common.constants'
import { strings } from 'src/common/constants/strings'

export const FilterKeys = {
  DATE_RANGE: 'date_range'
}

export const DATE_RANGE = {
  LAST_24_HOURS: 'last_24_hours',
  LAST_7_DAYS: 'last_7_days',
  LAST_30_DAYS: 'last_30_days',
  LAST_3_MONTHS: 'last_3_months',
  LAST_6_MONTHS: 'last_6_months',
  CUSTOM: 'custom'
}

export const dateOptions = [
  { value: DATE_RANGE.LAST_24_HOURS, label: strings.common.dates.last24hours },
  {
    value: DATE_RANGE.LAST_7_DAYS,
    label: strings.common.dates.last7days
  },
  { value: DATE_RANGE.LAST_30_DAYS, label: strings.common.dates.last30days },
  { value: DATE_RANGE.LAST_3_MONTHS, label: strings.common.dates.last3months },
  { value: DATE_RANGE.LAST_6_MONTHS, label: strings.common.dates.last6months },

  { value: DATE_RANGE.CUSTOM, label: strings.common.dates.custom }
]

export const DATE_RANGE_DEFAULT = DATE_RANGE.LAST_6_MONTHS
export const DATE_RANGE_DEFAULT_SHORT = DATE_RANGE.LAST_7_DAYS

export const getDateRange = (date: string) => {
  const today = new Date()
  const getDaysAgo = () => {
    switch (date) {
      case DATE_RANGE.LAST_24_HOURS:
        return subDays(today, 1)
      case DATE_RANGE.LAST_7_DAYS:
        return subDays(today, 7)
      case DATE_RANGE.LAST_30_DAYS:
        return subDays(today, 30)
      case DATE_RANGE.LAST_3_MONTHS:
        return subMonths(today, 3)
      case DATE_RANGE.LAST_6_MONTHS:
        return subMonths(today, 6)
      default:
        return subMonths(today, 6)
    }
  }

  const daysAgo = getDaysAgo()
  const startDate = format(startOfDay(daysAgo), DATE_FORMAT_SQL)
  const endDate = format(startOfDay(today), DATE_FORMAT_SQL)

  return [startDate, endDate]
}

export const dateRangeToBE = (date?: string[]) => {
  if (!date) {
    const [start_date, end_date] = getDateRange(DATE_RANGE_DEFAULT)

    return {
      start_date,
      end_date
    }
  }

  return {
    start_date: date[0],
    end_date: date[1]
  }
}

export const dateRangeToDropdownValue = (date: any) => {
  if (!date?.[0]) {
    return DATE_RANGE_DEFAULT
  }

  const [start_date, end_date] = date

  const start = parseISO(start_date)
  const end = parseISO(end_date)

  const daysDifference = differenceInDays(end, start)
  const monthsDifference = differenceInMonths(end, start)

  if (daysDifference === 1) return DATE_RANGE.LAST_24_HOURS
  else if (daysDifference === 7) return DATE_RANGE.LAST_7_DAYS
  else if (daysDifference === 30) return DATE_RANGE.LAST_30_DAYS
  else if (monthsDifference === 3) return DATE_RANGE.LAST_3_MONTHS
  else if (monthsDifference === 6) return DATE_RANGE.LAST_6_MONTHS
  else return DATE_RANGE.CUSTOM
}

export const getDateRangeLabel = (dateRange: any) => {
  if (!dateRange || dateRange.length < 2) {
    return undefined
  }

  if (dateRangeToDropdownValue(dateRange) === DATE_RANGE.CUSTOM) {
    return dateRange[0] + ' - ' + dateRange[1]
  }

  return undefined
}
