import { TextField } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import styles from '../SearchField/styles.module.css'
import Icon from '../Icon'

export const SearchField = ({
  value,
  onChange,
  sx = {},
  placeholder = 'Search...',
  focused = false,
  className,
  dataTestId = 'search-field'
}: {
  value?: string
  onChange: (value: string) => void
  sx?: object
  placeholder?: string
  focused?: boolean
  className?: string
  dataTestId?: string
}) => {
  const theme = useTheme()
  const inputRef = useRef<HTMLInputElement>(null)
  const [isFocused, setIsFocused] = useState(false)
  const [innerValue, setInnerValue] = useState('')

  useEffect(() => {
    setInnerValue(value || '')
    if (focused) {
      inputRef?.current?.querySelector('input')?.focus()
    }
  }, [value, focused, inputRef])

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  return (
    <TextField
      onFocus={handleFocus}
      onBlur={handleBlur}
      className={cx(styles.container, className)}
      ref={inputRef}
      size={'small'}
      value={innerValue}
      placeholder={placeholder}
      onChange={e => {
        onChange(e.target.value)
        setInnerValue(e.target.value)
      }}
      InputProps={{
        inputProps: {
          'data-testid': dataTestId
        },
        startAdornment: (
          <Icon
            className={styles.icon}
            name='icons/svg/search'
            height={22}
            width={22}
            color={isFocused ? 'var(--neutralBrandBlue40)' : 'var(--neutralBrandBlue30)'}
          />
        )
      }}
      variant='outlined'
      sx={{
        width: 150,
        input: {
          color: theme.palette.customColors.brandBlue,
          fontSize: '13px !important'
        },

        '& .MuiOutlinedInput-root': {
          paddingLeft: '0px !important',
          '& fieldset': {
            border: 'none !important',
            borderRadius: '0px !important',
            borderBottom: `1px solid ${theme.palette.grey[200]} !important`
          }
        },

        ...sx
      }}
    />
  )
}
