import { useRouter } from 'next/router'
import { useQuery } from 'react-query'
import { LONG_STALE_TIME } from 'src/common/constants/common.constants'
import { fetchDashboard } from 'src/services/dashboard.api'

export const useDashboard = () => {
  const router = useRouter()
  const id: string = router.query.dashboardId?.toString() ?? ''

  return useQuery(['dashboards', id], async () => fetchDashboard(id), {
    staleTime: LONG_STALE_TIME,
    enabled: !!id
  })
}
