import { UseQueryResult, useQuery } from 'react-query'
import { LONG_STALE_TIME } from 'src/common/constants/common.constants'
import { QUERY_KEYS } from 'src/common/constants/query-keys'
import { getBoards } from 'src/services/boards.api'
import { Board } from '../../types'

export const useGetBoards = (options?: any): UseQueryResult<Board[], Error> => {
  return useQuery(QUERY_KEYS.boards, getBoards, {
    staleTime: LONG_STALE_TIME,
    ...options
  })
}
