import { useQuery } from 'react-query'
import { QUERY_KEYS } from 'src/common/constants/query-keys'
import { fetchSystemSettings } from 'src/services/settings.api'
import { LONG_STALE_TIME } from '../../../common/constants/common.constants'
import { CostAndUsageMetric, CostPeriodTypes, CostSettingTypes, SystemSettingsDomains } from '../types'

type SystemSettingsResponse = {
  cost: { cost_type: CostAndUsageMetric; period: CostPeriodTypes }
  notifications: any
}

export const useSystemSettings = (domain?: SystemSettingsDomains, type?: CostSettingTypes) => {
  return useQuery<SystemSettingsResponse>(
    [QUERY_KEYS.systemSettings, domain, type],
    () => fetchSystemSettings(domain, type),
    {
      staleTime: LONG_STALE_TIME
    }
  )
}
