import { useRouter } from 'next/router'
import { routes } from 'src/common/constants/routes.constants'
import { strings } from 'src/common/constants/strings'
import { BreadcrumbType, Breadcrumbs } from '../Breadcrumbs'

const integrationTypeTitleMapper: Record<string, string> = {
  aws: strings.integrations.aws.name,
  azure: strings.integrations.azure.name,
  gcp: strings.integrations.gcp.name,
  github: strings.integrations.github.name,
  jira: strings.integrations.jira.name,
  slack: strings.integrations.slack.name,
  teams: strings.integrations.teams.name
}
const integrationKeys = Object.keys(integrationTypeTitleMapper)
export const IntegrationBreadcrumbs = () => {
  const { query, asPath } = useRouter()

  // "/integrations/aws/Account" -> aws, /integrations/instance/aws -> aws
  const integrationType = asPath.split('/').find(key => integrationKeys.includes(key)) || ''
  const integrationTypeTitle = integrationTypeTitleMapper[integrationType]
  const integrationName: string = query.slug?.[0]?.toString() ?? ''

  const breadcrumbs: Array<BreadcrumbType> = [{ title: strings.common.integrations, path: routes.integrations.path }]

  if (integrationTypeTitle) {
    breadcrumbs.push({
      title: integrationTypeTitle,
      path: integrationType === 'aws' ? `${routes.integrations.path}/aws` : ''
    })
  }

  if (asPath.includes('/instance')) {
    breadcrumbs.push({
      title: strings.common.newIntegration
    })
  }

  if (integrationName) {
    breadcrumbs.push({
      title: integrationName
    })
  }

  return <Breadcrumbs breadcrumbs={breadcrumbs} />
}
