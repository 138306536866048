import { FlowControlType, NodeControlType } from 'src/common/constants/common.constants'
import { strings } from '../../common/constants/strings'

export const REACT_FLOW_KEYS_TO_REMOVE = [
  'position',
  'viewport',
  'draggable',
  'style',
  'positionAbsolute',
  'width',
  'height',
  'selected'
]

export enum WorkflowExecutionStatus {
  Ready = 'READY',
  Running = 'RUNNING',
  Waiting = 'WAITING',
  RunningSteps = 'RUNNING STEPS',
  Succeeded = 'SUCCEEDED',
  SucceededWithErrors = 'SUCCEEDED_WITH_ERRORS',
  CompletedWithErrors = 'COMPLETED_WITH_ERRORS',
  Failed = 'FAILED',
  Aborted = 'ABORTED',
  OK = 'OK',
  Pending = 'PENDING'
}

export enum EdgeType {
  Workflow = 'WORKFLOW',
  IfResult = 'IFRESULT',
  ApproveResult = 'APPROVERESULT',
  Placeholder = 'PLACEHOLDER',
  Loop = 'LOOP'
}

export enum WFEntityType {
  Node = 'NODE',
  Edge = 'EDGE'
}

export enum IfResultType {
  True = 'TRUE',
  False = 'FALSE',
  Close = 'CLOSE'
}

export enum ApproveResultType {
  Approve = 'APPROVE',
  Reject = 'REJECT',
  Close = 'CLOSE'
}

export enum LoopResultType {
  Inside = 'INSIDE',
  Close = 'CLOSE'
}

export const notAllowedComplexStep = [
  FlowControlType.If,
  FlowControlType.Loop,
  FlowControlType.Approve,
  FlowControlType.Trigger,
  FlowControlType.Break,
  FlowControlType.Collect
] as string[]
export const NODE_CONTROLS = [
  {
    id: 0,
    type: NodeControlType.Run,
    name: 'run',
    tooltip: strings.common.runStep,
    canRender: (flowControl?: FlowControlType) => {
      return flowControl !== FlowControlType.Trigger
    }
  },
  {
    id: 1,
    type: NodeControlType.Cut,
    name: 'cut',
    tooltip: strings.common.cut,
    canRender: (flowControl?: FlowControlType) => {
      return !notAllowedComplexStep.includes(flowControl as FlowControlType)
    }
  },
  {
    id: 2,
    type: NodeControlType.Copy,
    name: 'copy',
    tooltip: strings.common.copy,
    canRender: (flowControl?: FlowControlType) => {
      return !notAllowedComplexStep.includes(flowControl as FlowControlType)
    }
  },
  {
    id: 3,
    type: NodeControlType.Delete,
    name: 'delete',
    tooltip: strings.common.delete,
    canRender: (flowControl?: FlowControlType) => {
      return flowControl !== FlowControlType.Trigger
    }
  }
]

export const PYTHON_CODE = 'CODE-001-P1'
export const PYTHON_CODE_BOTO3 = 'CODE-002-P1'
export const PYTHON_CODES = [PYTHON_CODE, PYTHON_CODE_BOTO3]

export const EXECUTION_POLLING_RETRIES = 15

export const TRIGGER_STEP_PARAM_ID = 'TRIGGER-001-P1'
export const SUBWORKFLOW_PARAM_ID = 'SUBWORKFLOW-001-P1'

export const RANGE_MONTH_TO_DATE = 'MTD'
export const RANGE_YEAR_TO_DATE = 'YTD'
export const RANGE_PREVIOUS_MONTH = 'PM'

export const DATE_VARIABLE_TODAY = '$(date +%Y-%m-%d)'
export const DATE_VARIABLE_FIRST_DAY_OF_CURRENT_MONTH = '$(date -d "$(date +%Y-%m-01)" +%Y-%m-%d)'
export const DATE_VARIABLE_FIRST_DAY_OF_CURRENT_YEAR = '$(date -d "$(date +%Y-01-01)" +%Y-%m-%d)'
export const DATE_VARIABLE_FIRST_DAY_OF_PREVIOUS_MONTH = '$(date -d "$(date +%Y-%m-01) -1 month" +%Y-%m-%d)'

export const STEP_NAME_POSTFIX = ['output', 'timestamp', 'status', 'logs']

export const GITHUB_INTEGRATION_PARAM = 'GITHUB-001-P1'
export const GITHUB_REPO_PARAM = 'GITHUB-001-P2'
export const GITHUB_WORKFLOW_PARAM = 'GITHUB-001-P3'
export const GITHUB_WORKFLOW_INPUT_PARAM = 'GITHUB-001-P5'

export const JIRA_INTEGRATION_PARAM_REGEXP = /JIRA-00\d-P1/
export const JIRA_PROJECT_PARAM_REGEXP = /JIRA-00\d-P2/
export const JIRA_ISSUE_TYPE_PARAM_REGEXP = /JIRA-00\d-P6/

export const TEAMS_MESSAGE_TYPE = 'TEAMS-001-P3'
export const TEAMS_TEAM_IDS = ['TEAMS-001-P4', 'APPROVAL-001-P2']

export const DEPRECATED_PARAMS = ['SLACK-004-P7']

export const serviceIcon = (service: string) => {
  const text = service.toLowerCase()
  if (['aws', 'ec2', 'rds', 's3'].includes(text)) {
    return 'images/icons/integrations/' + text + '.png'
  }

  return 'images/icons/integrations/aws.png'
}
